import React from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';

//Date filters
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const HolidaysToolbar = (props) =>  {

  const { onSearch, date, changeDate } = props;

  const handleDateChange = (date) => {
    const params = {
        year: date.getFullYear()
    }
    changeDate(date);
    onSearch(params);
  } 
  return (
    <Grid container alignItems='center' alignContent='center' justifyContent='space-between'>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <Grid item  sm={6}>
            <Box mx={3} my={2}>
              <DatePicker
                margin="normal"
                id="date-picker-year"
                label="Año"
                format="yyyy"
                cancelLabel="Cancelar"
                helperText='Seleccione el año'
                views={["year"]}
                minDate={(new Date()).setFullYear( (new Date()).getFullYear() - 3 )}
                initialFocusedDate={new Date()}
                autoOk={true}
                onChange={handleDateChange}
                value={date}
              />
            </Box>
          </Grid>
        </MuiPickersUtilsProvider>
       </Grid>
  );
}

export default HolidaysToolbar;