import React, { useState, useEffect } from 'react'
import genericDataService from '../../../../services/genericDataService.jsx'
import Loading from '../../../loading/loading';
import { Button, Fade, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';

const activitiesAvailableDataservice = new genericDataService("/activity_types/available");

const activityDataService = new genericDataService("/activities");

const ActivityCancel = (props) => {

  /* Importo el user context para obtener el branch selected */
  const { onChange, started } = props;

  const activity_type_id = props.activity_type_id;

  const [loading, setLoading] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  const timeoutFadeIn = 500;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelActivity = () => {
    cancelActivity();
    setOpen(false);
  };



  useEffect(() => {
    if (activity_type_id > 0) {
      activitiesAvailableDataservice.index()
      .then((res) => res.data)
      .then(data => queryActivityInProgress());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity_type_id]);

  const cancelActivity = () => {
      setLoading(true);

      activityDataService.destroy(started.id)
        .then(res => res.data)
        .then(data => queryActivityInProgress());
  }

  const queryActivityInProgress = () => {
    activitiesAvailableDataservice.index()
      .then((res) => res.data)
      .then(data => {
        onChange(data.started);
        setFadeIn(true);
        setLoading(false);
      })
      .catch(() => {
        onChange(false);
        setLoading(false);
      });
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{"¿Desea anular la actividad en curso?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Al anular la actividad se perderán los datos de la actividad actual.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleCancelActivity} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Loading open={loading} />
      {
        started === false ?
          <Button fullWidth variant="contained" color="primary" disabled>
            <CircularProgress size={25} />
          </Button> :
            started === null ? <> </> :
            (parseInt(activity_type_id) === parseInt(started.activity_type_id))
              ?
              <Fade in={fadeIn} timeout={timeoutFadeIn}>
                <Button fullWidth variant="contained" onClick={handleClickOpen} color="secondary" >Anular</Button>
              </Fade>
              :
              <> </>
      }
    </>
  )
}

export default ActivityCancel;