import materialTableLocalization from '../../../helpers/localization/materialTableLocalization.jsx';

/* Material table title */
const title = "Administración de Tipos de actividad";

/* Material table columns defs */
const columns = [
  {title: 'Nombre', field: 'name', validate: rowData => rowData.name !== ''},
  {title: 'Descripcion', field: 'description', validate: rowData => rowData.description !== ''},
  {title: 'Categoria', field: 'category_id',  lookup:{}, validate: rowData => rowData.category_id > 0},
  {title: 'Area', field: 'area_id',  lookup:{}, validate: rowData => rowData.area_id > 0},
  {title: 'Tipo de notificacion', field: 'notification_type_id', validate: rowData => rowData.notification_type_id > 0, lookup:{}, sorting: false},
  {title: 'Valor de la hora', field: 'hour_value', type: 'numeric'},
]

/* Material table options */
const options = 
{
  editable: true,
  search: true,
  addRowPosition: "first",
  pageSize: 10,
  emptyRowsWhenPaging: false,
  pageSizeOptions: [10, 20, 50, 100, 500],
  exportButton: true,
  exportAllData: true,
  sorting: true,
  actionsColumnIndex: -1,
  toolbarButtonAlignment:'left',
  initialPage: 0
}

export default columns;
export { title, materialTableLocalization as localization, options };
