import React, { useEffect, useState } from 'react'
import { Box, Grid, Button } from '@material-ui/core';
import genericDataService from '../../../services/genericDataService';
import { ReactPictureGrid } from 'react-picture-grid';
const wallPaperDataService = new genericDataService("/prefs/wallpapers");

const Wallpapers = (props) => {

    const [wallpapers, setWallpapers] = useState([]);

    const [wallPaperSelected, setWallPaperSelected] = useState(null);

    useEffect(() => {
        wallPaperDataService.lookup("/prefs/wallpapers")
        .then(response => {
            setWallpapers(response.data);
        });
    }, []);
    
    useEffect(() => {
        if (wallpapers[wallPaperSelected]) {
            let imageName = wallpapers[wallPaperSelected].url.split("/").pop();
            localStorage.setItem("wallpaper", wallpapers[wallPaperSelected].url);
            wallPaperDataService.store({
                active: imageName
            }).then(response => {
                window.location.href = window.location.href;
            }).catch(err => {
            });
            
        }
    }, [wallPaperSelected]);

    
    const unsetWallPaper = () => {
        localStorage.removeItem("wallpaper");
        wallPaperDataService.getApiClient()
        .delete("/prefs/wallpapers").
        then(response => {
            window.location.href = window.location.href;
        }).catch(err => {
        });
    }
        return (
        <Box>
            <ReactPictureGrid
            showPreview={false}
            showImageInfo={false}
            data={wallpapers.map((wallpaper, wallpaperIndex) => {
                return {
                    image: wallpaper.url,
                    title: "Imagen " + (wallpaperIndex + 1),
                    description: wallpaper.active ? <Button style={{color: "white", borderColor: "white"}} variant='outlined'  onClick={() => unsetWallPaper()} >Dejar de usar</Button> : <Button style={{color: "white", borderColor: "white"}} variant='outlined'  onClick={() => setWallPaperSelected(wallpaperIndex)} >Usar este fondo</Button>
                }
            })} showTitle gap={30} />
        </Box>
    )
}

export default Wallpapers;