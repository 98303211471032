import React, { useState, useEffect } from "react";
import {
  Stepper,
  StepLabel,
  makeStyles,
  Step,
  Button,
  Typography,
  Box,
  Grid,
  Paper,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SaveIcon from "@material-ui/icons/Save";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import WorkIcon from "@material-ui/icons/Work";
import PersonalFields from "./editProfile/fields/personalFields";
import "./profile.css";
import AddressFields from "./editProfile/fields/addressFields";
import PhoneFields from "./editProfile/fields/phoneFields";
import CompanyFields from "./editProfile/fields/companyFields";
import Alert from "../../app/alert";
import genericDataService from "../../../services/genericDataService";
import SocialNetworksFields from "./editProfile/fields/socialNetworksFields";
import apiClient from "../../../helpers/apiClient/apiClient";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  marginCheckbox: {
    marginTop: 10,
    marginLeft: 2,
  },
  selectSizeIdentityType: {
    width: "75%",
    "@media only screen and (max-width: 600px)": {
      width: "227px",
    },
  },
  selectSize: {
    width: "57%",
    "@media only screen and (max-width: 600px)": {
      width: "227px",
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  widerField: {
    width: "90%",
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  postalCode: {
    width: "65%",
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  paperStyleFields: {
    borderRadius: 25,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperStyleImage: {
    borderRadius: 25,
    width: 250,
    height: 250,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectSizeDialog: {
    width: "227px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function CreateUser(props) {
  const [identityTypes, setIdentityTypes] = useState(null);
  const [contractMethods, setContractMethods] = useState(null);
  const [holidayTypes, setHolidayTypes] = useState(null);
  const dataService = new genericDataService();

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [actualStepperIndex, setActualStepperIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transition, setTransition] = useState(false);
  const [visible, setVisible] = useState(true);

  //personal
  const [personal, setPersonal] = useState({});
  const [identityType, setIdentityType] = useState({ id: 1, name: "" });
  const [contractMethod, setContractMethod] = useState({ id: 1, name: "" });
  const [userHolidayTypes, setUserHolidayTypes] = useState([]);

  //address
  const [address, setAddress] = useState({});
  const [citySelected, setCitySelected] = useState({});

  //company data
  const [company, setCompany] = useState({
    medical_insurance_id: 1,
  });

  //phones
  const [personalPhones, setPersonalPhones] = useState([]);
  const [contactPhones, setContactPhones] = useState([]);
  const [workPhones, setWorkPhones] = useState([]);
  const [phoneTypes, setPhoneTypes] = useState([]);

  //work social networks
  const [workSocialNetworks, setWorkSocialNetworks] = useState([]);
  const [personalSocialNetworks, setPersonalSocialNetworks] = useState([]);

  //alert
  const [alert, setAlert] = useState({
    severity: "success",
    message: "",
    open: false,
  });

  const [errors, setErrors] = useState([]);

  function getSteps(index) {
    let indexSteps = actualStepperIndex;
    if (index !== undefined) indexSteps = index;

    switch (indexSteps) {
      case 0:
        return [
          "Información personal",
          "Domicilio",
          "Teléfonos personales",
          "Teléfonos de contacto",
          "Redes sociales",
        ];
      case 1:
        return ["Información laboral", "Teléfonos laborales", "Redes sociales"];
      default:
        return [""];
    }
  }

  useEffect(() => {
    if (contractMethods === null) {
      dataService
        .lookup("/contract_methods/all")
        .then((res) => {
          setContractMethods(res.data);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (holidayTypes === null) {
      dataService
        .lookup("/holiday_types/all")
        .then((res) => {
          setHolidayTypes(res.data);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTransition(false);
  }, [transition]);

  useEffect(() => {
    if (identityTypes === null) {
      dataService
        .lookup("/identity_types/all")
        .then((res) => {
          setIdentityTypes(res.data);
          setLoading(false);
        })
        .catch((err) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const phonesTypesDataService = new genericDataService("phone_types/all");
    if (phoneTypes === null || phoneTypes.length === 0) {
      setLoading(true);
      phonesTypesDataService.index().then((res) => {
        setPhoneTypes(res.data);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = getSteps();

  const handleNext = () => {
    if (activeStep + 1 === steps.length) {
      setActiveStep(0);
      setActualStepperIndex(
        (prevActualStepperIndex) => prevActualStepperIndex + 1
      );
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setTransition(true);
    setVisible(false);
    setTimeout(() => {
      setTransition(false);
      setVisible(true);
    }, 200);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActualStepperIndex((prevActualStepperIndex) => {
        setActiveStep(getSteps(prevActualStepperIndex - 1).length - 1);
        return prevActualStepperIndex - 1;
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    setTransition(true);
    setVisible(false);
    setTimeout(() => {
      setTransition(false);
      setVisible(true);
    }, 200);
  };

  const handleSave = () => {
    setPersonal({ ...personal, vacation_amount: 0, identity_type_id: identityType.id, ...company });
    setOpen(false);
    setLoading(true);
    sendData();
  };

  const sendData = () => {
    const idUserHolidayTypes = userHolidayTypes.map(({ name, id }) => id);
    let params = {
      ...personal,
      ...company,
      medical_insurance_id: 1,
      holiday_types: idUserHolidayTypes,
    };
    const userDataService = new genericDataService("users");
    setLoading(true);
    userDataService
      .store(params)
      .then(async (res) => {
        let id = res.data.id;
        if (address.address || address.city_id || address.postalCode) {
          const dataService = new genericDataService("addresses");
          await dataService
            .store({
              ...address,
              user_id: id,
            })
            .catch((e) => {});
        }

        const userPhones = await apiClient
          .post("users/" + id + "/phones", personalPhones ?? [])
          .catch((e) => {
            setAlert({
              severity: "error",
              message: e.message,
              open: true,
            });
          });

        const userContacts = await apiClient
          .post("users/" + id + "/contacts", contactPhones ?? [])
          .catch((e) => {
            setAlert({
              severity: "error",
              message: e.message,
              open: true,
            });
          });

        const userSocialNetworks = await apiClient
          .post(
            "users/" + id + "/social-networks",
            personalSocialNetworks ?? []
          )
          .catch((e) => {
            setAlert({
              severity: "error",
              message: e.message,
              open: true,
            });
          });

        const userWorkPhones = await apiClient
          .post("users/" + id + "/work-phones", workPhones ?? [])
          .catch((e) => {
            setAlert({
              severity: "error",
              message: e.message,
              open: true,
            });
          });

        const userWorkSocialNetworks = await apiClient
          .post(
            "users/" + id + "/work-social-networks",
            workSocialNetworks ?? []
          )
          .catch((e) => {
            setAlert({
              severity: "error",
              message: e.message,
              open: true,
            });
          });

        if (
          userPhones.status === 200 &&
          userContacts.status === 200 &&
          userSocialNetworks.status === 200 &&
          userWorkPhones.status === 200 &&
          userWorkSocialNetworks.status === 200
        ) {
          setAlert({
            severity: "success",
            message: "Usuario creado correctamente",
            open: true,
          });
          setTimeout(() => {
            props.history.goBack();
          }, 2000);
        } else {
          setAlert({
            severity: "error",
            message: "Errores en la creación",
            open: true,
          });
        }
      })
      .catch((e) => {
        setErrors(e.response?.data.errors);
        setLoading(false);
        setAlert({
          severity: "error",
          message: "Errores en la creación",
          list: e.response?.data.errors,
          open: true,
        });
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getTitle = () => {
    let title = "";

    if (actualStepperIndex === 0) {
      title = "Información personal";
    } else {
      title = "Información laboral";
    }
    return title;
  };

  const getWorkFields = () => {
    switch (activeStep) {
      case 0:
        return (
          <CompanyFields
            classes={classes}
            creating
            contractMethods={contractMethods ?? []}
            contractMethod={contractMethod}
            setContractMethod={setContractMethod}
            holidayTypes={holidayTypes ?? []}
            userHolidayTypes={userHolidayTypes}
            setUserHolidayTypes={setUserHolidayTypes}
            company={company}
            errors={errors}
            setCompany={setCompany}
            user={{}}
          />
        );
      case 1:
        return (
          <PhoneFields
            classes={classes}
            creating
            phones={workPhones}
            setPhones={setWorkPhones}
            title={"Teléfonos laborales"}
            primary={"number"}
            secondary={"phone_type_id"}
            label={"phone_type"}
            phoneTypes={phoneTypes}
          />
        );
      case 2:
        return (
          <SocialNetworksFields
            classes={classes}
            creating
            socialNetworks={workSocialNetworks}
            setSocialNetworks={setWorkSocialNetworks}
          />
        );
      default:
        break;
    }
  };

  const getPersonalFields = () => {
    switch (activeStep) {
      case 0:
        return (
          <PersonalFields
            classes={classes}
            creating
            loading={loading}
            personal={personal}
            setPersonal={setPersonal}
            identityType={identityType}
            setIdentityType={setIdentityType}
            identityTypes={identityTypes}
            user={{}}
            errors={errors}
          />
        );
      case 1:
        return (
          <AddressFields
            classes={classes}
            creating
            address={address}
            setAddress={setAddress}
            user={{}}
            setCitySelected={setCitySelected}
            citySelected={citySelected}
          />
        );
      case 2:
        return (
          <PhoneFields
            classes={classes}
            creating
            phones={personalPhones}
            setPhones={setPersonalPhones}
            phoneTypes={phoneTypes}
            primary={"number"}
            secondary={"phone_type_id"}
            label={"phone_type"}
            title={"Teléfonos personales"}
          />
        );
      case 3:
        return (
          <PhoneFields
            classes={classes}
            creating
            phones={contactPhones}
            setPhones={setContactPhones}
            phoneTypes={phoneTypes}
            primary={"phone_number"}
            secondary={"phone_type_id"}
            label={"fullname"}
            isContact
            title={"Teléfonos de contacto"}
          />
        );
      case 4:
        return (
          <SocialNetworksFields
            classes={classes}
            creating
            socialNetworks={personalSocialNetworks}
            setSocialNetworks={setPersonalSocialNetworks}
          />
        );
      default:
        break;
    }
  };

  const getFields = () => {
    if (actualStepperIndex === 1) {
      return getWorkFields();
    } else {
      return getPersonalFields();
    }
  };

  return (
    <div className={classes.root}>
      <Alert
        onClose={() => setAlert({ ...alert, open: false })}
        alert={alert}
        setAlert={setAlert}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Guardar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>¿Desea guardar el usuario?.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Box m={3}>
        <Grid container>
          <Grid item sm={9} md={3}>
            <Typography variant="h4">{getTitle()}</Typography>
          </Grid>
          <Grid item sm={3} md={2}>
            <Box display="inline" pl={3}>
              {actualStepperIndex === 1 ? (
                <WorkIcon fontSize="large" />
              ) : (
                <AccountCircleIcon fontSize="large" />
              )}
            </Box>
          </Grid>
          <Grid item xs={false} sm={5}>
            <Box height={1 / 2}></Box>
          </Grid>
          <Grid item xs={12} md={2}>
            {/* <Box pl={10}> */}
            <Button
              disabled={actualStepperIndex === 0 && activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
            >
              <NavigateBeforeIcon />
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={
                actualStepperIndex === 1 && activeStep === steps.length - 1
                  ? handleOpen
                  : handleNext
              }
            >
              {actualStepperIndex === 1 && activeStep === steps.length - 1 ? (
                <SaveIcon />
              ) : (
                <NavigateNextIcon />
              )}
            </Button>
            {/* </Box> */}
          </Grid>
        </Grid>
      </Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {transition ? (
        <> </>
      ) : (
        <Slide in={visible} direction="left">
          <Paper>
            <Box padding={3}>{getFields()}</Box>
          </Paper>
        </Slide>
      )}
    </div>
  );
}
