import React, {useState, useEffect} from 'react'
import apiClient, { fetchToken } from "../../../helpers/apiClient/apiClient.jsx"

/* Material core kit */
import { 
    Button, CssBaseline,TextField,  Avatar,
    Link,  Box, Typography, Container
} from '@material-ui/core';

import Alert  from '@material-ui/lab/Alert'

/* Material icons */
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Loading from '../../loading/loading.jsx';

/* custom theme mods */
import useStyles from "./login.css";

/* import image logo*/
import imageLogo from "../../../resources/images/logo.png";

/* component */
const Login = (props) => {

  /* import custom classes */
  const classes = useStyles();

  /* initialize state */
  const [state, setState] = useState({
    message:"",
    token:"",
    expires_in: 0,
    requestDate: {},
    username : "",
    password: "",
    ping: null
  });

  useEffect(() => {
    apiClient.get("/ping")
      .then((res) => res.data)
      .then((res) =>  setState({...state, ping: res.message === 'pong!' ? true : false }))
      .catch((err) => setState({...state, ping: false, message: err}))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /* cuando ocurre el efecto secundario y token !="" grabo la cookie y redirijo a login*/
  useEffect(() => {
    if (state.token !== "" && state.expiration_date !==  null) {
      localStorage.setItem("token", state.token);
      localStorage.setItem("token_expiration_date", state.expiration_date);
      props.onLoggedIn();
      props.history.push("/");
    }
  }, [state.token, state.expiration_date , props]);

  /* onchange callback for inputs*/
  const change = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      message:"",
      token: "",
      expiration_date: null
    });
  };

  /* login submit callback */
  const submit = (e) => {
    /* deshabilito el submit del boton */
    e.preventDefault();
    props.onLoading(true);
    fetchToken(state)
    .then((response) => {
      props.onLoading(false);
      if (response.data.access_token!=="") {
        let expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + response.data.expires_in);
        setState({...state, token: response.data.access_token, expiration_date: expirationDate});
      } else {
        setState({ ...state, message: response.message, token: "",  expiration_date: null});
      }
    })
    .catch((err) => {
      props.onLoading(false);
      setState({ ...state, message: err.message, token: "", expiration_date: null});
    })
  }

  /* view */
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {
        state.ping === null ? <Loading /> :
        <Box className={classes.paper}>
          <img src={imageLogo} alt='Netlabs' />
          <Avatar className={classes.avatarClose}>{state.token==="" ? <LockOutlinedIcon /> : <LockOpenIcon />} </Avatar>
          <Typography component="h1" variant="h5">Access control</Typography>
          { state.ping ?
          <form className={classes.form} noValidate error={state.error} >
            <TextField id="username" name="username"
              value={state.username} autoComplete="username"
              variant="outlined" margin="normal" fullWidth
              error={state.error} onChange={change}
              label="Username" required autoFocus />
            <TextField id="password" name="password" type="password"
              value={state.password} autoComplete="current-password"
              variant="outlined" margin="normal" fullWidth
              onChange={change}
              label="Password" required />
            { state.message!=="" ? <Alert severity="error">{state.message}</Alert> : '' }
            <Button type="submit" fullWidth  variant="contained" onClick={submit} color="primary" className={classes.submit}>INGRESAR</Button>
          </form>
          : <Typography variant="body" color="error" align="center"><br /><br /><br />Lo sentimos, el servidor de aplicación no responde. <br />vuelva a intentar en unos minutos</Typography>}
        </Box>
      }
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.netlabs.com.ar">Netlabs</Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
      </Box>
    </Container>
  );
}

export default Login;
