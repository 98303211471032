import materialTableLocalization from '../../../helpers/localization/materialTableLocalization.jsx';

/* Material table title */
const title = "Administración de tipos de feriados";

/* Material table columns defs */
const columns = [
  {title: 'Tipo de feriado', field: 'name', validate: rowData => rowData.name !== ''},
]

/* Material table options */
const options = 
{
  editable: true,
  search: true,
  addRowPosition: "first",
  pageSize: 10,
  emptyRowsWhenPaging: false,
  pageSizeOptions: [10, 20, 50, 100, 500],
  exportButton: true,
  exportAllData: true,
  sorting: true,
  actionsColumnIndex: -1,
  toolbarButtonAlignment:'left',
  initialPage: 0
}

export default columns;
export { title, materialTableLocalization as localization, options };
