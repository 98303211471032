import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, Checkbox, FormControlLabel, CircularProgress} from "@material-ui/core";
import MaterialTable from "material-table";
import UserContext from "../../../contexts/userContext/userContext.jsx";
/* importing columns a data service */
import { title, localization, options } from "./roles.def.jsx";
import genericDataService from "../../../services/genericDataService";
import mtablePerm from "../../../helpers/permissions/permissions.jsx";

const permissions = new mtablePerm("roles");

const roleDataService = new genericDataService("/roles/all-permissions");

const tableRoles = React.createRef();

const Roles = (props) => {
  /* Indice de los campos select en columns def */
  const ROLE_PARENT_ID_FIELD = 2;

  const me = useContext(UserContext);

  const [actions, setActions] = useState({});

  const [roles, setRoles] = useState([]);
  const [columns, setColumns] = useState([]);

  const [loading, setLoading] = useState(false);
  const [canEditRoles, setCanEditRoles] = useState(false);

  const [data, setData] = useState(() => () => roleDataService.emptyTable());

  useEffect(() => {
    if (me.user.actual_branch > 0) {
      new genericDataService("/roles/all").index()
      .then((res) => {
        setRoles(res.data);
        res.data.map(
          (roleItem) =>
            (columns[ROLE_PARENT_ID_FIELD].lookup[roleItem.id] =
              roleItem.full_name)
        );
      })
      .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (roles.length != 0) {
      setLoading(true);
      const columns = [
        {
          title: "Permiso",
          field: "name",
          editable: "never",
          sorting: false
        }, 
        ...roles.map((role) => {
        return {
          title: role.full_name,
          field: "name",
          render: (rowData) => {
            let checked = role.permissions.filter((permission) => permission.name == rowData.name).length > 0;
            return <RolePermissionCheckobox
              roleId={role.id}
              permissionId={rowData.id}
              checked={checked}
              disabled={canEditRoles}
              label={rowData.name}
            />;
          },
        };
      })]
      setColumns(columns);
      setLoading(false);
    }
  }, [roles]);

  /* Refresca la tabla de roles luego de los siguiente eventos: mount, refresh, add, update */
  useEffect(() => {
    if (me.user.actual_branch > 0) {
      setActions(permissions.resolve(me?.user?.menu_permissions).get());
      setCanEditRoles(!me.user.menu_permissions.includes("update_roles"));
      refreshTable();
    }
  }, [me]);

  const refreshTable = () => {
    setLoading(true);
    setData(() => (query) => roleDataService.rows(query));
    tableRoles.current.onQueryChange();
    setLoading(false);
  };

  return (
    <Box style={{ padding: 20 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            title={title}
            tableRef={tableRoles}
            columns={columns}
            isLoading={loading}
            onLoading={loading}
            localization={{ ...localization, toolbar: { ...localization.toolbar, searchPlaceholder: "Buscar permisos" }}}
            loadingType={"linear"}
            options={{
              ...options,
              editable: false,
              search: true,
              searchFieldAlignment: "right",
              searchFieldVariant: "outlined",
              addRowPosition: "first",
              pageSize: 15,
              pageSizeOptions: [5, 15, 50, 100, 500],
              exportButton: false,
              exportAllData: false,
              sorting: true,
              toolbarButtonAlignment: "left",
              initialPage: 0,
              columnsButton: true,
              emptyRowsWhenPaging: false,
              
            }}
            data={data}
            editable={{
              ...actions,
              onRowUpdate: undefined,
              onRowAdd: undefined,
            }}
            actions={[
              {
                icon: "list",
                tooltip: "Areas",
                isFreeAction: true,
                onClick: () => props.history.push("/admin/areas"),
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const RolePermissionCheckobox = (props) => {

  const [checked, setChecked] = useState(props.checked);
  const [loading, setLoading] = useState(false);
  const [onRender, setOnRender] = useState(true);
  const handleChange = (event) => {
    setOnRender(false);
    setChecked(event.target.checked);
  }

  const RolePermissionDataService = new genericDataService();

  useEffect(() => {
    if (!onRender) {
      setLoading(true);
      if (checked) {
        RolePermissionDataService
        .changeResource("/roles/" + props.roleId + "/permissions/" + props.permissionId)
        .store()
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {});
      } else {
        RolePermissionDataService
        .changeResource("/roles/" + props.roleId + "/permissions")
        .destroy(props.permissionId)
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {});
      }
    }
  }, [checked]);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
   <>
    { loading ? 
    <CircularProgress size={25} disableShrink /> : 
    <FormControlLabel
    control={
        <Checkbox
          checked={checked}
          defaultValue={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "primary checkbox" }}
          disabled={props.disabled}
        />
      }
    />
    }
  </>
  );
};

export default Roles;
