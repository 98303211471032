import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( (theme) => (
  {
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },    
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    }
  }

));

export default useStyles;