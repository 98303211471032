import React, { useContext, useState, useEffect } from 'react'
import { Grid, Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import UserContext from '../../../contexts/userContext/userContext.jsx';

/* importing columns a data service */
import columns, {title, localization, options} from './activity_types.def.jsx'

import genericDataService from '../../../services/genericDataService';

import mtablePerm from '../../../helpers/permissions/permissions.jsx';

const permissions = new mtablePerm("activity_types");

const activityTypeDataService = new genericDataService("/activity_types");

const tableActivityTypes = React.createRef();

const ActivityTypes = (props) => {
    
	const me = useContext(UserContext);

	const [actions, setActions] = useState({});

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState(() => () => activityTypeDataService.emptyTable());

	const CATEGORY_FIELD_TABLE_ID = 2;

	const AREA_FIELD_TABLE_ID = 3;

	const NOTIFICATION_TYPE_FIELD_ID = 4;

	
	/* traigo las categories necesarias para el abm */
	useEffect(() => {
		activityTypeDataService
		.lookup("/categories/all")
		.then((res) => res.data.map(category => columns[CATEGORY_FIELD_TABLE_ID].lookup[category.id] = category.name)
		);
	}, []);

	/* traigo las areas necesarias para el abm */
	useEffect(() => {
		activityTypeDataService
		.lookup("/areas/all")
		.then((res) => res.data.map(area => columns[AREA_FIELD_TABLE_ID].lookup[area.id] = area.name)
		);
	}, []);

	/* traigo los notification types necesarios para el abm */
	  useEffect(() => {
		activityTypeDataService
		  .lookup("/notification_types/all")
		  .then((res) => res.data.map(notificationTypeItem => columns[NOTIFICATION_TYPE_FIELD_ID].lookup[notificationTypeItem.id] = notificationTypeItem.name)
		  );
	  }, []);

	/* Refresca la tabla de areas luego de los siguiente eventos: mount, refresh, add, update */
	useEffect(() => {
		if (me.user.actual_branch>0) {
			setActions(permissions.resolve(me?.user?.menu_permissions).get());
			setData(() => (query) => activityTypeDataService.rows(query, me.user.company_id));
			tableActivityTypes.current.onQueryChange();
			setLoading(false);
		}
  }, [me])
	
	return (
		<Box style={{ padding: 20 }}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<MaterialTable
						title={title}
						tableRef={tableActivityTypes}
						columns={columns}
						isLoading={loading}
						onLoading={loading}
						localization={localization}
						loadingType={"linear"}
						options={options}
						data={data}
						editable={{
							...actions,
							onRowAdd: actions.onRowAdd ? newData => activityTypeDataService.rowAdd(newData, me.user.company_id) : undefined,
							onRowUpdate: actions.onRowUpdate ? (newData, oldData) => activityTypeDataService.rowUpdate(newData, oldData) : undefined
						}}
					/>
				</Grid>
			</Grid>
		</Box>
  )
}

export default ActivityTypes;