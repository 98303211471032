import ActivityCancel from "../activities/activityCancel/activityCancel.jsx";
import ActivityStartEnd from "../activities/activityStartEnd/activityStartEnd.jsx";
import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Zoom,
  Box,
} from "@material-ui/core";
import moment from "moment-timezone";

const CardStartEndActivity = (props) => {
  const [started, setStarted] = useState(false);

  const handleActivityStartEndChange = (started) => {
    setStarted(started);
  };

  const handleActivityCancelChange = (started) => {
    setStarted(started);
  };

  const {
    classes,
    zoomIn,
    velZoomIn,
    last_activity_type_id,
    last_activity_description,
  } = props;

  return (
    <Zoom in={zoomIn} style={{ transitionDelay: zoomIn ? velZoomIn : "0ms" }}>
      <Grid item xs={12} sm={6} lg={3}>
        <Card className={classes.root} variant="outlined">
          <Box style={{ minHeight: 90 }}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Inicio rápido
              </Typography>
              <Typography variant="h5" component="h2">
                {last_activity_description}
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="subtitle1"
                component="h3"
              >
                {started
                  ? "Inicio: " + moment(started.start).format("HH:mm")
                  : ""}
              </Typography>
              {started ? <></> : <Box marginTop={4}></Box>}
            </CardContent>
          </Box>
          <CardActions>
            <Box mt={2}>
              <Grid container spacing={2}>
                {started ?
                <Grid item>
                  <ActivityCancel
                    {...props}
                    showTimeStarted={true}
                    activity_type_id={last_activity_type_id}
                    onChange={handleActivityStartEndChange}
                    started={started}
                  />
                </Grid>
                : <></>}
                <Grid item>
                  <ActivityStartEnd
                    {...props}
                    showTimeStarted={true}
                    activity_type_id={last_activity_type_id}
                    onChange={handleActivityCancelChange}
                    started={started}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    </Zoom>
  );
};

export default CardStartEndActivity;
