import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";

//Date filters
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//Dropdown filters
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Button search
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const TraceActivitiesTicketsTooolbar = (props) => {
  const {
    onSearch,
    filtersData,
    setFiltersData,
    users,
    userSelected,
    setUserSelected,
    dropdownUserAppear,
  } = props;

  const [isDateValid, setIsDateValid] = useState(false);

  const handleDateFromChange = (date) => {
    if (!isNaN(date)) {
      setFiltersData({
        ...filtersData,
        dateFrom: date,
      });
      setIsDateValid(true);
    }
  };

  const handleDateToChange = (date) => {
    if (!isNaN(date)) {
      setFiltersData({
        ...filtersData,
        dateTo: date,
      });
      setIsDateValid(true);
    }
  };

  const searchButtonClicked = () => {
    if (!isDateValid) {
      const today = new Date();

      if (!filtersData.dateFrom && !filtersData.dateTo) {
        setFiltersData({
          ...filtersData,
          dateFrom: today,
          dateTo: today,
        });
      } else if (!filtersData.dateTo) {
        setFiltersData({
          ...filtersData,
          dateTo: today,
        });
      } else if (!filtersData.dateFrom) {
        const dateFromyesterday = new Date(filtersData.dateTo);
        dateFromyesterday.setDate(dateFromyesterday.getDate() - 1);
        setFiltersData({
          ...filtersData,
          dateFrom: dateFromyesterday,
        });
      }
    }

    onSearch(filtersData);
  };

  const onAutocompleteChange = (event, value) => {
    setFiltersData({
      ...filtersData,
      user: value ? [value.id] : [],
    });
    setUserSelected(value);
  };

  return (
    <Grid container alignItems="center" alignContent="center">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <Grid item md={6} lg={3}>
          <Box mx={3} my={2}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-from"
              label="Desde"
              format="dd/MM/yyyy"
              disableFuture
              cancelLabel="Cancelar"
              value={filtersData.dateFrom}
              autoOk={true}
              onChange={handleDateFromChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Box>
        </Grid>

        <Grid item md={6} lg={3}>
          <Box mx={3} my={2}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-from"
              label="Hasta"
              format="dd/MM/yyyy"
              disableFuture
              cancelLabel="Cancelar"
              showTodayButton
              autoOk={true}
              todayLabel="Hoy"
              value={filtersData.dateTo}
              onChange={handleDateToChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Box>
        </Grid>
      </MuiPickersUtilsProvider>
      {dropdownUserAppear ? (
        <Grid item md={6} lg={3}>
          <Box mx={3} my={1}>
            <Autocomplete
              id="user-picker"
              autoComplete
              onChange={onAutocompleteChange}
              options={users}
              getOptionLabel={(option) =>
                option ? option.lastname + " " + option.firstname : ""
              }
              value={userSelected}
              style={{ width: 255 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Usuario"
                  placeholder="Usuario"
                  margin="normal"
                />
              )}
            />

            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={props.activeUserFlag}
                  onChange={(e) => props.setActiveUserFlag(e.target.checked)}
                />
              }
              label="Solo activos"
              classes={props.classes}
            />
          </Box>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item md={3} xs={12} sm={4} lg={dropdownUserAppear ? 2 : 3}>
        <Box mx={3} mt={4} mb={2}>
          <Button
            disabled={filtersData.user === ""}
            onClick={searchButtonClicked}
            fullWidth
            variant="contained"
            color="primary"
            endIcon={<SearchIcon />}
          >
            Buscar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TraceActivitiesTicketsTooolbar;
