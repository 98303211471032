import React, { useContext, useState, useEffect } from 'react'
import { Box, Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import UserContext from '../../../contexts/userContext/userContext.jsx';

/* importing columns a data service */
import columns, {title, localization, options} from './holiday_types.def.jsx'
import genericDataService from '../../../services/genericDataService';
import mtablePerm from '../../../helpers/permissions/permissions.jsx';

const permissions = new mtablePerm("holiday_types");

const holidayTypesDataService = new genericDataService("/holiday_types");

const tableHolidayTypes = React.createRef();

const HolidayTypes = (props) => {

	const me = useContext(UserContext);

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState(() => () => holidayTypesDataService.emptyTable());

	const [actions, setActions] = useState({});

	/* Refresca la tabla de holiday_types luego de los siguiente eventos: mount, refresh, add, update */
	useEffect(() => {
		if (me.user.actual_branch>0) {
			setActions(permissions.resolve(me?.user?.menu_permissions).get());
			setData(() => (query) => holidayTypesDataService.rows(query, me.user.company_id));
			tableHolidayTypes.current.onQueryChange();
			setLoading(false);
		}
  }, [me])

	return (
		<Box style={{ padding: 20 }}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<MaterialTable
						title={title}
						tableRef={tableHolidayTypes}
						columns={columns}
						isLoading={loading}
						onLoading={loading}
						localization={localization}
						loadingType={"linear"}
						options={options}
						data={data}
						editable={{
							...actions,
							onRowAdd: actions.onRowAdd ? newData => holidayTypesDataService.rowAdd(newData, me.user.company_id): undefined,
							onRowUpdate: actions.onRowUpdate ? (newData, oldData) => holidayTypesDataService.rowUpdate(newData, oldData): undefined
						}}
						actions={
							[{
							icon: 'chevron_left',
							tooltip: 'Volver a feriados',
							isFreeAction: true,
							onClick: () => props.history.push("/admin/holidays")
							}]
						}
					/>
				</Grid>
			</Grid>
		</Box>
  )
}

export default HolidayTypes;