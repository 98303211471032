import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { categoryIcons } from '../../menu/menu.jsx';
import UserContext from '../../contexts/userContext/userContext.jsx';
import useStyles from './sidebar.css.jsx';
import menu from '../../menu/menu.jsx';


const Sidebar = (props) => {

  /* topMenu contiene los links que van a renderizarse en la barra superior */
  const [sidebarMenu, setSidebarMenu] = useState(menu.filter((routeItem) => "position" in routeItem && routeItem.position === "top-menu"));

  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState([]);

  const handleClick = (id, event) => {
    setOpenMenu({ [id]: !openMenu[id] });
    event.stopPropagation();
  };

  const me = useContext(UserContext);

  useEffect(() => {
    if (me.user.actual_branch>0) {
      var sidebarMenuOptions = sidebarMenu;
      me.user.available_activity_types.forEach((category, index) => {
        let icon = categoryIcons.filter(catIcon => catIcon.id === category.id).shift().icon 
        var topActivityMenu = {
          name: category.name,
          text: category.name,
          exact: true,
          position: 'top-menu',
          path: category.name === 'Licencias' ? '/licences' : undefined,
          icon: icon,
          childs: []
        };
        if(category.name !== 'Licencias') {
          category.activity_types.forEach(activityType => {
            topActivityMenu.childs.push({
              name: activityType.name,
              text: activityType.name,
              path: '/activities/' + activityType.id,
              exact: true,
              icon: icon
            });
          
          });
        }

        if (sidebarMenuOptions.findIndex((item) => item.name === topActivityMenu.name)<0) {
          sidebarMenuOptions.splice(2 + index, 0,  topActivityMenu);
        } else {
          sidebarMenuOptions.splice(2 + index, 2,  topActivityMenu);
        }
      });
      setSidebarMenu(sidebarMenuOptions)
    }
  }, [me, sidebarMenu]);


  return (
    <Drawer anchor="left" open={props.open} onClick={props.onToggleSidebar} onKeyDown={props.onToggleSidebar}>
      <Box className={classes.fullList} role="presentation" onClick={props.onToggleSidebar} onKeyDown={props.onToggleSidebar}>
        <List className={classes.list}>
          {
            sidebarMenu
            /* filter by menu_permissions available in user.me */
            .filter((menu) => ("menu_permissions" in me?.user) && (!menu?.permission || me.user.menu_permissions.indexOf(menu?.permission)>-1))
            .map((menu, i) => {
              const SelectedIcon = menu.icon;
              return "childs" in menu && menu.childs.length > 0
                ? menu.childs.length > 1 ?
                <List key={"root-" + i}>
                  <ListItem button key={"parent-" + i} onClick={handleClick.bind(this, menu.name)} >
                    <ListItemIcon><SelectedIcon /></ListItemIcon>
                    <ListItemText primary={menu.text} />
                    {openMenu[menu.name] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openMenu[menu.name]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {
                        menu.childs
                          /* filter by menu_permissions available in user.me */
                          .filter((submenu) => ("menu_permissions" in me?.user) && (!submenu?.permission || me.user.menu_permissions.indexOf(submenu?.permission)>-1))
                          .map((submenu, j) => {
                          const SelectedIcon = submenu.icon;
                          return (
                            <ListItem className={classes.nested} button key={menu.name + "-" + j} onClick={props.onToggleSidebar} component={Link} to={submenu.path}>
                              <ListItemIcon><SelectedIcon /></ListItemIcon>
                              <ListItemText primary={submenu.text} />
                            </ListItem>
                          );
                        }
                        )}
                    </List>
                  </Collapse>
                </List>
                : <ListItem button key={menu.name} onClick={props.onToggleSidebar} component={Link} to={menu.childs[0]?.path}>
                  <ListItemIcon><SelectedIcon /></ListItemIcon>
                  <ListItemText primary={menu.text} />
                </ListItem>
                : <ListItem button key={i} onClick={props.onToggleSidebar} component={Link} to={menu?.path ?? ""}>
                  <ListItemIcon><SelectedIcon /></ListItemIcon>
                  <ListItemText key={i} primary={menu.text} />
                </ListItem>
            })
          }
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;

