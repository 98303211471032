
import esLocale from "date-fns/locale/es";

/* Material table lang */
const materialTableLocalization = {
  body: {
    emptyDataSourceMessage : "No hay registros",
    addTooltip: "Crear",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    dateTimePickerLocalization: esLocale,
    editRow: {
      deleteText: "Está seguro que desea eliminar este elemento?",
      cancelTooltip: "Cancelar",
      saveTooltip: "Crear"
    }
  },
  header: {
    actions: 'Acciones' 
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'líneas',
    firstTooltip: 'Primera',
    previousTooltip: 'Anterior',
    nextTooltip: 'Siguiente',
    lastTooltip: 'Última'
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
    exportTitle: "Exportar"
  }
};

export default materialTableLocalization;