import React, {useState} from 'react'
import apiClient from "../../../helpers/apiClient/apiClient.jsx"

/* Material core kit */
import {Button, CssBaseline,TextField, Typography, Container, Box, Paper, Grid, IconButton, Tooltip } from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Alert from '../../app/alert.jsx';

/* custom theme mods */
import useStyles from "./password.css";


import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/* component */
const Password = (props) => {
  let query = useQuery();
  const token = query.get("token");
  /* import custom classes */
  const classes = useStyles();

  /* initialize state */
  const [state, setState] = useState({
    message:"",
    token:"",
    expires_in: 0,
    requestDate: {},
    password: "",
    password_confirmation : "",
    ping: null
  });

  const [alert, setAlert] = useState({
      open: false,
      message: "",
      severity: "success"
  })

  const [username, setUsername] = useState("")

  /* onchange callback for inputs*/
  const change = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      message:"",
      token: "",
      expiration_date: null
    });
  };

  const firstlogin = (params) => {
    return apiClient.post('/auth/first-login/'+token, params);
  }

  /* login submit callback */
  const submit = () => {
		return new Promise((resolve, reject) => {
      var params = typeof(state) === 'undefined' ? {} : state;
      /* TODO: refactor luego de cambiar las rutas en la API */
      firstlogin(params)
      .then((res) => {
        setUsername(res.data.user.username);
        let expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + res.data.expires_in);

        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("token_expiration_date", expirationDate);
        props.onLoggedIn();
        setAlert({
          severity: 'sucess',
          message: 'Contraseña guardada',
          open: true
        });
        setTimeout(()=> {
            props.history.replace('/logout');
          }, 5000)
        })
      .catch((error) => {
        setAlert({
          severity: 'error',
          message: error.message,
          open: true
        });
        })
      })
  }

  const formPassword = () => {
    return <Box padding={5}>
            <Typography component="h1" variant="h5">Crear contraseña</Typography>
            <form className={classes.form} noValidate error={state.error} >
              <TextField id="password" name="password" type="password"
                value={state.password} autoComplete="current-password"
                variant="outlined" margin="normal" fullWidth
                onChange={change}
                label="Contraseña" required />
              <TextField id="password_confirmation" name="password_confirmation" type="password"
                value={state.password_confirmation} autoComplete="current-password_confirmation"
                variant="outlined" margin="normal" fullWidth
                onChange={change}
                label="Confirmar Contraseña" required />
              <Alert
                onClose={()=>setAlert({...alert, open:false})}
                alert={alert}
                setAlert={setAlert}
              />
              <Button type="button" fullWidth  variant="contained" onClick={submit} color="primary" className={classes.submit}>Guardar</Button>
            </form>
          </Box>
  }

  const userInformation = () => {
    return (
      <Box padding={3}>
        <Typography variant="h4">Su usuario es: </Typography>
        <Typography variant='h4' color='textSecondary'>{username}</Typography>
        <Box marginTop={2}>
          <Typography variant='subtitle1'>Será redirigido al ingreso.</Typography>
        </Box>
        <Box marginTop={12} height={100}>
          <Grid container>
            <Grid item xs={6}>
              <Tooltip arrow title='Documentación HCM+'>
                <IconButton size={50} onClick={()=>{window.open('https://wiki.ac.netlabs.com.ar', '_blank').focus()}} aria-label="upload picture">
                  <MenuBookIcon style={{fontSize: 100}}/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6} alignItems='center' alignContent='center'>
                <Box margin={4}>
                  <Typography alignText='center' variant='button'>Manual de usuarios.</Typography>
                </Box>
            </Grid>
          </Grid>
        </Box>
        {/* https://wiki.ac.netlabs.com.ar/index.php/The_Heimdall_Project */}
      </Box>
    )
  }

  /* view */
  return (
    <Box marginTop={16}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Grid contianer>
        <Grid item xs={0} sm={1}><Box></Box></Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            { username === '' ? formPassword()
             : userInformation() }
          </Paper>
        </Grid>
        <Grid item xs={0} sm={1}><Box></Box></Grid>
      </Grid>
    </Container>
    </Box>
  );
}

export default Password;
