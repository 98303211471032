import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import ActivityListTrace from './activityTracer/activityTracer'

const TraceActivities = (props) =>  {

  return (
    <Grid container>
       <Grid xs={12} item component={Paper}>
        <ActivityListTrace {...props }/>
       </Grid>
    </Grid>
  );
}

export default TraceActivities;