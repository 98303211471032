import React, { useState } from 'react';
import { Grid, Fab, Icon, TextField, InputLabel, Box } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import apiClient from '../../../../helpers/apiClient/apiClient.jsx';

const AddActivityForm = ({
  openForm,
  handleOpenForm,
  users,
  categories,
}) => {

  const [userSelected, setUserSelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [formValues, setFormValues] = useState({
    'user_id': '',
    'category_id': '',
    'start_date': '',
    'start_time': '',
    'end_date': '',
    'end_time': '',
    'summary': '',
  });

  const onUserChange = (event, value) => {
    setUserSelected(value);
  };

  const onCategoryChange = (event, value) => {
    setCategorySelected(value);
  };

  const handleTextFieldInputChange = (event) => {
    const { id, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [id]: value,
    }));
  };

  const saveButtonClicked = async () => {
    const formattedStartDate = new Date(formValues.start_date + 'T' + formValues.start_time);
    const formattedEndDate = new Date(formValues.end_date + 'T' + formValues.end_time);
    
    formattedStartDate.setHours(formattedStartDate.getHours() - 3);
    formattedEndDate.setHours(formattedEndDate.getHours() - 3);

    const response = await apiClient.post('/activities/store-for-specific-user', {
      user_id: userSelected.id,
      activity_type_id: categorySelected.id,
      start: formattedStartDate.toISOString(),
      end: formattedEndDate.toISOString(),
      summary: formValues.summary,
    });

    handleOpenForm(false);

    setFormValues({
      user_id: '',
      category_id: '',
      start_date: '',
      start_time: '',
      end_date: '',
      end_time: '',
      summary: '',
    });
  };

  return (
    <Dialog open={openForm} onClose={() => handleOpenForm(false)}>
      <DialogTitle>Agregar nueva actividad</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              id="user-picker"
              autoComplete
              onChange={onUserChange}
              options={users}
              getOptionLabel={(option) =>
                option ? option.lastname + " " + option.firstname : ""
              }
              value={userSelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Usuario"
                  placeholder="Usuario"
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              autoComplete
              onChange={onCategoryChange}
              options={categories}
              getOptionLabel={(option) => (option ? option.name : "")}
              value={categorySelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categoria"
                  placeholder="Categoria"
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="start_date">Fecha de inicio</InputLabel>
            <TextField
              id="start_date"
              label=""
              type="date"
              fullWidth
              margin="normal"
              style={{ marginTop: 0 }}
              onChange={handleTextFieldInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="start_time">Hora de inicio</InputLabel>
            <TextField
              id="start_time"
              label=""
              type="time"
              fullWidth
              margin="normal"
              style={{ marginTop: 0 }}
              onChange={handleTextFieldInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="end_date">Fecha de fin</InputLabel>
            <TextField
              id="end_date"
              label=""
              type="date"
              fullWidth
              margin="normal"
              style={{ marginTop: 0 }}
              onChange={handleTextFieldInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="end_time">Hora de fin</InputLabel>
            <TextField
              id="end_time"
              label=""
              type="time"
              fullWidth
              margin="normal"
              style={{ marginTop: 0 }}
              onChange={handleTextFieldInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="summary">Resumen</InputLabel>
            <TextField
              id="summary"
              label=""
              multiline
              rows={4}
              fullWidth
              margin="normal"
              style={{ marginTop: 0 }}
              onChange={handleTextFieldInputChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpenForm(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={saveButtonClicked} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddActivityForm;
