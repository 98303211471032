import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../../contexts/userContext/userContext';
import genericDataService from '../../services/genericDataService';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { createStyles } from '@material-ui/core/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import Loading from '../loading/loading';
import moment from 'moment';
import prettyBytes from 'pretty-bytes';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import Alert from '../app/alert';

const AttachmentsDialog = (props) => {

  const useStyles = makeStyles(theme => createStyles({ previewChip: { minWidth: 160, maxWidth: 210 } }));
  const classes = useStyles();
  /* Importo el user context para obtener el branch selected */
  const me = useContext(UserContext);
  const [uploadList, setUploadList] = useState([]);
  const [attachmentsList, setAttachmentList] = useState([]);
  const [dropzoneDialogOpen, setDropzoneDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(-1);
  const [alert, setAlert] = useState({
      severity:"success",
      open: false,
      message:""
    });

  const handlerDropzoneDialogOpen = () => setDropzoneDialogOpen(true);
  const handlerDropzoneDialogClose = () => setDropzoneDialogOpen(false);

  const upload = () => {
    if (uploadList.length) {
      const data = new FormData();
      // eslint-disable-next-line array-callback-return 
      uploadList.map((file) => {
        data.append("files[]", file, file.name);
      })
      new genericDataService("/activities/" + props.activity.id + "/attachments")
        .upload(data)
        .then((res) => {
          setDropzoneDialogOpen(false);
          setAlert({
            severity: 'success',
            message: res.data.message,
            open: true
          });
          updateFileList();
          props.refreshParent();
        })
        .catch((error) => {
          setDropzoneDialogOpen(false);
          updateFileList();
          props.refreshParent();
          setAlert({
            severity: 'error',
            message: error.message,
            open: true
          });
        });
    }
    return false;
  };

  const deleteFile = (activityCommmentId) => {
    if (activityCommmentId > 0) {
      setLoading(true);
      new genericDataService("/activities/" + props.activity.id + "/attachments")
        .destroy(activityCommmentId)
        .then((res) => {
          updateFileList();
          setLoading(true);
          props.refreshParent();
        })
        .catch((error) => {
          updateFileList();
          setLoading(true);
          props.refreshParent();
        });
    }
  };

  const updateFileList = () => {
    setLoading(true);
    new genericDataService("")
      .lookup("/activities/" + props.activity.id + '/attachments')
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        setAttachmentList(data);
      }).catch((err) => { });
  }

  useEffect(() => {
    if (props.activity?.id > 0 && props.activityAttachmentsOpen) {
      setLoading(false);
      updateFileList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activity])

  useEffect(() => {
    if(me.user.actual_branch > 0) {
      setUserId(me.user.id);
    }
  }, [me]);


  const download = (base64Content, fileName) => {
      const base64ImageData = base64Content; //'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==';
      const contentType = base64ImageData.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: contentType});
      const blobUrl = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = blobUrl;
      a.target = "_blank";
      a.click();
  }
  
  return (
    <>
    <Alert onClose={()=>setAlert({...alert, open: false})} alert={alert} setAlert={setAlert}/>
    <Dialog scroll="paper" fullWidth maxWidth="sm" aria-labelledby="attachment-list" open={props.activityAttachmentsOpen}>
      <DialogTitle id="attachment-list">
        Gestor de documentos adjuntos
        <IconButton
          disabled={props.activity.status_name !== "pendiente" || userId !== props.activity.user_id}
          style={{ cursor: 'pointer', float: 'right' }}
          edge="end" onClick={handlerDropzoneDialogOpen}
          color="primary"
          aria-label="Subir archivos">
            <AddCircleOutlineIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DropzoneDialog
          acceptedFiles={["image/jpeg", "image/png", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/pdf"]}
          maxFileSize={4000000}
          getFileAddedMessage={(fileName) => fileName + " agregado."}
          getFileRemovedMessage={(fileName) => fileName + " eliminado."}
          getDropRejectMessage={(file) => file.name + " " + (prettyBytes(file.size)) + " rechazado. Máximo permitido: " + prettyBytes(4000000)}
          getFileLimitExceedMessage={(filesLimit) => "Se pueden subir hasta " + filesLimit + " archivos a la vez"}
          open={dropzoneDialogOpen}
          onClose={handlerDropzoneDialogClose}
          onSave={() => upload()}
          onChange={(files) => setUploadList(files)}
          showPreviews={false}
          showPreviewsInDropzone={true}
          dropzoneText="Arrastre un archivo o haga click para seleccionar"
          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
          previewChipProps={{ classes: { root: classes.previewChip } }}
          previewText="Archivos seleccionados"
          cancelButtonText="Cancelar"
          dialogTitle="Seleccione el archivo a adjuntar"
          submitButtonText="Adjuntar"
        />
        <DialogContentText></DialogContentText>
        {loading ?
          <Loading open={true} />
          :
          <List component="nav" aria-label="adjuntos">
            {
              attachmentsList.length ? 
                attachmentsList.map((attachment) =>
                  <ListItem key={attachment.id} button onClick={() => download(attachment.base64content, attachment.content)}>
                    <ListItemText primary={attachment.content} secondary={moment(attachment.updated_at).format('DD/MM/YY HH:mm')} />
                    {
                      userId !== props.activity.user_id ? <></>
                      : 
                      <ListItemSecondaryAction>
                        <IconButton disabled={props.activity.status_name !== "pendiente"} edge="end" aria-label="delete" onClick={() => deleteFile(attachment.id)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </ListItemSecondaryAction> 
                    }
                  </ListItem>
                )
              :
              <ListItem key={0}>
                <ListItemText primary='Esta actividad no tiene documento adjuntos' />
              </ListItem>
            }
          </List>
        }
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => props.setActivityAttachmentsOpen(false)} color="primary">Cerrar</Button>
      </DialogActions>
    </Dialog>
    </>
  )
}

export default AttachmentsDialog;