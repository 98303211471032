import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";

//Date filters
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//Button search
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const SalariesToolbar = (props) => {
  const { onSearch, filtersData, setFiltersData } = props;

  const [isDateValid, setIsDateValid] = useState(false);

  const handleDateFromChange = (date) => {
    if (!isNaN(date)) {
      setFiltersData({
        ...filtersData,
        dateFrom: date,
      });
      setIsDateValid(true);
    }
  };

  const handleDateToChange = (date) => {
    if (!isNaN(date)) {
      setFiltersData({
        ...filtersData,
        dateTo: date,
      });
      setIsDateValid(true);
    }
  };

  const searchButtonClicked = () => {
    if (!isDateValid) {
      const today = new Date();

      if (!filtersData.dateFrom && !filtersData.dateTo) {
        setFiltersData({
          ...filtersData,
          dateFrom: today,
          dateTo: today,
        });
      } else if (!filtersData.dateTo) {
        setFiltersData({
          ...filtersData,
          dateTo: today,
        });
      } else if (!filtersData.dateFrom) {
        const dateFromyesterday = new Date(filtersData.dateTo);
        dateFromyesterday.setDate(dateFromyesterday.getDate() - 1);
        setFiltersData({
          ...filtersData,
          dateFrom: dateFromyesterday,
        });
      }
    }

    onSearch(filtersData);
  };

  return (
    <Grid container alignItems="center" alignContent="center">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <Grid item md={4}>
          <Box mx={3} my={2}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-from"
              label="Desde"
              format="dd/MM/yyyy"
              disableFuture
              cancelLabel="Cancelar"
              value={filtersData.dateFrom}
              autoOk={true}
              onChange={handleDateFromChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Box>
        </Grid>

        <Grid item md={4}>
          <Box mx={3} my={2}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-from"
              label="Hasta"
              format="dd/MM/yyyy"
              disableFuture
              cancelLabel="Cancelar"
              showTodayButton
              autoOk={true}
              todayLabel="Hoy"
              value={filtersData.dateTo}
              onChange={handleDateToChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Box>
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid item xs={12} md={4}>
        <Box mx={3} mt={4} mb={2}>
          <Button
            onClick={searchButtonClicked}
            fullWidth
            variant="contained"
            color="primary"
            endIcon={<SearchIcon />}
          >
            Buscar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SalariesToolbar;
