import React from 'react';
import { Grid } from '@material-ui/core';
import ActivityListTraceTickets from './activityTracerTickets/activityTracerTickets'

const TraceActivitiesTicketsList = () =>  {

  return (
    <Grid container>
       <Grid xs={12} item>
        <ActivityListTraceTickets />
       </Grid>
    </Grid>
  );
}

export default TraceActivitiesTicketsList;