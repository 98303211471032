import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../contexts/userContext/userContext";
import genericDataService from "../../services/genericDataService";
import Loading from "../loading/loading";
import moment from "moment";
import Gravatar from "react-gravatar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  ListItemAvatar,
  Avatar,
  OutlinedInput,
  DialogTitle,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ButtonGroup,
  InputAdornment,
  FormControl,
  InputLabel,
  Tooltip,
  Badge,
  ListItemSecondaryAction,
} from "@material-ui/core";
import Alert from "../app/alert";

//Icons
import EventNoteIcon from "@material-ui/icons/EventNote";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import MessageIcon from "@material-ui/icons/Message";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import TripOriginIcon from "@material-ui/icons/TripOrigin";

const ActivityDetailDialog = (props) => {
  function DetailField(props) {
    const { field, fieldName, icon } = props;

    return (
      <Box my={2}>
        <Grid container alignItems="flex-end">
          <Grid item xs={12} md={4}>
            <Typography variant="button">{fieldName}</Typography>
          </Grid>
          <Grid item xs={10} md={6}>
            <Typography variant="subtitle1">{field}</Typography>
          </Grid>
          <Grid item xs={2}>
            {icon}
          </Grid>
        </Grid>
      </Box>
    );
  }

  /* Importo el user context para obtener el branch selected */
  const me = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [canVote, setCanVote] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);
  const [comments, setComments] = useState([]);
  const [supervisorsId, setSupervisorsId] = useState(null);
  const [alert, setAlert] = useState({
    severity: "success",
    open: false,
    message: "",
  });
  const [content, setContent] = useState("");

  const onApproveClick = () => {
    const r = window.confirm("¿Desea aprobar la actividad?");

    if (r) {
      const approveActivityDataService = new genericDataService(
        "/activities/" + props.activity.id + "/approve"
      );

      setLoading(true);
      approveActivityDataService
        .store({ comment: content !== "" ? content : undefined })
        .then(() => {
          setLoading(false);
          setHasVoted(true);
          setCanVote(false);
          setComments([
            ...comments,
            {
              id: 99,
              user_id: me.user.id,
              content: content,
              vote: 1,
              user: {
                job_email: me.user.job_email,
                username: me.user.username
              },
            },
          ]);
          setContent("");
        });
    }
  };

  const onRejectClick = () => {
    const r = window.confirm("¿Desea rechazar la actividad?");

    if (r) {
      const approveActivityDataService = new genericDataService(
        "/activities/" + props.activity.id + "/reject"
      );

      setLoading(true);
      approveActivityDataService
        .store({ comment: content !== "" ? content : undefined })
        .then(() => {
          setLoading(false);
          setHasVoted(true);
          setCanVote(false);
          setComments([
            ...comments,
            {
              id: 99,
              user_id: me.user.id,
              content: content,
              vote: 0,
              user: {
                job_email: me.user.job_email,
                username: me.user.username
              },
            },
          ]);
          setContent("");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (props.activity) {
      setComments(props.activity.comments);
      const activityTypeDataService = new genericDataService(
        "/activities/" + props.activity.id + "/approvers"
      );

      setLoading(true);
      activityTypeDataService
        .index()
        .then((res) => {
          setSupervisorsId(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [props.activity]);

  let votesComments =
    comments?.filter((comment) => comment.vote !== null) ?? [];

  if (supervisorsId !== null && canVote === null) {
    let filteredVotesComments = votesComments.filter(function (vote) {
      return vote.user_id === me.user.id;
    });
    let userCanVote =
      props?.activity?.status_name === "pendiente" &&
      filteredVotesComments.length === 0;
    userCanVote = userCanVote && supervisorsId.find((id) => id === me.user.id);
    if (!userCanVote) setCanVote(false);
    else setCanVote(true);
  }

  return (
    <>
      <Alert
        onClose={() => setAlert({ ...alert, open: false })}
        alert={alert}
        setAlert={setAlert}
      />
      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="sm"
        aria-labelledby="activity-detail"
        open={props.activityDetailOpen}
      >
        <DialogTitle id="detail">Detalle de actividad</DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <Loading open={true} />
          ) : (
            <>
              <Box color="primary" borderRadius={40} p={2} boxShadow={2}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <DetailField
                      field={moment(props.activity.start).format(
                        "DD/MM/YY HH:mm"
                      )}
                      fieldName="Inicio"
                      icon={<EventNoteIcon />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DetailField
                      field={moment(props.activity.end).format(
                        "DD/MM/YY HH:mm"
                      )}
                      fieldName="Fin"
                      icon={<EventNoteIcon />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DetailField
                      field={props.activity?.user?.job_email}
                      fieldName="Colaborador"
                      icon={<PermIdentityIcon />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DetailField
                      field={props.activity?.type?.full_name}
                      fieldName="Tipo"
                      icon={<WorkOutlineIcon />}
                    />
                  </Grid>
                  {props.activity?.type?.name !== "Vacaciones" ? (
                    <Grid item xs={12} sm={6}>
                      <DetailField
                        field={
                          <Box mt={1}>
                            {" "}
                            {props.activity?.lunch ? (
                              <CheckIcon />
                            ) : (
                              <ClearIcon />
                            )}
                          </Box>
                        }
                        fieldName="Almuerzo"
                        icon={<LocalDiningIcon />}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12} sm={6}>
                    <DetailField
                      field={props.activity?.summary}
                      fieldName="Descripción"
                      icon={<MessageIcon />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DetailField
                      field={props.activity?.status_name}
                      fieldName="Estado"
                      icon={<TripOriginIcon />}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={4}>
                <Typography variant="h5">
                  Votos{" "}
                  {
                    <Badge
                      badgeContent={votesComments.length}
                      color="secondary"
                    >
                      <ThumbsUpDownIcon />
                    </Badge>
                  }
                </Typography>
              </Box>
              <List component="nav" aria-label="comentarios">
                {votesComments.length ? (
                  votesComments.map((vote) => (
                    <ListItem key={vote.id}>
                      <ListItemAvatar>
                        <Tooltip arrow title={vote.user.job_email}>
                          <Avatar style={{ width: 50, height: 50 }}>
                            <Gravatar
                              size={50}
                              rating="pg"
                              default={"mp"}
                              email={vote.user.job_email ?? ""}
                            />
                          </Avatar>
                        </Tooltip>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${vote.user.username}`}
                        secondary={vote.content ?? "-"}
                      />
                      <ListItemSecondaryAction>
                        {vote.vote ? <ThumbUpIcon /> : <ThumbDownIcon />}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                ) : (
                  <ListItem key={0}>
                    <ListItemText primary="Esta actividad no tiene votos" />
                  </ListItem>
                )}
              </List>
            </>
          )}
          {!canVote ? (
            <></>
          ) : (
            <Box mt={6}>
              <Box my={4}>
                <Typography variant="h6">Votar</Typography>
              </Box>
              <Grid container>
                <Grid item xs={12} md={10}>
                  <Box mr={3} mb={2}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Comentario (opcional)
                      </InputLabel>
                      <OutlinedInput
                        value={content}
                        onChange={(event) => {
                          setContent(event.target.value);
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <MessageIcon />
                          </InputAdornment>
                        }
                        labelWidth={160}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box my={1}>
                    <ButtonGroup
                      disableElevation
                      variant="contained"
                      color="primary"
                    >
                      <Button onClick={onApproveClick}>
                        {" "}
                        <ThumbUpIcon />{" "}
                      </Button>
                      <Button onClick={onRejectClick}>
                        {" "}
                        <ThumbDownIcon />{" "}
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              props.setActivityDetailOpen(false);
              setContent("");
              setCanVote(null);
              setSupervisorsId(null);
              if(hasVoted) {
                props.refreshParent();
              }
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivityDetailDialog;
