import materialTableLocalization from "../../../helpers/localization/materialTableLocalization.jsx";

/* Material table title */
const title = "Administración de roles";

/* Material table options */
const options = {
  editable: true,
  search: true,
  addRowPosition: "first",
  pageSize: 10,
  pageSizeOptions: [10, 20, 50, 100, 500],
  exportButton: false,
  exportAllData: false,
  sorting: false,
  toolbarButtonAlignment: "left",
  initialPage: 0,
  columnsButton: true,
};

export { title, materialTableLocalization as localization, options };
