import React, { useState, useContext, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import getColumnDefinitions, { localization, options, title } from "./voteActivities.def";
import UserContext from "../../../../contexts/userContext/userContext.jsx";
import genericDataService from "../../../../services/genericDataService";

import AttachmentsDialog from "../../../../components/attachmentsDialog/attachmentsDialog.jsx";
import ActivityDetailDialog from "../../../../components/ActivityDetailDialog/activityDetailDialog.jsx";
import VoteActivitiesToolbar from "./voteActivitiesToolbar";

const tableVoteActivities = React.createRef();

const TraceActivitiesDataService = new genericDataService("/activities/tracer");

const PendingVotesDataService = new genericDataService("/users/pending-votes");

const approveActivityDataService = new genericDataService(
  "/activities/massive-approve"
);

const VoteActivities = (props) => {
  const onChangeRowsPerPage = (rowsPerPage) => {
    setPageSize(rowsPerPage);
  };

  const me = useContext(UserContext);
  const isAdminRRHH = me.user?.roles?.some(role => role.full_name === "Administrador de RRHH");

  const [userHasPermissions, setUserHasPermissions] = useState(true);
  const [filtersData, setFiltersData] = useState({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(),
    user_id: [],
  });
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [activeUserFlag, setActiveUserFlag] = useState(true);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [areas, setAreas] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [allActivityTypes, setAllActivityTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [activityTypeSelected, setActivityTypeSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);

  const [activity, setActivity] = useState(0);
  const [activityDetailOpen, setActivityDetailOpen] = useState(false);
  const [activityAttachmentsOpen, setActivityAttachmentsOpen] = useState(false);
  const [queryData, setQueryData] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const columns = getColumnDefinitions(setActivity, setActivityDetailOpen, setActivityAttachmentsOpen);

  const [data, setData] = useState(() => () => {
    return PendingVotesDataService.emptyTable();
  });

  const READ_ACTIVITY_TRACER_PERMISSION = "read_activity_tracer";

  useEffect(() => {
    if(me.user.actual_branch > 0)
      refreshTable();
  }, [me]);

  const addComment = (comment) => {
    setActivity({ ...activity, comments: [...activity.comments, comment] });
  };

  const massiveApprove = (comments) => {
    setLoading(true); 
    queryData.comment = comments;
    queryData.activities = filtersData.activities_id;
    const { activities_id, ...newFiltersData } = filtersData;
    approveActivityDataService.store(queryData, filtersData).then(() => {
      refreshTable(newFiltersData);
    }).catch(() => { refreshTable(newFiltersData); });
  };

  const refreshTable = (filters) => {
    setLoading(true);

    if (filters && 'activities_id' in filters) {
      const { activities_id, ...newFiltersData } = filters;
  
      setData(() => (query) => {
        setQueryData(query);
        return PendingVotesDataService.rows(query, newFiltersData).then((value) => {
          return value;
        });
      });
    } else {
      setData(() => (query) => {
        setQueryData(query);
        return PendingVotesDataService.rows(query, filters).then((value) => {
          return value;
        });
      });
    }

    tableVoteActivities.current.onQueryChange();
    setLoading(false);
  };

  const areaChanged = (area) => {
    if (area) {
      let areaUsers = allUsers.filter(
        (user) => "" + area.id in user.available_areas
      );
      setUsers(areaUsers);
    } else {
      setUsers(allUsers);
    }
  };

  const userChanged = (user) => {
    if (user) {
      let userAreas = allAreas.filter(
        (area) => "" + area.id in user.available_areas
      );

      let userActivityTypes = allActivityTypes.filter(
        (activityType) => "" + activityType.area_id in user.available_areas
      );
      setAreas(userAreas);
      setActivityTypes(userActivityTypes);
    } else {
      setAreas(allAreas);
      setActivityTypes(allActivityTypes);
    }
  };

  const activityTypeChanged = (activityType) => {
    if (activityType) {
      let activityTypeUsers = allUsers.filter(
        (user) => "" + activityType.area_id in user.available_areas
      );
      let activityTypeAreas = allAreas.filter(
        (area) => "" + activityType.area_id == area.id
      );
      let activityTypeCategories = allCategories.filter(
        (category) => "" + activityType.category_id == category.id
      );

      setUsers(activityTypeUsers);
      setAreas(activityTypeAreas);
      setCategories(activityTypeCategories);
    } else {
      setUsers(allUsers);
      setAreas(allAreas);
      setCategories(allCategories);
    }
  };

  const categoryChanged = (category) => {
    if(category){
      let categoryActivityTypes = allActivityTypes.filter(
        (activityType) => "" + activityType.category_id == category.id
      );
      setActivityTypes(categoryActivityTypes);
    } else {
      setCategories(allCategories);
    }
  }

  /* traigo todos los users para el select del filtro */
  useEffect(() => {
    if (me.user.actual_branch > 0 && userHasPermissions) {
      setLoading(true);
      TraceActivitiesDataService.lookup("/users/all")
        .then((res) => {
          let usersArray = res.data.filter(
            (user) => user.active === activeUserFlag
          );
          setUsers(usersArray);
          setAllUsers(usersArray);
          if (props.location.state.user_id) {
            setFiltersData({
              ...filtersData,
              user_id: [props.location.state.user_id],
              start: props.location.state.dateFrom,
              end: props.location.state.dateTo,
            });
            const user = res.data.find(
              (u) => u.id === props.location.state.user_id
            );
            setUserSelected(user);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasPermissions, activeUserFlag]);

  /* traigo todas las categorias para el select del filtro */
  useEffect(() => {
    TraceActivitiesDataService.lookup("/categories/all")
      .then((res) => {
        setCategories(res.data);
        setAllCategories(res.data);
      })
      .catch((err) => {});
  }, []);

  /* traigo todas las activity_type para el select del filtro */
  useEffect(() => {
    if (areaSelected) {
      let idAreaSelected = areaSelected["id"];
      let filteredActivityTypes = activityTypes.filter(
        (activityType) => activityType["area_id"] === idAreaSelected
      );
      setActivityTypes(filteredActivityTypes);
    } else {
      if (me.user.actual_branch > 0) {
        let permission = me.user.menu_permissions.includes(
          READ_ACTIVITY_TRACER_PERMISSION
        );
        if (!permission) {
          updateUserActivityTypes(me.user.available_activity_types);
        } else {
          updateActivityTypes();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSelected, me]);

  /* traigo todos los status para el select del filtro */
  useEffect(() => {
    TraceActivitiesDataService.lookup("/statuses/all")
      .then((res) => {
        setStatuses(res.data);
      })
      .catch((err) => {});
  }, []);

  const updateUserActivityTypes = (availableActivityTypes) => {
    let userActivityTypes = [];

    availableActivityTypes.forEach((availableActivityType) => {
      availableActivityType["activity_types"].forEach((activityType) => {
        userActivityTypes.push(activityType);
      });
    });

    setActivityTypes(userActivityTypes);
  };

  const updateActivityTypes = () => {
    TraceActivitiesDataService.lookup("/activity_types/all").then((res) => {
      setActivityTypes(res.data);
      setAllActivityTypes(res.data);
    });
  };

  const updateUserAreas = (areas) => {
    let userAreas = [];
    Object.entries(areas).forEach(([key, value]) => {
      let area = {
        id: key,
        name: value,
      };
      userAreas.push(area);
    });
    setAreas(userAreas);
  };

  const updateAreas = () => {
    TraceActivitiesDataService.lookup("/areas/all")
      .then((res) => {
        setAreas(res.data);
        setAllAreas(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (me.user.actual_branch > 0) {
      let permission = me.user.menu_permissions.includes(
        READ_ACTIVITY_TRACER_PERMISSION
      );
      setUserHasPermissions(permission);

      //set user, areas disponibles y activity types disponibles
      if (!permission) {
        setFiltersData({
          ...filtersData,
        });
        updateUserActivityTypes(me.user.available_activity_types);
        updateUserAreas(me.user.available_areas);
      } else {
        updateActivityTypes();
        updateAreas();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    <>
      <AttachmentsDialog
        activity={activity}
        activityAttachmentsOpen={activityAttachmentsOpen}
        setActivityAttachmentsOpen={setActivityAttachmentsOpen}
        refreshParent={refreshTable}
      />
      <ActivityDetailDialog
        activity={activity}
        addComment={addComment}
        activityDetailOpen={activityDetailOpen}
        setActivityDetailOpen={setActivityDetailOpen}
        refreshParent={refreshTable}
      />
      <MaterialTable
        tableRef={tableVoteActivities}
        columns={columns}
        toolbar={true}
        title={title}
        data={data}
        isLoading={loading}
        loadingType={"linear"}
        localization={localization}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSelectionChange={(rows) => {
          setFiltersData((prevFiltersData) => ({
            ...prevFiltersData,
            activities_id: rows.map((row) => row.id),
          }));
        }}
        options={{
          ...options,
          pageSize: pageSize,
          emptyRowsWhenPaging: false,
        }}
        components={{
          Toolbar: (props) => (
            <>
              <MTableToolbar {...props} />
              <VoteActivitiesToolbar
                isAdminRRHH={isAdminRRHH}
                users={users}
                setUsers={setUsers}
                userChanged={userChanged}
                categories={categories}
                areas={areas}
                areaChanged={areaChanged}
                categoryChanged={categoryChanged}
                activityTypeChanged={activityTypeChanged}
                setAreas={setAreas}
                activityTypes={activityTypes}
                statuses={statuses}
                onSearch={refreshTable}
                filtersData={filtersData}
                userSelected={userSelected}
                setUserSelected={setUserSelected}
                categorySelected={categorySelected}
                setCategorySelected={setCategorySelected}
                areaSelected={areaSelected}
                setAreaSelected={setAreaSelected}
                activityTypeSelected={activityTypeSelected}
                setActivityTypeSelected={setActivityTypeSelected}
                statusSelected={statusSelected}
                setStatusSelected={setStatusSelected}
                setFiltersData={setFiltersData}
                dropdownUserAppear={userHasPermissions}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                activeUserFlag={activeUserFlag}
                setActiveUserFlag={setActiveUserFlag}
                massiveApprove={massiveApprove}
              />
            </>
          ),
        }}
        actions={[
        ]}
      />
    </>
  );
};
export default VoteActivities;
