import materialTableLocalization from '../../../helpers/localization/materialTableLocalization.jsx';
import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import decimalToHumanHours from '../../../helpers/dateUtils/dateFormatter';

/* Material table title */
const title = "Reporte liquidación de sueldos";

/* Material table columns defs. table activity */
const columns = [
  {
    title: 'Área',
    field: 'actual_area.name',
    render: (rowData) => rowData.actual_area?.name ?? 'Sin área asignada',
    editable: 'never',
    width: "10%",
  },
  {
    title: 'Colaborador',
    field: 'full_name',
    editable: 'never',
    width: "15%" ,
    render: (rowData) => rowData.full_name
  },
  {
    title: 'Fecha de ingreso',
    field: 'admission_date',
    editable: 'never',
    width: "15%",
    render: (rowData) => rowData.admission_date
  },
  {
    title: (
      <Tooltip title={"Suma de horas cargadas en el sistema HCM+."}>
        <span>{"Horas Trabajadas"}</span>
      </Tooltip>
    ),
    field: 'worked_hours',
    render: (rowData) => decimalToHumanHours(rowData.worked_hours),
    width: "10%",
    editable: 'never',
    align: 'center'
  },
  {
    title: (
      <Tooltip
        title={"Carga horaria obligatoria de la jornada."}
      >
        <span>{"Horas Laborales"}</span>
      </Tooltip>
    ),
    field: 'workable_hours',
    render: (rowData) => rowData.workable_hours,
    width: "10%",
    editable: 'never',
    align: 'center'
  },
  {
    title: 'Saldo horas del periodo',
    field: 'hours_balance',
    editable: 'never',
    width: "10%",
    render: (rowData) => {
      const color = rowData.hours_balance > 0 ? "primary" : rowData.hours_balance < 0 ? "secondary" : "default";
      return  <Chip
                label= { (rowData.hours_balance < 0 ? "- ": "") + decimalToHumanHours(Math.abs(rowData.hours_balance)) }
                color={color}
              />
    },
    align: 'center'
  },
  {
    title: 'Saldo horas total',
    field: 'total_hours_balance',
    editable: 'never',
    width: "10%",
    render: (rowData) => {
      const color = rowData.total_hours_balance > 0 ? "primary" : rowData.total_hours_balance < 0 ? "secondary" : "default";
      return  <Chip
                label= { (rowData.total_hours_balance < 0 ? "- ": "") + decimalToHumanHours(Math.abs(rowData.total_hours_balance)) }
                color={color}
              />
    },
    align: 'center',
    hidden: false
  },
  {
    title: 'Horas licencia por enfermedad',
    field: 'sickness_licence_total_hours',
    editable: 'never',
    width: "15%",
    render: (rowData) => rowData.sickness_licence_total_hours,
    align: 'center'
  },
  {
    title: 'Saldo vacaciones actual',
    field: 'vacation_amount',
    editable: 'never',
    width: "15%",
    render: (rowData) => rowData.vacation_amount,
    align: 'center'
  },
  {
    title: 'Horas licencia por examen',
    field: 'exam_licence_total_hours',
    editable: 'never',
    width: "15%",
    render: (rowData) => rowData.exam_licence_total_hours,
    align: 'center'
  },
];

export { columns, materialTableLocalization as localization, title };
