import React, { useState, useEffect, useContext } from "react";
import { Grid, Box } from "@material-ui/core";
import useStyles from "./dashboard.css.jsx";
import genericDataService from "../../../services/genericDataService";
import UserContext from "../../../contexts/userContext/userContext.jsx";
import CardWiki from "./cardWiki.jsx";
import CardStartEndActivity from "./cardStartEndActivity.jsx";
import CardNextHoliday from "./cardNextHoliday.jsx";
import CardVacationAmount from "./cardVacationAmount.jsx";
import CardBalanceHours from "./cardBalanceHours.jsx";

const nextHoliday = new genericDataService("/holidays");

const Dashboard = (props) => {
  const [holiday, setHoliday] = useState({});
  const [last_activity_type_id, setLastActivityTypeId] = useState(0);
  const [last_activity_description, setLastActivityDescription] =
    useState("Trabajo");
  const me = useContext(UserContext);
  const [zoomIn, setZoomIn] = useState(false);
  const [vacation, setVacation] = useState({});
  const [balanceHours, setBalanceHours] = useState(0);
  const velZoomIn = "100ms";

  useEffect(() => {
    nextHoliday
      .lookup("/holidays/next")
      .then((res) => {
        setHoliday(res.data);
      })
      .catch((err) => props.history.replace("/error"));
  }, [props]);

  useEffect(() => {
    if (me.user.actual_branch > 0 && me.user.id) {
      setVacation({
        amount: me.user.vacation_amount,
        inARow: me.user.vacation_in_a_row,
      });

      new genericDataService(`/users/${me.user.id}/last-balance-hour`)
        .index()
        .then((res) => {
          setBalanceHours(res.data);
        })
        .catch((err) => props.history.replace("/error"));

      new genericDataService("/activity_types/suggested")
        .index()
        .then((res) => {
          setLastActivityTypeId(res.data["id"]);
          setLastActivityDescription(res.data["name"]);
          setZoomIn(true);
        })
        .catch((err) => props.history.replace("/error"));
    }
  }, [me, props]);

  const classes = useStyles();

  return (
    <Box style={{ padding: 20 }}>
      <Grid container spacing={1}>
        {typeof holiday == "object" && "date_from" in holiday ? (
          <CardNextHoliday
            {...props}
            zoomIn={zoomIn}
            velZoomIn={velZoomIn}
            classes={classes}
            holiday={holiday}
          />
        ) : (
          <></>
        )}
        <CardStartEndActivity
          {...props}
          last_activity_type_id={last_activity_type_id}
          last_activity_description={last_activity_description}
          zoomIn={zoomIn}
          velZoomIn={velZoomIn}
          classes={classes}
        />
        <CardWiki
          {...props}
          classes={classes}
          zoomIn={zoomIn}
          velZoomIn={velZoomIn}
        />
        <CardVacationAmount
          {...props}
          classes={classes}
          zoomIn={zoomIn}
          velZoomIn={velZoomIn}
          vacationAmount={vacation.amount}
          vacationInARow={vacation.inARow}
          me={me}
        />
        <CardBalanceHours
          {...props}
          classes={classes}
          zoomIn={zoomIn}
          velZoomIn={velZoomIn}
          balanceHours={balanceHours}
        />
      </Grid>
    </Box>
  );
};

export default Dashboard;
