const decimalToHumanHours = (decimalHour) => {
    const hours = Math.floor(decimalHour);
    const minutes = Math.round((decimalHour - hours) * 60);
  
    let minutesLabel = '';
    if(minutes > 0) {
      minutesLabel = minutes + ' m';
    }
  
    let hoursLabel = '';
    if (hours >= 0) {
      hoursLabel = hours + ' h';
    }
    return hoursLabel + ' ' + minutesLabel
  }

export default decimalToHumanHours;