import React from 'react';
import { useParams } from "react-router-dom";
import { Grid, Paper } from '@material-ui/core';
import ActivityList from './activityList/activityList.jsx'

const Activities = () =>  {

  //Data = Id del usuario, es opcional
  let {activity_type_id} = useParams();
  return (
    <Grid container>
       <Grid xs={12} item component={Paper}>
        <ActivityList activity_type_id={activity_type_id}/>
       </Grid>
    </Grid>


  );
}

export default Activities;