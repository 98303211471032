import materialTableLocalization from '../../../helpers/localization/materialTableLocalization.jsx';

/* Material table title */
const title = "Administración de Areas";

/* Material table columns defs */
const columns = [
  {title: 'Nombre', field: 'name', validate: rowData => rowData.name !== ''},
  {title: 'Descripcion', field: 'description', validate: rowData => rowData.description !== ''}
]

/* Material table options */
const options = 
{
  editable: false,
  search: true,
  pageSize: 50,
  emptyRowsWhenPaging: false,
  pageSizeOptions: [10, 20, 50, 100, 500],
  addRowPosition: "first",
  exportButton: true,
  exportAllData: true,
  sorting: true,
  actionsColumnIndex: -1,
  toolbarButtonAlignment:'left',
  initialPage: 0
}

export default columns;
export { title, materialTableLocalization as localization, options };
