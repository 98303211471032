import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Loading from '../../loading/loading';
import Slide from '@material-ui/core/Slide';

import genericDataService from '../../../services/genericDataService';
import { Box } from '@material-ui/core';


const holidayDataService = new genericDataService("/holidays/coming");

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 2000,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ListHolidays = (props) => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [slideIn, setSlideIn] = useState(false);


  useEffect(() => {
    setLoading(true);
    holidayDataService.index().then((res) => {
          setData(res.data);
          setLoading(false);
          setSlideIn(true);
        }).catch(() => console.log("500"));
    }, [])

    const sizeFecha = {
        fontSize: 30,
      };

    const paddingText = {
        paddingLeft: 0
    };

  return (
    <Box style={{ padding: 20 }}>
      <Loading open={loading} />
      <Typography variant="h6" className={classes.title}>
      Próximos feriados del año
      </Typography>

      <Slide direction="up" in={slideIn}>
        <List component="nav" className={classes.root} aria-label="feriados">
            {Object.values(data).map((feriado, index) => (
                <ListItem  button key={index}>
                    <ListItemIcon style={sizeFecha}>
                        <Box style={{width: 100, textAlign:"center"}}>
                          {((new Date(Date.parse(feriado.date_from))).getDate()).toString() 
                          + '/'
                          + ((new Date(Date.parse(feriado.date_from))).getMonth() + 1).toString()}
                        </Box>
                    </ListItemIcon>
                <ListItemText style={paddingText} primary={feriado.description} secondary={feriado.date_from_human_diff} />
              </ListItem>
        ))}
        </List>
      </Slide>
    </Box>
  );
}

export default ListHolidays;
