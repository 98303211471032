import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem,
  Tooltip, Avatar, FormControl, Select, Box, Slide } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import Gravatar from 'react-gravatar'

import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

/* importo el userContext que provee info del usuario logueado */
import UserContext from '../../contexts/userContext/userContext.jsx';

/* Importo la funcion que obtiene del localstorage el item themeSelected, true => dark, false => light */
import getThemeSelected  from '../../helpers/theme/theme.jsx'

/* Importo la extensión de los estilos propios del componente */
import useStyles from './topbar.css.jsx'

import menu from '../../menu/menu.jsx';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide timeout={700} easing={{ enter: 4000, exit: 16000, }} appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Topbar = (props) =>  {
  /* Extiende los estilos de mui */
  const classes = useStyles();

  const me = useContext(UserContext);

  /* userMenu contiene los links que van a renderizarse en el submenu usuarios de la barra superior */
  const userMenu = menu.filter((routeItem) => "position" in routeItem && routeItem.position === "user-menu")

  /* estado del menu desplegable de usuarios */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  /* estado del switch de dark mode */
  const [darkMode, setDarkMode] = useState(getThemeSelected());

  /* handler que abre el menu de usuario */
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /* handler que cierra el menu de usuario */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* handler que cambia light / darkMode y eleva el estado por medio de props */
  const handleChangeDarkMode = (event) => {
    setDarkMode(!darkMode);
    props.onTheme(!darkMode); /* ontheme es un callback que viene por props */
  }

  const [areaId, setAreaId] = useState('');
  const [availableAreas, setAvailableAreas] = useState([]);

  useEffect(() => {
    let availableAreasArr = [];
    if (me.user.actual_area?.id > 0) {
      setAreaId(me.user.actual_area.id);
      for (const keyAreaId in me.user.available_areas) {
        availableAreasArr.push({
          id:   keyAreaId,
          name: me.user.available_areas[keyAreaId]
        });
      }
      setAvailableAreas(availableAreasArr);
    }
  }, [me.user]);

  const handleChageArea = (event) => {
    if (typeof(event)!='undefined') {
      setAreaId(event.target.value);
      me.updateActualArea(event);
      props.children.props.history.replace("/dashboard");
    }
  }

  return (
    <Box className={classes.root}>
      <Box sx={{ boxShadow:10 }}>
      <HideOnScroll {...props}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton tooltip="Menu" onClick={props.onToggleSidebar}  edge="start" className={classes.menuButton} color="inherit" aria-label="menu"> <MenuIcon /> </IconButton>
          <Typography variant="h6" className={classes.appName}>HCM+</Typography>
          <Typography className={classes.appVersion}>v1.4.1</Typography>
            {availableAreas.length > 1 ?
            <FormControl className={classes.formControl}>
              <Select
                name='selectAreaId'
                value={areaId}
                onChange={handleChageArea}
                className={classes.areaSelect}
                disableUnderline
                inputProps={{
                  classes: {
                      icon: classes.icon,
                  },
              }}
              >
              {
                availableAreas.map((area, objIndex) =>
                  <MenuItem key={objIndex} value={area.id}>{area.name}</MenuItem>
                )
              }
              </Select>
            </FormControl>
            : <></>}

          <Tooltip id="button-dark/light" title="Modo dark / light">
            <IconButton color="inherit" onClick={handleChangeDarkMode} name="mnode" > 
              { darkMode ? <Brightness4Icon /> : <Brightness7Icon /> }
            </IconButton>
          </Tooltip>
          <UserContext.Consumer>
          {
            (value) => (value.user.firstname ?? "") + " " + (value.user.middlename ?? "") + " " +  (value.user.lastname ?? "")
          }
          </UserContext.Consumer>
          <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu}  color="inherit">
            <Avatar>
              <UserContext.Consumer>
                { (value) => <Gravatar size={45} rating="pg" default="mp" email={(value.user.job_email ?? "")} /> }
              </UserContext.Consumer>
            </Avatar>
          </IconButton>
          <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right'}} open={open} onClose={handleClose}>
          {
            userMenu.map((userMenu, i) => <MenuItem key={i} onClick={handleClose} component={Link} to={userMenu.path}>{userMenu.text}</MenuItem>)
          }
          </Menu>
        </Toolbar>
      </AppBar>
      </HideOnScroll>
      </Box>
    </Box>
  );
}

export default Topbar;