 
class mtablePerm {

  constructor(_resource)
  {
		this.resource = _resource;
		this.mTableActions = {
      isEditable: () => false,
      isEditHidden: () => true,
      isDeletable: () => false,
      isDeleteHidden: () => true
    }

  }

  get()
  {
    return this.mTableActions
  }
  
  resolve(_permissions)
  {
		this.mTableActions = {
      isEditable: () => _permissions?.indexOf("update_" + this.resource)>-1,
      isEditHidden: () => !(_permissions?.indexOf("update_" + this.resource)>-1),
      isDeletable: () => _permissions?.indexOf("delete_" + this.resource)>-1,
      isDeleteHidden: () => !(_permissions?.indexOf("delete_" + this.resource)>-1),
      onRowAdd: _permissions?.indexOf("create_" + this.resource)>-1,
      onRowUpdate: _permissions?.indexOf("update_" + this.resource)>-1,
    }
    return this;
  }

}

export default mtablePerm;
