import moment from "moment-timezone";
import materialTableLocalization from "../../../../helpers/localization/materialTableLocalization.jsx";
import decimalToHumanHours from "../../../../helpers/dateUtils/dateFormatter";
import React from "react";
import { Tooltip } from "@material-ui/core";

/* Material table title */
const title = "Reporte de Horas";

/* Material table columns defs. table activity */
const columns = [
  {
    title: "Fecha",
    field: "date",
    render: (rowData) => moment(rowData.date).format("DD/MM/YY"),
    type: "date",
    editable: "never",
    width: "10%",
    filterPlaceholder: "Fecha",
  },
  {
    title: (
      <Tooltip
        title={"Carga horaria obligatoria de la jornada."}
      >
        <span>{"Laborales"}</span>
      </Tooltip>
    ),
    field: "workable_hours",
    editable: "never",
    width: "10%",
    align: "center",
    render: (rowData) => decimalToHumanHours(rowData.workable_hours),
  },
  {
    title: (
      <Tooltip title={"Suma de horas cargadas en el sistema HCM+."}>
        <span>{"Trabajadas"}</span>
      </Tooltip>
    ),
    field: "worked_hours",
    editable: "never",
    width: "10%",
    align: "center",

    render: (rowData) => decimalToHumanHours(rowData.worked_hours),
  },
  {
    title: (
      <Tooltip title={"Suma de horas reportadas en la plataforma de tickets."}>
        <span>{"Reportadas"}</span>
      </Tooltip>
    ),
    field: "reported_hours",
    editable: "never",
    width: "10%",
    align: "center",
    render: (rowData) => decimalToHumanHours(rowData.reported_hours),
  },
  {
    title: (
      <Tooltip
        title={"Porcentaje de horas reportadas en tickets vs las horas cargadas en el sistema."}
      >
        <span>{"Reportadas / Trabajadas"}</span>
      </Tooltip>
    ),
    field: "diff",
    editable: "never",
    width: "10%",
    align: "right",
    render: (rowData) => rowData.diff + " %",
  },
];

/* Material table options. table trace-activities */
const options = {
  editable: false,
  search: false,
  pageSizeOptions: [10, 20, 50, 100, 500],
  exportButton: true,
  exportAllData: true,
  sorting: true,
  actionsColumnIndex: -1,
  toolbarButtonAlignment: "left",
  initialPage: 0,
  filtering: false,
  rowStyle: (rowData) => {
    if (
      rowData.workable_hours != 0 &&
      (rowData.diff < 95 || rowData.diff > 105)
    ) {
      return {
        backgroundColor: "#e17676",
        color: "black",
      };
    }

    return {};
  },
};

export default columns;
export { title, materialTableLocalization as localization, options };
