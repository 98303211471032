import React, { useState, useEffect } from 'react'
//card
import { Box, Grid, TextField } from '@material-ui/core';
import genericDataService from '../../../../../services/genericDataService.jsx'
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AddressFields(props) {

  const { user, classes, readOnly, address, setAddress,
    creating, setEdited, citySelected, setCitySelected } = props;

  const [cities, setCities] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const citiesDataService = new genericDataService();

  useEffect(() => {
    if (user.actual_branch > 0 || user.active != null) {
      setAddress({
        city: user.address?.city,
        postalcode: user.address?.postalcode,
        address: user.address?.address,
      });

      citiesDataService.lookup('cities/search/'+user.address?.city, '')
          .then((res) => {
            if (res.data?.cities[0]) {
              setCitySelected(res.data?.cities[0]);
            }
          })
          .catch((err) => {})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleEdited = (value) => {
    if (!creating) setEdited(value);
  }

  return (
      <Grid container alignItems='center' alignContent='center'>
          <Grid item xs={12} sm={6}>
              <Box className={classes.widerField}>
              <Autocomplete
                  noOptionsText='Escriba para buscar'
                  options={cities}
                  disabled={readOnly}
                  value={citySelected}
                  onChange={(e, value, reason)=> {
                  if (reason === 'select-option') {
                      handleEdited(true);
                      setAddress({...address, city: value.id});
                      setCitySelected(value);
                  } else {
                      handleEdited(false);
                  }
                  }}
                  inputValue={searchCity}
                  onInputChange={(e, newInputValue) => {
                  setSearchCity(newInputValue);
                  if (newInputValue.length > 2) {
                      citiesDataService.lookup('cities/search/'+newInputValue, '')
                      .then((res) => {
                      setCities(res.data?.cities);
                      })
                      .catch((err) => {})
                  }
                  }}
                  getOptionLabel={(option) => option?.name ? option.name : ""}
                  renderInput={(params) => <TextField {...params}
                                          multiline
                                          rowsMax={2}
                                          label="Ciudad"
                                          margin="dense"
                                          />}
              />
              </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
          <TextField
              className={classes.margin}
              label="Código postal"
              size="medium"
              margin="dense"
              inputProps={
              { readOnly: readOnly, }
              }
              value={address.postalcode ?? ''}
              onChange={(e)=> {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                    handleEdited(true);
                    setAddress({...address, postalcode: e.target.value});
                }
              }}
          />
          </Grid>
          <Grid item xs={12}>
          <TextField
              className={classes.widerField}
              label="Domicilio"
              multiline
              rowsMax={3}
              value={address.address}
              margin="dense"
              inputProps={
              { readOnly: readOnly, }
              }
              onChange={(e)=> {
              handleEdited(true);
              setAddress({...address, address: e.target.value});
              }}
          />
          </Grid>
      </Grid>
  )
}