import React, { useState, useContext, useEffect } from 'react';
import { GuardedRoute } from 'react-router-guards'
import UserContext from '../../contexts/userContext/userContext';
import AccessDenied from './accessDenied';

const ProtectedRoute = (props) => {

  const [auth, setAuth] = useState(undefined);
  const me = useContext(UserContext);

  const validate = (props, me) => {
    
    if (me.user.actual_branch> 0) {
      setAuth(!("permission" in props) || me?.user?.menu_permissions.indexOf(props.permission)>-1);
    }
    
  }

  useEffect(() => {
    validate(props, me);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])
  return(
    <>
    {
    ("permission" in props) ?
      auth === undefined ? "" : auth
        ?
        <GuardedRoute {...props} />
        :
        <AccessDenied />
    :
      <GuardedRoute {...props} />
    }
    </>
  );
};

export default ProtectedRoute;
