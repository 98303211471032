import React, {useState, useEffect, useContext} from 'react'
import genericDataService from '../../../../services/genericDataService.jsx'
import UserContext from '../../../../contexts/userContext/userContext.jsx';
import Loading from '../../../loading/loading';
import {TextInput, DateTimeInput, DateInput} from '../../../inputs/inputs.jsx'
import moment from 'moment';
import {  Typography } from '@material-ui/core';

import { 
  FormControlLabel, Button, Dialog, CircularProgress,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle, Checkbox, Collapse
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

const activityDataService = new genericDataService("/activities");

const ActivityReport = (props) => {

  /* Importo el user context para obtener el branch selected */
	const me = useContext(UserContext);

  const { onChange } = props;

  const [form, setForm] = useState({
        start: new Date(),
        end: new Date(),
        summary: "",
        lunch: false
  });
  const [loading, setLoading] = useState(false);
  const [activityReportForm, setActivityReportForm] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (me.user.actual_branch>0) {
      setForm({
        user_id: me.user.id,
        branch_id: me.user.actual_branch,
        activity_type_id: props.activity_type_id,
        start: new Date(),
        end: new Date(),
        summary: "",
        lunch: false
      })
    }
  }, [me, props])

  const sendActivity = () => {
    setLoading(true);

    /*
      Pusimos el moment, para convertir de Date a String, asi Axios no lo convierte a UTC
      TODO: Sacar moment de aca, y agregarselo al objeto apiClient en el interceptor de axios.
    */
    form.start = moment(form.start).format('YYYY-MM-DD HH:mm:ss');
    form.end = moment(form.end).format('YYYY-MM-DD HH:mm:ss');

    if(props.hideSummary) form.summary = 'Vacaciones';

    activityDataService.store(form)
    .then(res => {
      closeActivityReportForm();
      onChange();
      if (me.user.actual_branch>0) {
        setForm({
          ...form,
          start: new Date(),
          end: new Date(),
          summary: "",
          lunch: false,
        })
      }
      setErrors([]);
      return res.data;
    })
    .catch((error) =>  {
      setForm({
        ...form,
        summary: form.summary.trim() === "" ? "" : form.summary.trim(),
        lunch: false
      });
      setErrors(error.response?.data.errors);
      setLoading(false);
    });
  }

  const showActivityReportForm = () => {
    setLoading(false);
    setActivityReportForm(true);
  }

  const closeActivityReportForm = () => {
    setActivityReportForm(false);
    setLoading(false);
  }

	const onChangeInput = (event) => {
			setForm({...form, [event.target.name]:event.target.value});
	}

	//Modificar form pasando nombre y valor de parametros
	const handleChange = (name, value) => {
    setForm({...form, [name]:value});
	}

  const handleChangeLunch = (event) => {
    setForm({...form, lunch: event.target.checked});
	}

  const onKeyPressTextField = (event) => {
    if(event.key === 'Enter'){
      sendActivity();
    }
  }

  return (
   <>
    <Loading open={loading} />
    <Button  fullWidth  variant="contained" onClick={showActivityReportForm} color="primary" >Reportar</Button>
    <Dialog onClose={closeActivityReportForm} aria-labelledby="simple-dialog-title" open={activityReportForm}>
      <DialogTitle id="simplerge-dialog-title">{props.isVacationCategory ? props.extraInfo.title : "Reportar actividad"}
      {
        props.isVacationCategory ? 
        <Typography color="textSecondary" gutterBottom>Disponible: {props.extraInfo.vacation_amount} días {props.vacation_in_a_row> 0 ? "de corrido" : "hábiles"} </Typography>
        :
        ""
      }
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.isVacationCategory ? "" : "Ingrese la descripción de la actividad realizada"}</DialogContentText>
        {
          props.useDate === true ?
          <>
            <DateInput label="Inicio" name="start" handleChange={handleChange} canEdit={true} data={form} error={errors} />
            <DateInput label="Fin" name="end" handleChange={handleChange} canEdit={true} data={form} error={errors} />
          </> 
          : <>
            <DateTimeInput label="Inicio" name="start" handleChange={handleChange} canEdit={true} data={form} error={errors} />
            <DateTimeInput label="Fin" name="end" handleChange={handleChange} canEdit={true} data={form} error={errors} />
          </>
        }
        {props.hideSummary === false ?
        <TextInput
          label="Descripcion"
          multiline
          name="summary"
          type="text"
          handleChange={onChangeInput}
          onKeyPress={onKeyPressTextField}
          data={form}
          error={errors} /> :
          <></>
        }

        {props.hideTakeLunch === false ?
        <FormControlLabel
          style={{ padding: '25px 10px' }}
          control={
            <Checkbox
              checked={form.lunch}
              onChange={handleChangeLunch}
              name="checkedB"
              color="primary"
            />
          }
          label="Descuenta almuerzo"
        />
        : <></>}

      <Collapse in={form.lunch} timeout={{appear:50, enter: 300, exit: 100}}>
        <Alert variant="outlined" severity="warning">
        Al seleccionar la opción 'Descuenta almuerzo', se le descontará una hora de la actividad reportada.
      </Alert>
      </Collapse>
      </DialogContent>
      <DialogActions>
      {loading ? <CircularProgress size={25} disableShrink /> :<Button onClick={sendActivity} disabled={loading} color="primary" autoFocus>{props.isVacationCategory ? "Solicitar": "Finalizar"}</Button>}
      </DialogActions>
    </Dialog>
   </>
  )
}

// Especifica los valores por defecto de props:
ActivityReport.defaultProps = {
  takeLunch: true
};


export default ActivityReport;