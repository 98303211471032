import React, { useState, useEffect, useContext } from "react";
import { Button, List, ListItem, ListItemText, Grid, Box, Paper, Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText, Snackbar } from "@material-ui/core";
import genericDataService from "../../../services/genericDataService";
import Loading from "../../loading/loading";
import UserContext from "../../../contexts/userContext/userContext.jsx";
import { EditorState, Modifier } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import MUIEditor, { toHTML } from "react-mui-draft-wysiwyg";
import useStyles from './templates.css';
import Alert from "@material-ui/lab/Alert";

const templateService = new genericDataService("/mail_templates");

const Templates = () => {
  const classes = useStyles();
  const me = useContext(UserContext);
  const [variables, setVariables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templateSelectedId, setTemplateSelectedId] = useState('');
  const [templateSelected, setTemplateSelected] = useState({});
  const [editorState, setEditorState] =useState(() => EditorState.createEmpty(), );
  const [snackState, setSnackState] = useState({open: false, message:'', severity:'success'});

  useEffect(() => {
    if (me.user.actual_branch > 0) {
      setLoading(true);
      templateService
      .index()
      /* cambiar la repsuesta de la api o hacer mas elegante ese .data.data*/
      .then(res => res.data.data)
      .then(data => {
        setTemplates(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    }
    // eslint-disable-next-line array-callback-return
  }, [me]);
  
  useEffect(() => {
    if (templateSelectedId) {
      setLoading(true);
      templateService.show(templateSelectedId)
      .then(res => res.data)
      .then(data => {
        setTemplateSelected(data);
        let contentState = stateFromHTML(data.html_template);
        setEditorState(EditorState.createWithContent(contentState));
        setVariables(data.variables);
        setLoading(false);
      })
      .catch(err => setLoading(false))
    }
  }, [templateSelectedId]);
  
  const handleVariable = (text) => {
    let entity, entityKey;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    entity = contentState.createEntity('variable', 'IMMUTABLE', {time:  new Date().getTime()});
    entityKey = entity.getLastCreatedEntityKey();
    const textWithEntity = Modifier.insertText( contentState, selection, "{{ " + text + " }}", null, entityKey);
    setEditorState(EditorState.push(editorState, textWithEntity));
  };

  const saveTemplate = () => {
    const stateHtml = toHTML(editorState.getCurrentContent());
    let template = templateSelected;
    template.text_template=""; // si lo mando vacio, la api lo rellena con striptags(html_template).
    template.html_template = stateHtml;
    setLoading(true);
    templateService.update(template.id, template, 'post')
    .then(res => {
      setSnackState({open: true, message: 'Plantilla actualizada', severity: 'success'});
      setLoading(false);
    })
    .catch(err => {
      setSnackState({open: true, message: err.message, severity: 'error'});
      setLoading(false);
    })
  }

  const handleSnackClose = () => {
    setSnackState({open: false, message:'', severity:'success'});
  }

  return (
    <Box style={{ padding: 20 }}>
      <Loading open={loading} />
      <Grid container spacing={2} margin={1}>
        <Grid item xs={6}>
            <Typography variant="h4" gutterBottom component="div">Editor de plantillas de correo</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <InputLabel id="template-selector-label">Plantilla de correo</InputLabel>
            <Select 
              autoWidth={true} 
              labelId="template-selector-label"
              id="template-selector"
              value={templateSelectedId} 
              onChange={(event) => {setTemplateSelectedId(event.target.value) }}
            >
              {
                templates.map((template, index) => <MenuItem key={index} value={template.id}>{template.name}</MenuItem>)
              }
            </Select>
            <FormHelperText>Elija la plantilla que desea editar</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom component="div">Variables disponibles</Typography>
          <List component="nav" aria-label="secondary mailbox folders">
          {variables.map((variable, idx) => 
            <ListItem key={"v" + idx} button>
              <ListItemText primary={"{{ " + variable+ " }}"} onClick={() => handleVariable(variable)} />
            </ListItem>
          )}            
          </List>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper className={classes.paper} >
            <Typography variant="h6" gutterBottom component="div">Plantilla</Typography>
            <MUIEditor
              toolbar={{options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link']}}
              editorState={editorState}
              onChange={setEditorState}
            />
            {
              me?.user?.menu_permissions?.includes('update_templates') ? 
              <Button disabled={templateSelectedId === ''} onClick={saveTemplate}>Guardar cambios</Button> :
              "No tenes permisos para editar templates"
            }
            
          </Paper>
        </Grid>
      </Grid>
      <Snackbar autoHideDuration={3000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={snackState.open} onClose={handleSnackClose}>
        <Alert severity={snackState.severity}>{snackState.message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default Templates;
