import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import LicenceList from './licenceList/licenceList';


const Licences = () =>  {
  //Data = Id del usuario, es opcional
  return (
    <Grid container>
       <Grid xs={12} item component={Paper}>
        <LicenceList />
       </Grid>
    </Grid>
  );
}

export default Licences;