import React, { useContext, useState, useEffect } from 'react'
import { Grid, Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import UserContext from '../../../contexts/userContext/userContext.jsx';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';

/* importing columns a data service */
import columns, { title, localization, options } from './areas.def.jsx'
import genericDataService from '../../../services/genericDataService';
import mtablePerm from '../../../helpers/permissions/permissions.jsx';

const permissions = new mtablePerm("areas");

const areasDataService = new genericDataService("/areas");

const tableAreas = React.createRef();

const Areas = (props) => {

  const me = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(() => () => areasDataService.emptyTable());

  const [actions, setActions] = useState({});

  /* Refresca la tabla de areas luego de los siguiente eventos: mount, refresh, add, update */
  useEffect(() => {
    if (me.user.actual_branch > 0) {
      setActions(permissions.resolve(me?.user?.menu_permissions).get());
      setData(() => (query) => areasDataService.rows(query, me.user.company_id));
      tableAreas.current.onQueryChange();
      setLoading(false);
    }
  }, [me])

  const getRowAction = (rowData, handleRowAction) => {

    if(rowData.deleted_at == null) {
      return {
        icon: DeleteIcon,
        tooltip: 'Eliminar',
        onClick: (event, rowData) => {
          handleRowAction(rowData);
        }
      }
    }
    
    return {
      icon: RestoreIcon,
      tooltip: 'Activar',
      onClick: (event, rowData) => {
        handleRowAction(rowData);
      }
    }
  };

  const handleRowAction = (rowData) => {
    if (rowData.deleted_at === null) {
      areasDataService.rowDelete(rowData);
    } else {
      areasDataService.getApiClient().post(`areas/${rowData.id}/activate`);
    }
  };

  return (
    <Box style={{ padding: 20 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            title={title}
            tableRef={tableAreas}
            columns={columns}
            isLoading={loading}
            onLoading={loading}
            localization={localization}
            loadingType={"linear"} rount
            options={
              {
                ...options,
                exportCsv: (columns) => areasDataService.makeRequest(tableAreas, columns).exportCsv(),
                exportPdf: (columns) => areasDataService.makeRequest(tableAreas, columns).exportPdf()
              }
            }
            data={data}
            editable={{
              ...actions,
              onRowAdd: actions.onRowAdd ? newData => areasDataService.rowAdd(newData, me.user.company_id) : undefined,
              onRowUpdate: actions.onRowUpdate ? (newData, oldData) => areasDataService.rowUpdate(newData, oldData) : undefined,
            }}
            actions={[
              rowData => getRowAction(rowData, handleRowAction)
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Areas;