import React, { useEffect, useState, useContext } from 'react'
import { Grid, Chip, Box, makeStyles, Dialog, Slide } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import Switch from '@material-ui/core/Switch';
/* import EditIcon from '@material-ui/icons/Edit'; */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import genericDataService from '../../../services/genericDataService.jsx'
import UserContext from '../../../contexts/userContext/userContext.jsx';
import mtablePerm from '../../../helpers/permissions/permissions.jsx';
import ProfileEdit from '../profile/editProfile/userProfile.jsx';

import './users.css';

const permissions = new mtablePerm("users");

const usersDataService = new genericDataService("/users");

const localization = {
  header: {
    actions: 'Acciones'
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'líneas',
    firstTooltip: 'Primera',
    previousTooltip: 'Anterior',
    nextTooltip: 'Siguiente',
    lastTooltip: 'Última'
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
    exportTitle: "Exportar"
  }

}

const useStyles = makeStyles((theme) => ({
  absolute: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(3),
    zIndex: 10
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableRef = React.createRef();

const Users = (props) => {

  const classes = useStyles();

  const me = useContext(UserContext);

  /* flag de loading, al cambiar este valor renderiza el componente*/
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState({});
  const [open, setOpen] = useState(false);
  const [userPermissions, setUserPermissions] = useState({});
  const [user, setUser] = useState({});

  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    showDisabled: false
  });

  const handleSwitch = () => {
    setParams({
      ...params,
      showDisabled: !params.showDisabled,
      page: 0
    });
  };

  const handleClose = () => {
    refreshTable();
    setOpen(false);
  }

  const refreshTable = () => {
    setLoading(true);
    setData(() => (query) => {
      query.showDisabled = params.showDisabled ?? false;
      query.page = params.page;
      return usersDataService.rows(query, me.user.company_id).then(
        (value) => {
          return value;
        }
      )
    });
    tableRef.current.onQueryChange();
    setLoading(false);
  }

  const onChangeRowsPerPage = (rowsPerPage) => {
    setParams({...params, pageSize: rowsPerPage});
  }

  const onChangePage = (page) => {
    setParams({...params, page: page});
  }

  /* Uso el hook de estado para renderizar la tabla al cambiar el mismo*/
  const [data, setData] = useState(() => () => usersDataService.emptyTable());

  /* Refresca la tabla de holiday_types luego de los siguiente eventos: mount, refresh, add, update */
  useEffect(() => {
    if (me.user) {
      setActions(permissions.resolve(me?.user?.menu_permissions).get());
      const canCreate = me.user.menu_permissions.includes('create_users');
      const canEdit = me.user.menu_permissions.includes('update_users');
      setUserPermissions({
        cantCreate: !canCreate,
        cantEdit: !canEdit
      });
      refreshTable();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, params]);

  return (
    <Box style={{ padding: 20 }}>
      <Box margin={0.1}>
      <Dialog 
        scroll="body"
        onClose={handleClose}
        TransitionComponent={Transition}
        open={open}
      >
        <Box style={{ padding: 20, margin: 30 }}>
          <ProfileEdit  user={user} {...props} />
        </Box>
       </Dialog>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            title="Administración de Colaboradores"
            tableRef={tableRef}
            isLoading={loading}
            loadingType={"linear"}
            onLoading={loading}
            localization={localization}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            columns={[
              { title: 'Nombre', render: (rowData) => {
                let lastname = '';
                if (rowData.lastname != null) {
                  lastname = rowData.lastname;
                }
                let firstname = '';
                if (rowData.firstname != null) {
                  firstname = rowData.firstname;
                }
                let middlename = ''
                if (rowData.middlename != null) {
                  middlename = rowData.middlename;
                }
                return lastname + ", " + firstname + " " + middlename }
              },
              { title: 'Email', field: 'job_email' },
              { title: 'Modo de Contratación', sorting: false, field: 'contract_method.name' },
              {
                title: 'Funciones', sorting: false, render: (rowData) =>
                rowData.roles.map((role, i) => (
                  <Chip label={role.full_name} className={classes.chip} key={i} />
                ))
              },
              { title: 'Fecha de ingreso', field: 'admission_date' },
            ]}
            data={data}
            actions={[
              {
                icon: "add_box",
                tooltip: "Agregar Colaborador",
                isFreeAction: true,
                onClick: () => props.history.push("/admin/users/create"),
                hidden: userPermissions.cantCreate,
              },
              {
                icon: 'edit',
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setUser(rowData);
                  setOpen(true);
                },
                hidden: userPermissions.cantEdit,
              },
              rowData =>({
                  icon: () => <Switch checked={rowData.active}/>,
                  tooltip: 'Activar/Desactivar',
                  hidden: userPermissions.cantEdit,
                  onClick: () => {
                    const UserDataService = new genericDataService('/users');
                    UserDataService.update(rowData.id, {active: !rowData.active})
                        .then( res => refreshTable()).catch(e => {});
                  },
              }),
              ]}
            options={{
              editable: true,
              search: true,
              pageSize: params.pageSize,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 20, 50, 100],
              actionsColumnIndex: -1,
              exportButton: true,
              exportAllData: true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: rowData.active ? classes.root : '#FBB0B0'
              })
            }}
            components={{
              Toolbar: props => (
                <>
                  <MTableToolbar {...props} />
                  <FormControlLabel
                    checked={params.showDisabled ?? false}
                    value="start"
                    control={<Switch color="primary" />}
                    label="Mostrar Inactivos"
                    labelPlacement="start"
                    onChange={handleSwitch}
                  />
                </>
              ),
            }}
            editable={{actions}}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Users;