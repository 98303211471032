import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appName: {
      marginRight: 5,
    },
    appVersion: {
      flexGrow: 1,
    },
    link: {
      textDecoration: "none"
    },
    areaSelect: {
      color: 'white'
    },
    icon: {
      fill: 'white',
    },
    appBar: {
      opacity: 0.95,
    }
  }));

  export default useStyles;
