import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import columns, { localization, options } from './activityList.def.jsx';
import UserContext from '../../../../contexts/userContext/userContext.jsx';
import genericDataService from '../../../../services/genericDataService';
import ActivityStartEnd from '../activityStartEnd/activityStartEnd.jsx';
import ActivityReport from '../activityReport/activityReport.jsx';
import { Grid } from '@material-ui/core';

import Badge from '@material-ui/core/Badge';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AttachmentsDialog from '../../../attachmentsDialog/attachmentsDialog.jsx';

const tableActivities = React.createRef();

const ActivitiesDataService = new genericDataService("/activity_types/");

const categoryWorkId = 1; // lo hardcodeo q no tengo otra forma de determinarlo sin hacer llamas extras a la api"
const categoryVacationId = 3;

const ActivityList = () => {

	/* leo de la query string el parametro de tipo de actividad para usar en los listados */
	let { activity_type_id } = useParams();

	/* Importo el user context para obtener el branch selected */
	const me = useContext(UserContext);

	const [activity, setActivity] = useState(0)
	/* flag de loading, al cambiar este valor renderiza el componente*/
	const [loading, setLoading] = useState(false);

	const [activityType, setActivityType] = useState({});

	const [title, setTitle] = useState("");
	/* Uso el hook de estado para renderizar la tabla al cambiar el mismo*/
	const [data, setData] = useState(() => () => {
		return ActivitiesDataService.emptyTable()
	});

	const [started, setStarted] = useState(false);
	const [activityAttachmentsOpen, setActivityAttachmentsOpen] = useState(false);

	const refreshTable = () => {
		tableActivities.current.onQueryChange();
	}

	const handleActivityStartEndChange = (started) => {
		setStarted(started);
		refreshTable();
	}

	const handleActivityReportChange = () => {
		refreshTable();
	}

	useEffect(() => {
		if (me.user.actual_branch > 0) {
			ActivitiesDataService.changeResource("/activity_types/" + activity_type_id + "/activities")
			setData(() => (query) => ActivitiesDataService.rows(query, me.user.company_id));
			tableActivities.current.onQueryChange();
			setLoading(false);
		}
	}, [me, activity_type_id])

	/* traigo los activity types necesarios para obtener el titulo y verificar si hay una actividad iniciada*/
	useEffect(() => {
		if (me.user.actual_branch > 0) {
			ActivitiesDataService
				.lookup("/activity_types/all")
				.then((res) => res.data.map(activityTypeItem => {
					if (parseInt(activityTypeItem.id) === parseInt(activity_type_id)) {
						setTitle("Listado de " + activityTypeItem.name);
						setActivityType(activityTypeItem);
					}
					return false;
				}))
				.catch((err) => {

				});
		}
	}, [me, activity_type_id]);

	return (
		<>
			<AttachmentsDialog 
				activity={activity} 
				activityAttachmentsOpen={activityAttachmentsOpen}
				setActivityAttachmentsOpen={setActivityAttachmentsOpen} 
				refreshParent={refreshTable} 
			/>
			<Grid container spacing={2} style={{ position: "fixed", bottom: "10px", left: "20px", zIndex: 10 }}>
			{activityType?.id > 0 ? activityType.category_id !== categoryWorkId ?
				<Grid item>
					<ActivityReport
						hideSummary={activityType.category_id === categoryVacationId}
						hideTakeLunch={activityType.category_id === categoryVacationId}
						useDate={activityType.category_id === categoryVacationId}
						/* En la prop extra info para los dias de vacaciones disponibles en caso de solicitud de vacaciones */
						isVacationCategory={activityType.category_id === categoryVacationId}
						extraInfo = {{
							"title" : "Solicitud de vacaciones", 
							"vacation_amount" : me.user.vacation_amount,
							"vacation_in_a_row" : me.user.vacation_in_a_row
						}} 
						activity_type_id={activity_type_id} onChange={handleActivityReportChange}
					/>
				</Grid>
			:<>
				<Grid item><ActivityStartEnd activity_type_id={activity_type_id} onChange={handleActivityStartEndChange} started={started} /></Grid>
				<Grid item><ActivityReport hideSummary={false} hideTakeLunch={false} activity_type_id={activity_type_id} onChange={handleActivityReportChange} /></Grid>
			</>:""}
			</Grid>
			<MaterialTable
				tableRef={tableActivities}
				columns={columns}
				toolbar={true}
				title={title}
				data={data}
				isLoading={loading}
				loadingType={"linear"}
				localization={localization}
				options={
					{
						...options,
						exportCsv: (columns) => ActivitiesDataService.makeRequest(tableActivities, columns).exportCsv(),
						exportPdf: (columns) => ActivitiesDataService.makeRequest(tableActivities, columns).exportPdf()
					}
				}
				components={{
					Toolbar: props => <MTableToolbar {...props} />
				}}
				actions={[
					rowData => ({
						icon: () => <Badge badgeContent={rowData.comments.length} color="primary"><AttachmentIcon color="action" /></Badge>,
						tooltip: 'Gestor de archivos adjuntos',
						onClick: (event, rowData) => { setActivity(rowData); setActivityAttachmentsOpen(true); }
					})
				]}
			/>
			<br />
			<br />
			<br />
		</>
	);
}
export default ActivityList