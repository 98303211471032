
import React from 'react';
import { Grid, Paper, Typography, Box, Icon } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import Assignment from '@material-ui/icons/Assignment';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import decimalToHumanHours from '../../../../helpers/dateUtils/dateFormatter'

const FooterWithTotals = (props)  => {

    const {totals} = props;

return (
    <>
    <Grid container component={Paper} alignContent='center' alignItems='center'>
        <Grid item md={2}>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box m={3}>
            <Grid container spacing={1}>
              <Grid item >
                <Icon style={{ fontSize: 30 }}><WorkIcon style={{ fontSize: 30 }}></WorkIcon></Icon>
              </Grid>
              <Grid item >
                <Typography variant='h6' align='center'>
                  Trabajadas: {totals.worked !== "-1:00" ? decimalToHumanHours(totals.worked) : '00:00'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Box m={3}>
          <Grid container spacing={1} alignContent='center' alignItems='center'>
              <Grid item >
                <Icon style={{ fontSize: 30 }}><Assignment style={{ fontSize: 30 }}></Assignment></Icon>
              </Grid>
              <Grid item >
                <Typography variant='h6' align='center'>
                  Reportadas: {totals.reported !== -1 ? decimalToHumanHours(totals.reported) : '00:00'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
    </Grid>

    <Grid container
      component={Paper}
      alignContent='center'
      alignItems='center'
      style = {totals.reportedVsWorked !== -1 &&
        (totals.reportedVsWorked < 95 || totals.reportedVsWorked > 105)
            ? {backgroundColor: '#e17676', color: 'black'} : {}}
      >
    <Grid item md={4}>
    </Grid>
    <Grid item xs={12} md={8}>
      <Box m={3} >
        <Grid container spacing={1}>
          <Grid item >
            <Icon style={{ fontSize: 30 }}><EqualizerIcon style={{ fontSize: 30 }}></EqualizerIcon></Icon>
          </Grid>
          <Grid item >
            <Typography variant='h6' align='center'>
              Reportadas/Trabajadas: {totals.reportedVsWorked !== -1 ? totals.reportedVsWorked + ' %' : '0 %'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
    </Grid>
  </>
    );
}
export default FooterWithTotals