import React from "react";
import { Grid, Card, CardContent, Typography, Zoom, Box } from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import moment from "moment-timezone";
import decimalToHumanHours from '../../../helpers/dateUtils/dateFormatter';

const CardBalanceHours = (props) => {
  const { classes, zoomIn, velZoomIn, balanceHours } = props;
  return (
    <Zoom in={zoomIn} style={{ transitionDelay: zoomIn ? velZoomIn : "0ms" }}>
      <Grid item xs={12} sm={6} lg={3}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Saldo de horas
            </Typography>
            <Typography variant="h5" component="h2">
              {`${balanceHours != '0' ? balanceHours > 0 ? "+" : "-" : ''} ${decimalToHumanHours(Math.abs(balanceHours))}`}
            </Typography>
            <HourglassEmptyIcon
              style={{ fontSize: 50, paddingTop: 10, margin: 2 }}
            />
            <Box mt={2}>
              <Typography variant="subtitle1" component="h2">
                {`(al ${moment().subtract(1, "days").format("DD/MM/YYYY")})`}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Zoom>
  );
};

export default CardBalanceHours;
