import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core';
import genericDataService from '../../../services/genericDataService';
import { useHistory } from "react-router-dom";

import { title, columns, localization } from './settlementReport.def.jsx'
import MaterialTable, { MTableToolbar } from 'material-table';
import SalariesToolbar from './settlementReportToolbar.jsx';

const tableSalaries = React.createRef();
const salariesDataService = new genericDataService("/activities/settlement-report");

const SettlementReport = (props) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(() => () => salariesDataService.emptyTable());
	let dateFromInit = new Date();
	let dateToInit = new Date();
	dateFromInit.setMonth(dateFromInit.getMonth() - 1);
	dateFromInit.setDate(dateFromInit.getDate() - 1);

	dateToInit.setDate(dateToInit.getDate() - 1)
	const [filtersData, setFiltersData] = useState({
		dateFrom: dateFromInit,
		dateTo: dateToInit,
	});
	const [params, setParams] = useState({
		page: 0,
		pageSize: 10
	});

	let history = useHistory();

	const refreshTable = (filters) => {
		setData(() => (query) => salariesDataService.rows(query, filters).then((value) => {
			if (value.totalCount < params.pageSize) {
				setParams({ ...params, pageSize: value.totalCount })
			}
			return value;
		}));
		tableSalaries.current.onQueryChange();
		setLoading(false);
	}

	const onChangeRowsPerPage = (rowsPerPage) => {
		setParams({ ...params, pageSize: rowsPerPage });
	}

	const onRowClick = (e, row) => {
		history.push({
			pathname: '/admin/trace-activities',
			state: {
				user_id: row.user_id,
				dateFrom: filtersData.dateFrom,
				dateTo: filtersData.dateTo
			},
		});
	}

	const exportCsv = (columns, data) => {
		const filtersCopy = { ...filtersData };
	
		const formattedData = data.map(row => {
		  const formattedRow = {};
		  columns.forEach(column => {
			const renderResult = column.render ? column.render(row) : null;
			formattedRow[column.field] = renderResult && renderResult.display ? renderResult.display : row[column.field];
		  });
		  return formattedRow;
		});
	
		const filterRow = {
		  'Desde': filtersCopy.dateFrom.toLocaleDateString(),
		  'Hasta': filtersCopy.dateTo.toLocaleDateString(),
		};
	
		const csvData = [
		  Object.keys(filterRow),
		  Object.values(filterRow),
		  columns.map(column => {
			if (typeof column.title === 'string') {
			  return column.title;
			} else if (column.title.props && column.title.props.children) {
			  return column.title.props.children;
			} else {
			  return '';
			}
		  }),

		  ...formattedData.map(row => columns.map(column => row[column.field])),
		];
	
		const csvString = csvData.map(row => row.join(',')).join('\n');
	
		const blob = new Blob([csvString], { type: 'text/csv' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = 'Reporte liquidación de sueldos.csv';
		link.click();
	};

	return (
		<Box style={{ padding: 20 }}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<MaterialTable
						title={title}
						tableRef={tableSalaries}
						onChangeRowsPerPage={onChangeRowsPerPage}
						columns={columns}
						isLoading={loading}
						onLoading={loading}
						localization={localization}
						onRowClick={onRowClick}
						loadingType={"linear"}
						options={{
							editable: false,
							search: false,
							pageSizeOptions: [10, 20, 50, 100],
							emptyRowsWhenPaging: false,
							exportButton: true,
							exportAllData: true,
							sorting: false,
							actionsColumnIndex: -1,
							toolbarButtonAlignment: 'left',
							pageSize: params.pageSize,
							emptyRowsWhenPaging: false,
							initialPage: 0,
							filtering: false,
							exportCsv,
						}}
						data={data}
						components={{
							Toolbar: props => (
								<>
									<MTableToolbar {...props} />
									<SalariesToolbar
										onSearch={refreshTable}
										filtersData={filtersData}
										setFiltersData={setFiltersData}
									/>
								</>
							)
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}

export default SettlementReport;