import React, { useState, useEffect } from "react"
import Topbar from '../../topbar/topbar.jsx';
import Sidebar from '../../sidebar/sidebar.jsx';

/* Material core kit */
import { CssBaseline, Box } from '@material-ui/core';

const Layout = (props) => {

  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    let newValue = !showSidebar;
    setShowSidebar(newValue);
  }

  const [wallpaperCSS, setWallpaperCSS] = useState({});

  useEffect(() => {
    let localWallpaper = localStorage.getItem("wallpaper");
    if (localWallpaper) {
      setWallpaperCSS({
        backgroundImage: "url(" + localWallpaper + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh"
      });
    }
  }, []);

  return (
      <Box style={wallpaperCSS}>
      <CssBaseline />
      <Box height={64}></Box>
      <Topbar {...props} onTheme={props.onTheme} onToggleSidebar={toggleSidebar} />{props.children}
      <Sidebar open={showSidebar} onToggleSidebar={toggleSidebar} />
    </Box>
  )
}

export default Layout;