import React, { useState, useEffect } from "react";
import { Button, Grid, Box, Paper, Typography } from "@material-ui/core";
import genericDataService from "../../../services/genericDataService";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import List from "@material-ui/core/List";
import Loading from "../../loading/loading";
import ListItem from "@material-ui/core/ListItem";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: 70,
  },
}));

const ImportCsv = () => {
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (files.length) {
      const data = new FormData();
      // eslint-disable-next-line array-callback-return
      data.append("file", files[0], files[0].name);
      setLoading(true);
      new genericDataService("/activity-importer/import")
        .upload(data)
        .then((res) => res.data)
        .then((data) => {
          setActivities(data.activities);
          setLoading(false);
        })
        .catch((err) => {
          var messageErrors = ["Error: " + err.response.data.message];
          var objKeys = Object.keys(err.response.data.errors);
          objKeys.map((objKey) => {
            err.response.data.errors[objKey].map((error) => {
              let field = objKey.split(".").pop();
              messageErrors.push(
                field > -1
                  ? "CSV Line " + (parseInt(field) + 1) + ": " + error
                  : "Description: " + error
              );
            });
          });
          setErrors(messageErrors);
          setLoading(false);
        });
        setFiles([]);
    }

    // eslint-disable-next-line array-callback-return
  }, [files]);

  return (
    <Box style={{ padding: 20 }}>
      <Loading open={loading} />
      <Grid container spacing={2} margin={1}>
        <Grid item xs={9}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom component="div">
              Importador de actividades en formato CSV
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: "none" }}
                type="file"
                accept="text/csv"
                onChange={(event) => setFiles(event.target.files)}
              />
              <Button
                className="btn-choose"
                variant="outlined"
                component="span"
              >
                Subir CSV
              </Button>
            </label>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom component="div">
              {errors.length > 0 || activities.length > 0
                ? "Resultado de la importación"
                : "Seleccione un archivo para comenzar la importacion"}
            </Typography>
            <List>
              {errors.map((error, idx) => (
                <ListItem key={"error_" + idx} alignItems="flex-start">
                  <MuiAlert severity="error">{error}</MuiAlert>
                </ListItem>
              ))}
            </List>
            <List>
              {activities.map((activity, idx) => (
                <ListItem key={"success_" + idx} alignItems="flex-start">
                  <MuiAlert severity="success">
                    {activity.job_email +
                      ", " +
                      moment(activity.start).format("DD/MM/YY HH:mm") +
                      ", " +
                      moment(activity.end).format("DD/MM/YY HH:mm")}
                  </MuiAlert>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImportCsv;
