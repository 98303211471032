
import React, { useState } from 'react'
//card
import Typography from '@material-ui/core/Typography';

import { Accordion, AccordionSummary, AccordionDetails, Grid } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import PhoneFields from '../fields/phoneFields';
import apiClient from '../../../../../helpers/apiClient/apiClient';

const PhonesAccordion = (props) => {

const { setAccordionIndex, path, phones, accordionIndex, index, title, setAlert } = props;

  const [edited, setEdited] = useState(false);

  const handleSavePressed = () => {
    setEdited(true);
    //los telefonos que manda, son los que guarda
    apiClient.post(path, phones)
        .then( res => {
          setEdited(false);
          setAlert({
            severity: 'success',
            message: title + ' actualizado correctamente',
            open: true
          });
        })
        .catch(e => {
          setAlert({
            severity: 'error',
            message: e.message,
            open: true
          });
        });
  }

  return (
    <Accordion
      expanded={accordionIndex === index}
      onChange={(e,v)=>{
        setAccordionIndex(v ? index : -1)
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        IconButtonProps={{color:'secondary'}}
      >
        <Grid container>
          <Grid item xs={11}>
            <Typography variant='h5'>{title}</Typography>
          </Grid>
          { edited ?
            <Grid item xs={1}>
            <IconButton onClick={handleSavePressed} aria-label="save-accordion">
              <SaveIcon />
            </IconButton>
          </Grid>
          : <></>}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <PhoneFields
          {...props}
          setEdited={setEdited}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default PhonesAccordion;