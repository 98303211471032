import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Zoom,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";

const CardVacationAmount = (props) => {
  const { classes, zoomIn, velZoomIn, vacationAmount, vacationInARow } = props;
  return (
    <Zoom in={zoomIn} style={{ transitionDelay: zoomIn ? velZoomIn : "0ms" }}>
      <Grid item xs={12} sm={6} lg={3}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Saldo de vacaciones
            </Typography>
            <Typography variant="h5" component="h2">
              {`${vacationAmount ?? '0'} días ${
                vacationInARow === 1 || vacationAmount <= 0 ? "" : "(hábiles)"
              }`}
            </Typography>
            <DateRangeIcon
              style={{ fontSize: 50, paddingTop: 10, margin: 2 }}
            />
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                let vacationActivityTypeId =
                  props.me.user.available_activity_types.find(
                    (element) => element.name === "Vacaciones"
                  )?.activity_types[0]?.id;
                props.history.push("/activities/" + vacationActivityTypeId);
              }}
            >
              Solicitar
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Zoom>
  );
};

export default CardVacationAmount;
