import React, { useState, useEffect, useContext } from "react";
import genericDataService from "../../../../services/genericDataService.jsx";
import UserContext from "../../../../contexts/userContext/userContext.jsx";
import Loading from "../../../loading/loading";
import { TextField, Box } from "@material-ui/core";
import { TextInput, DateTimeInput } from "../../../inputs/inputs.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";

import {
  Button,
  Dialog,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import Alert from "../../../app/alert";

const licenceDataService = new genericDataService("/activities");

const LicenceRequest = (props) => {
  /* Importo el user context para obtener el branch selected */
  const me = useContext(UserContext);

  const { onChange } = props;

  const [form, setForm] = useState({
    start: new Date(),
    end: new Date(),
    summary: "",
    lunch: false,
    activity_type_id: 0,
  });
  const [loading, setLoading] = useState(false);
  const [licenceForm, setActivityReportForm] = useState(false);
  const [errors, setErrors] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);

  const [alert, setAlert] = useState({
    severity: "success",
    open: false,
    message: "",
  });

  const [activityType, setActivityType] = useState({});

  useEffect(() => {
    if (me.user.actual_branch > 0) {
      setForm({
        user_id: me.user.id,
        branch_id: me.user.actual_branch,
        start: new Date(),
        end: new Date(),
        summary: "",
        lunch: false,
      });

      setActivityTypes(me.user.available_activity_types.filter((category) => category.name === 'Licencias')[0]?.activity_types);
    }
  }, [me, props]);

  const sendLicence = () => {
    setLoading(true);

    form.start = moment(form.start).format("YYYY-MM-DD HH:mm:ss");
    form.end = moment(form.end).format("YYYY-MM-DD HH:mm:ss");
    form.activity_type_id = activityType.id;

    licenceDataService
      .store(form)
      .then((res) => {
        setAlert({
          severity: "success",
          message: "Licencia reportada correctamente",
          open: true,
        });
        closeLicenceForm();
        onChange();
        if (me.user.actual_branch > 0) {
          setForm({
            ...form,
            start: new Date(),
            end: new Date(),
            summary: "",
            lunch: false,
          });
        }
        setErrors([]);
        return res.data;
      })
      .catch((error) => {
        setForm({
          ...form,
          summary: form.summary.trim() === "" ? "" : form.summary.trim(),
          lunch: false,
        });
        setErrors(error.response?.data.errors);
        setLoading(false);
      });
  };

  const showLicenceForm = () => {
    setLoading(false);
    setActivityReportForm(true);
  };

  const closeLicenceForm = () => {
    setActivityReportForm(false);
    setLoading(false);
  };

  const onChangeInput = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  //Modificar form pasando nombre y valor de parametros
  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const onKeyPressTextField = (event) => {
    if (event.key === "Enter") {
      sendLicence();
    }
  };

  return (
    <>
      <Alert
        onClose={() => setAlert({ ...alert, open: false })}
        alert={alert}
        setAlert={setAlert}
      />
      <Loading open={loading} />
      <Button
        fullWidth
        variant="contained"
        onClick={showLicenceForm}
        color="primary"
      >
        Reportar
      </Button>
      <Dialog
        onClose={closeLicenceForm}
        aria-labelledby="simple-dialog-title"
        open={licenceForm}
      >
        <DialogTitle id="simplerge-dialog-title">Reportar licencia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.isVacationCategory
              ? ""
              : "Ingrese la descripción de la actividad realizada"}
          </DialogContentText>

          <>
            <DateTimeInput
              label="Inicio"
              name="start"
              handleChange={handleChange}
              canEdit={true}
              data={form}
              error={errors}
            />
            <DateTimeInput
              label="Fin"
              name="end"
              handleChange={handleChange}
              canEdit={true}
              data={form}
              error={errors}
            />
            <Box display="inline">
            <Autocomplete
              id="activityTypeId"
              autoComplete
              disableClearable
              style={{width: '92%'}}
              autoHighlight
              value={activityType}
              onChange={(e, value) => {
                setActivityType(value);
                setForm({ ...form, activity_type_id: value.id });
              }}
              inputValue={activityType?.name}
              getOptionLabel={(option) => {
                if (option && option.name) return option.name;
                else return "";
              }}
              options={activityTypes ?? []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ margin: 8 }}
                  label="Tipo de licencia"
                  margin="normal"
                />
              )}
            />
            </Box>
            <TextInput
              label="Descripción"
              multiline
              style={{width: '92%'}}
              name="summary"
              type="text"
              handleChange={onChangeInput}
              onKeyPress={onKeyPressTextField}
              data={form}
              error={errors}
            />
          </>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress size={25} disableShrink />
          ) : (
            <Button
              onClick={sendLicence}
              disabled={loading}
              color="primary"
              autoFocus
            >
              Solicitar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LicenceRequest;
