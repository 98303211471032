import React, { useState, useEffect } from "react";
//card
import Typography from "@material-ui/core/Typography";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SaveIcon from "@material-ui/icons/Save";
import genericDataService from "../../../../../services/genericDataService.jsx";
import IconButton from "@material-ui/core/IconButton";
import PersonalFields from "../fields/personalFields.jsx";

const PersonalAccordion = (props) => {
  const {
    setAccordionIndex,
    identityTypes,
    accordionIndex,
    loading,
    user,
    index,
    setAlert,
    errors,
    setErrors,
  } = props;

  const [edited, setEdited] = useState(false);
  const [personal, setPersonal] = useState({});
  const [identityType, setIdentityType] = useState({});

  useEffect(() => {
    if (identityTypes) {
      const userIdentityType = identityTypes.find(
        (element) => element.id === user.identity_type_id
      );
      setIdentityType(userIdentityType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityTypes]);

  useEffect(() => {
    setPersonal({
      firstname: user.firstname,
      middlename: user.middlename,
      lastname: user.lastname,
      identity_type_id: user.identity_type_id,
      identity_number: user.identity_number,
      birthdate: user.birthdate,
      personal_email: user.personal_email,
      notification_channel_id: user.notification_channel_id,
      active: user.active ? 1 : 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSavePressed = () => {
    const UserDataService = new genericDataService("/users");
    UserDataService.update(user.id, personal)
      .then((res) => {
        setAlert({
          severity: "success",
          message: "Información personal actualizada correctamente",
          open: true,
        });
        setEdited(false);
      })
      .catch((error) => {
        setErrors(error.response?.data.errors);
        setAlert({
          severity: "error",
          message: "Error al actualizar la información personal",
          open: true,
          list: error.response?.data.errors ?? [],
        });
      });
  };

  return (
    <Accordion
      defaultExpanded
      expanded={accordionIndex === index}
      onChange={(e, v) => {
        setAccordionIndex(v ? index : -1);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        IconButtonProps={{ color: "secondary" }}
      >
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h5">Información personal</Typography>
          </Grid>
          {edited ? (
            <Grid item xs={1}>
              <IconButton onClick={handleSavePressed} aria-label="save-accordion">
                <SaveIcon />
              </IconButton>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <PersonalFields
          {...props}
          errors={errors}
          personal={personal}
          setPersonal={setPersonal}
          loading={loading}
          setEdited={setEdited}
          edited={edited}
          identityType={identityType}
          setIdentityType={setIdentityType}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default PersonalAccordion;
