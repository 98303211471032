import React, { useState, useEffect } from 'react'
//card
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, IconButton } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import genericDataService from '../../../../../services/genericDataService.jsx';
import OutOfOfficeFields from '../fields/outOfOfficeFields.jsx';

const OutOfOfficeAccordion = (props) => {

  const { setAccordionIndex, accordionIndex, index, setAlert, user, errors, setErrors } = props;
  const [outOfOffice, setOutOfOffice] = useState({});
  const [edited, setEdited] = useState(false);


  const handleSavePressed = () => {
    const dataService = new genericDataService('users');
    let params = {...outOfOffice}
    dataService.update(user.id, params)
        .then( res => {
          setEdited(false);
          setAlert({
            severity: 'success',
            message: 'Preferencias guardadas',
            open: true
          });
        })
        .catch(e => {
          setErrors(e.response?.data.errors);
          setAlert({
            severity: 'error',
            open: true,
            list: e.response?.data.errors ?? [],
          });
        });
  }

  useEffect(() => {
    setOutOfOffice({
        ...outOfOffice,
        vacation_notify: user.vacation_notify,
        vacation_email: user.vacation_email,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
        <Accordion
            defaultExpanded
            expanded={accordionIndex === index}
            onChange={(e,v)=>{
              setAccordionIndex(v ? index : -1)
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              IconButtonProps={{color:'secondary'}}
            >
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant='h5'>Modo fuera de oficina</Typography>
                </Grid>
                { edited ?
                  <Grid item xs={1}>
                  <IconButton onClick={handleSavePressed} aria-label="save-accordion">
                    <SaveIcon />
                  </IconButton>
                </Grid>
                : <></>}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <OutOfOfficeFields
                {...props}
                outOfOffice={outOfOffice}
                setOutOfOffice={setOutOfOffice}
                setEdited={setEdited}
                edited={edited}
                error={errors}
             />
            </AccordionDetails>
          </Accordion>
    )
}

export default OutOfOfficeAccordion;

