import React, { useState } from "react";
import { Grid, Fab, Icon } from "@material-ui/core";
import Box from "@material-ui/core/Box";

//Date filters
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//Dropdown filters
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Button search
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import VoteActivitiesForm from "./VoteActivitiesForm";

const VoteActivitiesToolbar = (props) => {
  const {
    isAdminRRHH,
    onSearch,
    filtersData,
    setFiltersData,
    users,
    areaChanged,
    userChanged,
    activityTypeChanged,
    categoryChanged,
    categories,
    areas,
    statuses,
    activityTypes,
    userSelected,
    categorySelected,
    areaSelected,
    statusSelected,
    activityTypeSelected,
    setUserSelected,
    setCategorySelected,
    setAreaSelected,
    setActivityTypeSelected,
    setStatusSelected,
    openFilter,
    setOpenFilter,
    dropdownUserAppear,
    massiveApprove,
  } = props;

  const [isDateValid, setIsDateValid] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const searchButtonClicked = () => {
    if (!isDateValid) {
      const today = new Date();

      if (!filtersData.start && !filtersData.end) {
        setFiltersData({
          ...filtersData,
          start: today,
          end: today,
        });
      } else if (!filtersData.end) {
        setFiltersData({
          ...filtersData,
          end: today,
        });
      } else if (!filtersData.start) {
        const dateFromyesterday = new Date(filtersData.end);
        dateFromyesterday.setDate(dateFromyesterday.getDate() - 1);
        setFiltersData({
          ...filtersData,
          start: dateFromyesterday,
        });
      }
    }

    onSearch(filtersData);
  };

  const clearFiltersButtonClicked = () => {
    setUserSelected(null);
    setCategorySelected(null);
    setStatusSelected(null);
    setAreaSelected(null);
    setActivityTypeSelected(null);
    setFiltersData({});
    areaChanged(null);
    activityTypeChanged(null);
    categoryChanged(null);
  };

  const handleDateFromChange = (date) => {
    if (!isNaN(date)) {
      setFiltersData({
        ...filtersData,
        start: date,
      });
      setIsDateValid(true);
    }
  };

  const handleDateToChange = (date) => {
    if (!isNaN(date)) {
      setFiltersData({
        ...filtersData,
        end: date,
      });
      setIsDateValid(true);
    }
  };

  const onAutocompleteChange = (event, value) => {
    setFiltersData({
      ...filtersData,
      user_id: value ? [value.id] : [],
    });
    setUserSelected(value);
    userChanged(value);
  };

  const onCategoryChange = (event, value) => {
    setFiltersData({
      ...filtersData,
      type_category_id: value ? [value.id] : [],
    });
    setCategorySelected(value);
    categoryChanged(value);
  };

  const onAreaChange = (event, value) => {
    setFiltersData({
      ...filtersData,
      type_area_id: value ? [value.id] : [],
    });

    areaChanged(value);
    setAreaSelected(value);
  };

  const handleOpenForm = (isOpen) => {
    setOpenForm(isOpen);
  };

  const handleOpenFormClick = () => {
    setOpenForm(true);
  };

  const onActivityTypeChange = (event, value) => {
    setFiltersData({
      ...filtersData,
      type_id: value ? [value.id] : [],
    });
    activityTypeChanged(value);
    setActivityTypeSelected(value);
  };

  const onStatusChange = (event, value) => {
    setFiltersData({
      ...filtersData,
      status_id: value ? [value.id] : [],
    });
    setStatusSelected(value);
  };

  const handleChange = () => {
    setOpenFilter((prev) => !prev);
  };

  return (
    <>
      <Box mb={1}>
        <Grid container alignItems="center" alignContent="center">
          {dropdownUserAppear ? (
            <Grid item xs={12} sm={6} md={3}>
              <Box ml={10} width={5 / 8}>
                <Autocomplete
                  id="user-picker"
                  autoComplete
                  onChange={onAutocompleteChange}
                  options={users}
                  getOptionLabel={(option) =>
                    option ? option.lastname + " " + option.firstname : ""
                  }
                  value={userSelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Usuario"
                      placeholder="Usuario"
                      margin="normal"
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={props.activeUserFlag}
                      onChange={(e) =>
                        props.setActiveUserFlag(e.target.checked)
                      }
                    />
                  }
                  label="Solo activos"
                  classes={props.classes}
                />
              </Box>
            </Grid>
          ) : (
            <></>
          )}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Grid item xs={12} sm={6} md={dropdownUserAppear ? 3 : 4}>
              <Box ml={10} width={5 / 8}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-from"
                  label="Desde"
                  format="dd/MM/yyyy"
                  cancelLabel="Cancelar"
                  value={filtersData.start}
                  autoOk={true}
                  onChange={handleDateFromChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={dropdownUserAppear ? 3 : 4}>
              <Box ml={10} width={5 / 8}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-from"
                  label="Hasta"
                  format="dd/MM/yyyy"
                  cancelLabel="Cancelar"
                  showTodayButton
                  autoOk={true}
                  todayLabel="Hoy"
                  value={filtersData.end}
                  onChange={handleDateToChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Box>
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid
            item
            xs={12}
            sm={dropdownUserAppear ? 6 : 12}
            md={dropdownUserAppear ? 3 : 4}
          >
            <Grid container>
              <Grid item xs={5}>
                <Box ml={1} mt={4} alignItems="center" justifyContent="center">
                  <Grid container>
                    <Grid item xs={8}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={openFilter}
                            onChange={handleChange}
                          />
                        }
                        label="Más filtros"
                        labelPlacement={"start"}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box ml={6}>
                        <Fab
                          variant="extended"
                          size="medium"
                          color="secondary"
                          onClick={clearFiltersButtonClicked}
                        >
                          <Icon>
                            <ClearIcon />
                          </Icon>
                        </Fab>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box ml={8} mt={4}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    onClick={searchButtonClicked}
                  >
                    <Icon>
                      <SearchIcon />
                    </Icon>
                  </Fab>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box ml={8} mt={4}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="secundary"
                    onClick={handleOpenFormClick}
                  >
                    <Icon>
                      <ThumbUpIcon />
                    </Icon>
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}></Grid>
        </Grid>
      </Box>
      <Collapse
        in={openFilter}
        timeout={{ enter: 400, appear: 100, exit: 1000 }}
      >
        <Box mb={1}>
          <Grid container alignItems="center" alignContent="center">
            <Grid item xs={12} sm={6} md={3}>
              <Box ml={10} width={5 / 8}>
                <Autocomplete
                  autoComplete
                  onChange={onCategoryChange}
                  options={categories}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  value={categorySelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categoria"
                      placeholder="Categoria"
                      margin="normal"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box ml={10} width={5 / 8}>
                <Autocomplete
                  autoComplete
                  onChange={onAreaChange}
                  options={areas}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  value={areaSelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Área"
                      placeholder="Área"
                      margin="normal"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box ml={10} width={5 / 8}>
                <Autocomplete
                  autoComplete
                  onChange={onActivityTypeChange}
                  options={activityTypes}
                  getOptionLabel={(option) => (option ? option.full_name : "")}
                  value={activityTypeSelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de actividad"
                      placeholder="Tipo de actividad"
                      margin="normal"
                    />
                  )}
                />
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
              <Box ml={10} width={5 / 8}>
                <Autocomplete
                  autoComplete
                  onChange={onStatusChange}
                  options={statuses}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  value={statusSelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Estado"
                      placeholder="Tipo de actividad"
                      margin="normal"
                    />
                  )}
                />
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </Collapse>
      <VoteActivitiesForm 
        openForm={openForm} 
        handleOpenForm={handleOpenForm}
        massiveApprove={massiveApprove}
        filtersData={filtersData}
      />
    </>
  );
};

export default VoteActivitiesToolbar;
