import React, { useContext, useState, useEffect } from 'react'
import { Grid, Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import UserContext from '../../../contexts/userContext/userContext.jsx';

/* importing columns a data service */
import columns, { title, localization, options } from './activity_type_validators.def.jsx'
import withFiltersDataService from '../../../services/withFiltersDataService.jsx';
import mtablePerm from '../../../helpers/permissions/permissions.jsx';

const permissions = new mtablePerm("activity_validators");

const activityTypeValidatorsDataService = new withFiltersDataService("/activity_type_validators");

const tableActivityTypeValidators = React.createRef();

const ACTIVITY_TYPE_FIELD_ID = 0;
const AREA_FIELD_ID = 1;

const ActivityTypeValidators = (props) => {

  const me = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(() => () => activityTypeValidatorsDataService.emptyTable());

  const [actions, setActions] = useState({});


  /* traigo los activity types necesarios para el abm */
  useEffect(() => {
    activityTypeValidatorsDataService
      .lookup("/activity_types/all")
      .then((res) => res.data.map(activityTypeItem => columns[ACTIVITY_TYPE_FIELD_ID].lookup[activityTypeItem.id] = activityTypeItem.full_name)
      );
  }, []);

  
  /* traigo las necesarios para el abm */
  useEffect(() => {
    activityTypeValidatorsDataService
      .lookup("/areas/all")
      .then((res) => res.data.map(areaItem => columns[AREA_FIELD_ID].lookup[areaItem.id] = areaItem.name)
      );
  }, []);


  /* Refresca la tabla de activity type validators luego de los siguiente eventos: mount, refresh, add, update */
  useEffect(() => {
    if (me.user.actual_branch > 0) {
      setActions(permissions.resolve(me?.user?.menu_permissions).get());
      setData(() => (query) => activityTypeValidatorsDataService.rows(query, me.user.company_id));
      tableActivityTypeValidators.current.onQueryChange();
      setLoading(false);
    }
  }, [me])

  return (
    <Box style={{ padding: 20 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            title={title}
            tableRef={tableActivityTypeValidators}
            columns={columns}
            isLoading={loading}
            onLoading={loading}
            localization={localization}
            loadingType={"linear"} rount
            options={
              {
                ...options,
                exportCsv: (columns) => activityTypeValidatorsDataService.makeRequest(tableActivityTypeValidators, columns).exportCsv(),
                exportPdf: (columns) => activityTypeValidatorsDataService.makeRequest(tableActivityTypeValidators, columns).exportPdf()
              }
            }
            data={data}
            editable={{
              ...actions,
              onRowAdd: actions.onRowAdd ? newData => activityTypeValidatorsDataService.rowAdd(newData, me.user.company_id) : undefined,
              onRowUpdate: actions.onRowUpdate ? (newData, oldData) => activityTypeValidatorsDataService.rowUpdate(newData, oldData) : undefined,
              onRowDelete: actions.isDeletable ? oldData => activityTypeValidatorsDataService.rowDelete(oldData) : undefined
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ActivityTypeValidators;