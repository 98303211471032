
const AUTH_ONLY = 'AUTH_ONLY';

const isTokenValid = () => {
  var token = localStorage.getItem('token');
  var token_expiration_date = localStorage.getItem('token_expiration_date');

  /* no existe el token o el expiration date en el local storage */
  if (token === null || token_expiration_date === null || token === "") {
    return false;
  }

  /* TODO: buscar la razon por la cual el local storage almacena el campo token como string con cadena undefined */
  if (token === "undefined" || token_expiration_date === "undefined") {
    return false
  }

  /* si existe token pero esta vencido */
  if (new Date(token_expiration_date) <  new Date()) {
    return false;
  }
  return true;
}

const middlewareAuth = async (to, from, next) => {
  if (to.meta[AUTH_ONLY] && !isTokenValid()) {
      next.redirect('/login');
  } else {
    next();
  }
};

export default middlewareAuth;
export { isTokenValid };