import React, { useState, useEffect, useContext } from 'react'
import genericDataService from '../../../../services/genericDataService.jsx'
import UserContext from '../../../../contexts/userContext/userContext.jsx';
import Loading from '../../../loading/loading';
import Alert from '@material-ui/lab/Alert';
import { 
  Link, Button, Dialog, TextField,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle, Fade, CircularProgress,
  Checkbox, Collapse, FormControlLabel
} from '@material-ui/core'

const activitiesAvailableDataservice = new genericDataService("/activity_types/available");

const activityDataService = new genericDataService("/activities");

const activityLastSummary = new genericDataService("/activity_types/");

const ActivityStartEnd = (props) => {

  /* Importo el user context para obtener el branch selected */
  const me = useContext(UserContext);

  const { onChange, started } = props;

  const activity_type_id = props.activity_type_id;

  const [loading, setLoading] = useState(false);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [summaryText, setSummaryText] = useState("");
  const [fadeIn, setFadeIn] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [canTakesLunch, setCanTakeLunch] = useState(true);
  const [errors, setErrors] = useState([]);

  const timeoutFadeIn = 500;

  const getLunchStatus = () => {
    activityLastSummary.changeResource("/activities/lunch/").index()
      .then((res) => res.data)
      .then(data => {
        setCanTakeLunch(data.status);
        // setLunch(data.status ? true : false);
        setLunch(false);
      })
      .catch(() => {
      });
  }

  useEffect(() => {
    if (activity_type_id > 0) {
      activitiesAvailableDataservice.index()
      .then((res) => res.data)
      .then(data => queryActivityInProgress())
      .catch((err) => {
        onChange(false);
        setLoading(false);
      });

      activityLastSummary.changeResource("/activity_types/" + activity_type_id + "/last-summary").index()
        .then((res) => res.data)
        .then(data => {
          setFadeIn(true);
          setSummaryText(data.summary ? data.summary : "");
          setLoading(false);
        })
        .catch(() => {
          onChange(false);
          setLoading(false);
        });

      getLunchStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity_type_id, props.showTimeStarted]);

  const startActivity = () => {
    setLoading(true);
    let params = {
      activity_type_id: activity_type_id,
      branch_id: me.user.actual_branch
    };
    activityDataService.update("start", params, "post")
      .then(res => res.data)
      .then(data => queryActivityInProgress());
  }

  const endActivity = () => {
    if (summaryText.trim() !== '') {
      let params = {
        activity_type_id: activity_type_id,
        branch_id: me.user.actual_branch,
        summary: summaryText,
        lunch: lunch ? 1 : 0
      };
      setLoading(true);
      activityDataService.update(started.id + "/end", params, "put")
        .then(res => res.data)
        .then(data => {
          closeModalHandler();
          queryActivityInProgress();
          getLunchStatus();
        })
        .catch((error) =>  {
          setSummaryText("");
          setLoading(false);
          setErrors(error.response.data.errors);
        });;
    } else {
      setLoading(false);
      setErrors({"summary" : ["La descripcion de la actividad es obligatoria"]});
    }
  }

  const queryActivityInProgress = () => {
    activitiesAvailableDataservice.index()
      .then((res) => res.data)
      .then(data => {
        onChange(data.started);
        setLoading(false);
      })
      .catch(() => {
        onChange(false);
        setLoading(false);
      });
  }

  const handleSummaryText = (event) => {
    setSummaryText(event.target.value);
  }
  const openModalHandler = () => {
    setLoading(false);
    setOpenSummaryModal(true);
  }

  const closeModalHandler = () => {
    setOpenSummaryModal(false);
  }

  const onKeyPressTextField = (event) => {
    if(event.key === 'Enter'){
      endActivity();
    }
  }

  const handleChangeLunch = (event,a) => {
      setLunch(event.target.checked);
	}

  return (
    <>
      <Loading open={loading} />
      {
        started === false ?
          <Button fullWidth variant="contained" color="primary" disabled>
            <CircularProgress size={25} />
          </Button> :
          started === null
            ?
            <Fade in={fadeIn} timeout={timeoutFadeIn}>
              <Button fullWidth variant="contained" onClick={startActivity} color="primary">Iniciar</Button>
            </Fade>
            :
            (parseInt(activity_type_id) === parseInt(started.activity_type_id))
              ?
              <Fade in={fadeIn} timeout={timeoutFadeIn}>
                <Button fullWidth variant="contained" onClick={openModalHandler} color="primary" >Finalizar</Button>
              </Fade>
              :
              <Fade in={fadeIn} timeout={timeoutFadeIn}>
                <Link to={'/activities/' + started.activity_type_id}><Alert severity="error"> {started.type.name + " en curso (" + started.type.area.name + ")"} </Alert></Link>
              </Fade>
      }

      <Dialog onClose={closeModalHandler} aria-labelledby="simple-dialog-title" open={openSummaryModal}>
        <DialogTitle id="simple-dialog-title">Cerrar actividad en curso</DialogTitle>
        <DialogContent>
          <DialogContentText>Ingrese la descripción de la actividad realizada</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="summary"
            value={summaryText}
            label="Descripcion de la actividad"
            onKeyPress={onKeyPressTextField}
            type="text"
            helperText={("summary" in errors) ? errors["summary"] : ""}
            error={"summary" in errors}
            fullWidth
            onChange={handleSummaryText}
          />

        <FormControlLabel
          control={
            <Checkbox
              checked={lunch}
              disabled={canTakesLunch == null ? true : false}
              onChange={handleChangeLunch}
              name="checkedB"
              color="primary"
              />
          }
          label="Descuenta almuerzo"
         />

          <Collapse in={lunch} timeout={{appear:50, enter: 300, exit: 100}}>
            <Alert style={{ width: '400px' }} variant="outlined" severity="warning">
              Al seleccionar la opción 'Descuenta almuerzo', se le descontará una hora de la actividad.
            </Alert>
          </Collapse>

        </DialogContent>
        <DialogActions>
           {loading ? <CircularProgress size={25} disableShrink /> : <Button onClick={endActivity} disabled={loading} color="primary" autoFocus>Finalizar</Button>}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ActivityStartEnd;