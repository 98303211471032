import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from '../protectedRoute/protectedRoute.jsx';
import { GuardProvider} from 'react-router-guards';
import { ThemeProvider } from '@material-ui/core/styles';
import getThemeSelected, { lightTheme, darkTheme } from '../../helpers/theme/theme.jsx'

/* carga el contexto del usuario para proveerlo en este componente y ser consumidos por el resto. */
import UserContext  from '../../contexts/userContext/userContext.jsx'

/* page components */
import  Layout from  '../pages/layout/layout.jsx';
import  Dashboard from  '../pages/dashboard/dashboard.jsx';
import  Activities from  '../pages/activities/activities.jsx';
import  TraceActivities from  '../pages/activities/actvititiesTracerList.jsx';
import  TraceActivitiesTickets from  '../pages/activities/activitiesTracerTicketsList.jsx';

import Users from '../pages/users/users.jsx';
import Areas from  '../pages/areas/areas.jsx';
import PermissionRoles from  '../pages/roles/roles.jsx';
import Branches from '../pages/branches/branches.jsx';

import Holidays from  '../pages/holidays/holidays.jsx';
import HolidayTypes from  '../pages/holiday_types/holiday_types.jsx';
import ActivityTypes from  '../pages/activity_types/activity_types.jsx';
import ActivityTypeValidators from  '../pages/activity_type_validators/activity_type_validators.jsx';

import ViewHolidays from  '../pages/view_holidays/view_holidays.jsx';

import ChangePassword from '../pages/profile/changePassword/changePassword';
import Login from '../pages/login/login.jsx';
import Logout from '../pages/logout/logout.jsx';
import ProfileEdit from '../pages/profile/editProfile/userProfile';
import Password from  '../pages/password/password.jsx';
import ActivityVote from  '../pages/activityVote/activityVote';
import SettlementReport from '../pages/salaries/settlementReport.jsx';
import ImportCsv from '../pages/importCsv/importCsv.jsx';
import VoteActivities from '../pages/activities/votePending/voteActivities.jsx';

/* helper components */
import Loading  from '../loading/loading.jsx'
import NotFound, {NotFoundPage}  from '../pages/notfound/notfound.jsx';

/* helpers modules */
import middlewareAuth, { isTokenValid }   from '../../helpers/requireLogin/requireLogin.jsx'

/* private CSS */
import './app.css';
import { fetchUserMe } from '../../helpers/apiClient/apiClient.jsx';
import CreateUser from '../pages/profile/createUser.jsx';
import Licences from '../pages/activities/licences.jsx';
import Templates from '../pages/templates/templates.jsx';
import Wallpapers from '../pages/wallpapers/wallpapers.jsx'


/* App component */
const App = (props) => {

  const [state, setState] =  useState({
    loggedin: false,
    loading: false,
    topTitle: props.title,
    user: { actual_branch: 0, actual_area :0} ,
    darkMode: false
  });

  const setActualBranch = (event) => {
    setState({ ...state, user: { ...state.user, actual_branch: event.target.value } })
  }


  const setActualArea = (event) => {
    localStorage.setItem("areaId", event.target.value);
    setState({ ...state, user: { ...state.user, actual_area: event.target.value } })
    setLoggedIn();
  }

  const [theme, setTheme] = useState(getThemeSelected());

  const toggleTheme = (switchValue) => {
    localStorage.setItem("darkMode", switchValue);
    setTheme(switchValue ? 'dark': 'light');
  }

  /* Establece la propiedad de conectado / no conectado, según el parametro booleano recibido */
  const setLoggedIn = () => {
    setState({...state, loading: true});
    fetchUserMe(localStorage.getItem("token"))
    .then((res) => res.data)
    .then((userData) => {
      if ("branches" in userData && userData.branches.length === 1) {
        userData.actual_branch = userData.branches[userData.branches.length - 1].id
      }
      let xArea = localStorage.getItem("areaId");
      if (xArea == null) {
        localStorage.setItem("areaId", userData.actual_area.id);
        setState({ ...state, user: { ...state.user, actual_area: userData.actual_area.id } })
      }
      
      let wallpaper = userData.prefs.filter(x => x.name === "background-image")?.shift()?.pivot?.value;
        
      if (wallpaper) {
        localStorage.setItem("wallpaper", wallpaper);
      }

      setState({
        ...state,
        loggedin: true,
        loading: false,
        user: { ...state.user, ...userData}
      });

    })
    .catch((err) => {
      setLoggedOut();
    });
  };

  const setLoggedOut = () => {
    setState({...state, user:{ actual_branch: 0, actual_area: null}, loggedin: false, loading:false});
  }

  /* Se establece como efecto secundario de la carga de la app, una llamada /users/me y la asignacion del modo visual almacenado. */
  useEffect(() => {
    toggleTheme(getThemeSelected());
    if (isTokenValid()) {
      setLoggedIn();
    } else {
      setLoggedOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Funcion para mostrar / no mostar la visualizacion del componente Loading (Fondo deshabilitado e icono de "cargando...") */
  const setLoading = (value) => {
    let newState = {...state, loading: value};
    setState(newState);
  };

  /* Component */
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <BrowserRouter>
        <GuardProvider guards={[middlewareAuth]} error={NotFound} loading={Loading}>
          {/* 
            Render del componente loading, estado inicial = false.
            Es usada como efecto de transicion a la actualizacion de datos via requests a las apis
          */}
          <Loading open={state.loading} {...props} />
          {/*
            GuardRoute es un derivado del componente clasico Route y permite insertar un middleware de autenticacion
            a las rutas que lo requieran
          */}
          <UserContext.Provider value={{user: state.user, updateActualBranch: setActualBranch, updateActualArea: setActualArea}}>
            <Switch>

              { /* TODO: Estas rutas deben armarse dinamicamente leyendo el archivo menu.jsx */ }
              <ProtectedRoute exact={true} path="/" meta={{"AUTH_ONLY": true}} 
                render={(props) => <Layout onTheme={toggleTheme}><Dashboard {...props} /></Layout>} />

              <ProtectedRoute exact={true} path="/dashboard"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><Dashboard {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="read_users" path="/admin/users"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><Users  {...props} /></Layout>} />

              <ProtectedRoute exact={true} path="/auth/first-login"
                render={(props) => <Password onLoggedIn={setLoggedIn} loggedin={state.loggedin} onLoggedOut={setLoggedOut} onLoading={setLoading} {...props}/>} />

              <ProtectedRoute exact={true} path="/vote"
                render={(props) => <ActivityVote {...props}/>} />

              <ProtectedRoute exact={true} permission="create_users" path="/admin/users/create"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><CreateUser  {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="read_areas" path="/admin/areas"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><Areas  {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="read_branches" path="/admin/branches"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><Branches  {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="read_holidays" path="/admin/holidays"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><Holidays  {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="read_activity_types" path="/admin/activity_types"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><ActivityTypes  {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="read_activity_validators" path="/admin/activity_type_validators"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><ActivityTypeValidators  {...props} /></Layout>} />

              <ProtectedRoute exact={true} path="/holidays/coming"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><ViewHolidays  {...props} /></Layout>} />

              <ProtectedRoute exact={true} strict={true} path="/activities/:activity_type_id"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><Activities {...props} /></Layout>} />

              <ProtectedRoute exact={true} strict={true} path="/admin/trace-activities"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><TraceActivities {...props} /></Layout>} />

              <ProtectedRoute exact={true} strict={true} path="/admin/trace-activities-tickets" meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><TraceActivitiesTickets {...props} /></Layout>} />

              <ProtectedRoute exact={true} strict={true} path="/admin/salaries" permission="read_settlement_report"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><SettlementReport {...props} /></Layout>} />

              <ProtectedRoute exact={true} path="/profile"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><ProfileEdit  {...props} /></Layout>} />

              <ProtectedRoute exact={true} path="/wallpapers"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><Wallpapers  {...props}/></Layout>} />

              <ProtectedRoute exact={true} path="/profile/change-password"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><ChangePassword  {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="read_holidays_types" path="/admin/holiday_types"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><HolidayTypes  {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="read_roles" path="/admin/roles"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><PermissionRoles  {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="import_activities" path="/admin/import-csv"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><ImportCsv  {...props} /></Layout>} />

              <ProtectedRoute exact={true} permission="('local_approver' || 'global_approver')" path="/admin/vote-activities"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><VoteActivities  {...props} /></Layout>} />

              <ProtectedRoute exact={true} path="/licences"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><Licences  {...props} /></Layout>} />

              <ProtectedRoute exact={true} path="/admin/templates"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><Templates  {...props} /></Layout>} />

              <ProtectedRoute exact={true} path="/error"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><NotFound  {...props} /></Layout>} />

              <ProtectedRoute exact={true} path="/login"
                render={(props) => (state.loggedin) ? <Redirect to="" /> : <Login loggedin={state.loggedin} onLoggedIn={setLoggedIn} onLoading={setLoading} {...props} />} />

              <ProtectedRoute exact={true} path="/logout"
                render={(props) => <Logout loggedin={state.loggedin} onLoggedOut={setLoggedOut} onLoading={setLoading} {...props}/>} />

              <ProtectedRoute exact={true} path="*"  meta={{"AUTH_ONLY": true}}
                render={(props) => <Layout onTheme={toggleTheme}><NotFoundPage  {...props} /></Layout>} />
            </Switch>
          </UserContext.Provider>
        </GuardProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
