import React, { useState, useContext ,useEffect } from 'react';
import MaterialTable, {MTableToolbar} from 'material-table';
import columns, { localization, options, title } from './activityTracerTickets.def.jsx';
import genericDataService from '../../../../services/genericDataService';
import TraceActivitiesTicketsToolbar from './activityTracerTicketsToolbar';
import FooterWithTotals from './activityTracerTicketsFooter';
import UserContext from '../../../../contexts/userContext/userContext.jsx';
import moment from 'moment-timezone';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

const tableActivitiesTickets = React.createRef();

const TraceActivitiesTicketsDataService = new genericDataService("/activities/tracer-with-reported");
const UserDataService = new genericDataService("/users/all");

const ActivityListTraceAdmin = (props)  => {

	/* flag de loading, al cambiar este valor renderiza el componente*/
	const [loading, setLoading] = useState(false);

  const [userHasPermissions, setUserHasPermissions] = useState(null);

  const me = useContext(UserContext);

  const READ_ALL_REPORTED_VS_WORKED_PERMISSION = 'read_reported_vs_worked';

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const [totals, setTotals] = useState({
    worked: "-1:00",
    reported: -1,
    reportedVsWorked: -1,
    irregularDays: -1
  });

	/* Uso el hook de estado para renderizar la tabla al cambiar el mismo*/
	const [data, setData] = useState(() => () => {
		return TraceActivitiesTicketsDataService.emptyTable()
	});

  const [filtersData, setFiltersData] = useState({
    dateFrom: new Date(),
    dateTo: new Date(),
    user: ""
  });
  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [activeUserFlag, setActiveUserFlag] = useState(true);

  useEffect(() => {
    if (me.user.actual_branch) {
      let permission = me.user.menu_permissions.includes(READ_ALL_REPORTED_VS_WORKED_PERMISSION);
      setUserHasPermissions(permission);
      if (!permission) {
        setFiltersData({
          ...filtersData,
          user: [me.user.id]});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  /* traigo todos los users para el select del filtro */
  useEffect(() => {
    if (userHasPermissions) {
      UserDataService.index()
      .then( (res) => {
        setUsers(res.data.filter((user) => user.active === activeUserFlag));})
      .catch((err) => {});
    }
  }, [userHasPermissions, activeUserFlag]);

  const refreshTable = (filters) => {
    setData(() => (query) => {
      return TraceActivitiesTicketsDataService.rows(query, filters).then(
        (value) => {

          setTotals({
            worked: value.totalWorked,
            reported: value.totalReported,
            reportedVsWorked: value.reportedVsWorked,
            irregularDays: value.irregularDays,
          });
          return value;
        }
      )
    } );
    tableActivitiesTickets.current.onQueryChange();
    setLoading(false);
  }

  const onChangeRowsPerPage = (rowsPerPage) => {
    setPageSize(rowsPerPage);
  }

	const exportCsv = (columns, data) => {
		const filtersCopy = { ...filtersData };

    const exportFileName =
    userSelected?.lastname +
    ' ' +
    userSelected?.firstname +
    " - Reporte de liquidación de sueldos | " +
    moment(filtersCopy.dateFrom).format('DD-MM-YYYY') +
    ' - ' +
    moment(filtersCopy.dateTo).format('DD-MM-YYYY');

		const formattedData = data.map(row => {
		  const formattedRow = {};
		  columns.forEach(column => {
			const renderResult = column.render ? column.render(row) : null;
			formattedRow[column.field] = renderResult && renderResult.display ? renderResult.display : row[column.field];
		  });
		  return formattedRow;
		});
	
		const csvData = [
		  columns.map(column => {
        if (typeof column.title === 'string') {
          return column.title;
        } else if (column.title.props && column.title.props.children) {
          return column.title.props?.children?.props?.children;
        } else {
          return '';
        }
		  }),

		  ...formattedData.map(row => columns.map(column => row[column.field])),
		];
	
		const csvString = csvData.map(row => row.join(',')).join('\n');
	
		const blob = new Blob([csvString], { type: 'text/csv' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = exportFileName + '.csv';
		link.click();
	};

  const exportPdf = (columns, data) => {
    const filtersCopy = { ...filtersData };
  
    const exportFileName =
      userSelected?.lastname +
      ' ' +
      userSelected?.firstname +
      " - Reporte de liquidación de sueldos | " +
      moment(filtersCopy.dateFrom).format('DD-MM-YYYY') +
      ' - ' +
      moment(filtersCopy.dateTo).format('DD-MM-YYYY');
  
    const formattedData = data.map(row => {
      const formattedRow = {};
      columns.forEach(column => {
        const renderResult = column.render ? column.render(row) : null;
        formattedRow[column.field] = renderResult && renderResult.display ? renderResult.display : row[column.field];
      });
      return formattedRow;
    });
  
    const pdfContent = {
      content: [
        {
          table: {
            headerRows: 1,
            widths: columns.map(() => 'auto'),
            body: [
              columns.map(column => {
                if (typeof column.title === 'string') {
                  return { text: column.title, style: 'tableHeader' };
                } else if (column.title.props && column.title.props.children) {
                  return { text: column.title.props?.children?.props?.children, style: 'tableHeader' };
                } else {
                  return '';
                }
              }),
              ...formattedData.map(row => columns.map(column => String(row[column.field]))),
            ],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          color: '#3498db', // Color celeste
          alignment: 'center',
        },
        tableHeader: {
          fillColor: '#3498db', // Color celeste
          color: '#ffffff', // Texto blanco
          bold: true,
          fontSize: 12,
          alignment: 'center',
        },
      },
    };
  
    pdfMake.createPdf(pdfContent).download(exportFileName + '.pdf');
  };

  return (
    <>
    <MaterialTable
        tableRef={tableActivitiesTickets}
        columns={columns}
        toolbar={true}
        title={title}
        data={data}
        isLoading={loading}
        loadingType={"linear"}
        localization={localization}
        onChangeRowsPerPage={onChangeRowsPerPage}
        options={
            {
                ...options,
                pageSize: pageSize,
                emptyRowsWhenPaging: false,
                exportFileName: userSelected?.lastname +' '+ userSelected?.firstname + " - Reporte de horas | " + moment(filtersData.dateFrom).format("DD-MM-YYYY") + " - " + moment(filtersData.dateTo).format("DD-MM-YYYY"),
                exportCsv,
                exportPdf
                // exportCsv: (columns) => TraceActivitiesTicketsDataService.makeRequest(tableActivitiesTickets, columns).exportCsv(),
                // exportPdf: (columns) => TraceActivitiesTicketsDataService.makeRequest(tableActivitiesTickets, columns).exportPdf()
            }
        }
        components={{
            Toolbar: props => (
              <>
                <MTableToolbar {...props} />
                <TraceActivitiesTicketsToolbar
                  users={users}
                  onSearch={refreshTable}
                  filtersData={filtersData}
                  userSelected={userSelected}
                  setUserSelected={setUserSelected}
                  setFiltersData={setFiltersData}
                  dropdownUserAppear={userHasPermissions}
                  activeUserFlag={activeUserFlag}
                  setActiveUserFlag={setActiveUserFlag}
                />
              </>
            )
          }}
          />
    <FooterWithTotals totals={totals}/>
    </>
        );
}
export default ActivityListTraceAdmin