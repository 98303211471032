
import React, { useState, useContext } from 'react';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, Container, Box, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import UserContext from '../../../../contexts/userContext/userContext.jsx';
import genericDataService from '../../../../services/genericDataService';
import { useHistory } from "react-router-dom";
import Loading from '../../../loading/loading';
import Alert from "../../../app/alert"


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const ChangePassword = (props) =>  {
  const classes = useStyles();

  const [dataChangePassword, setDataChangePassword] = useState({
    old_password: "",
    password: "",
    password_confirmation: ""
  });
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [zoomOut, setZoomOut] = useState(true);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, severity: 'error', });

  const me = useContext(UserContext);
  const UserDataService = new genericDataService("/users");

  const history = useHistory();

  const submit = (e) => {
    /* deshabilito el submit del boton */
    e.preventDefault();
    if(dataChangePassword.password && dataChangePassword.password_confirmation){
      if(!passwordUpdated){
        setLoading(true);
        UserDataService.update(me.user.id, dataChangePassword)
            .then( res => {
              setPasswordUpdated(true);
              setLoading(false);
            }).catch(e => {
              let msgError = "Error desconocido";
              if (Object.keys(e.response.data.errors)[0]) {
                msgError = e.response.data.errors[Object.keys(e.response.data.errors)[0]];
              }
              setAlert({
                message: msgError,
                open: true,
                severity: 'error'
              });
              setLoading(false);
            });
      } else {
        setZoomOut(false);
        setTimeout(function() {
          history.push('/dashboard');
        }, 350)
      }
    } else {
      if(!dataChangePassword.password){
        setAlert({
          ...alert,
          message: "Ingrese una nueva contraseña",
          open: true
        })
      } else {
        setAlert({
          ...alert,
          message: "Ingrese de nuevo la nueva contraseña.",
          open: true
        })
      }
    }

  }

  /* onchange callback for inputs*/
  const changeTextField = (e) => {
    setDataChangePassword({
      ...dataChangePassword,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
    <Loading open={loading} />
    <Zoom in={zoomOut} style={{ transitionDelay: zoomOut ? '100ms' : '0ms' }}>
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        { !passwordUpdated ? 
        <Typography component="h1" variant="h5">
          Cambiar contraseña
        </Typography> :
        <Grid container direction='column' alignContent='center' alignItems='center'>
          <Grid item>
            <Box my={7}>
            <Typography component="h1" variant="h5" align='center'>
              Contraseña actualizada con éxito
            </Typography>
            </Box>
          </Grid>
        </Grid>
        }
        { !passwordUpdated ?
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="old_password"
                variant="outlined"
                required
                fullWidth
                id="old_password"
                label="Contraseña actual"
                type="password"
                autoComplete="current-password"
                autoFocus
                InputLabelProps={{color:'secondary'}}
                onChange={changeTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="password"
                label="Nueva contraseña"
                type="password"
                name="password"
                InputLabelProps={{color:'secondary'}}
                onChange={changeTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password_confirmation"
                label="Repetir contraseña"
                InputLabelProps={{color:'secondary'}}
                type="password"
                id="password_confirmation"
                onChange={changeTextField}
              />
            </Grid>
          </Grid>
          <Alert
            onClose={()=>setAlert({...alert, open:false}) }
            setAlert={setAlert}
            alert={alert}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={submit}
            color="primary"
            className={classes.submit}
          >
            Aceptar
          </Button>
        </form>
        : 
        <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={submit}
            color="primary"
            className={classes.submit}
          >
            Ir al dashboard
          </Button>
        }
      </Box>
    </Container>
  </Zoom>
  </>
);
}

export default ChangePassword;