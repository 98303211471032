import React from "react";
//card
import Typography from "@material-ui/core/Typography";
import {
  Tooltip,
  Avatar,
  FormControlLabel,
  Box,
  Grid,
  TextField,
  Button,
  Paper,
  Switch,
  InputAdornment
} from "@material-ui/core";
import Gravatar from "react-gravatar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment-timezone";

export default function PersonalFields(props) {
  const {
    user,
    classes,
    readOnly,
    me,
    personal,
    loading,
    setPersonal,
    creating,
    setEdited,
    identityTypes,
    identityType,
    setIdentityType,
  } = props;

  const history = useHistory();

  const handleActiveChange = (e, value) => {
    setPersonal({ ...personal, active: value ? 1 : 0 });
    handleEdited(true);
  };

  const handleEdited = (value) => {
    if (!creating) setEdited(value);
  };

  return (
    <Box marginX={2}>
      <Grid container alignItems="center" alignContent="center">
        <Grid item sm={12} lg={3}>
          <Box mt={2} display="flex" justifyContent="center">
            <Paper className={classes.paperStyleImage} elevation={8}>
              <Tooltip
                disableHoverListener={creating}
                disableFocusListener={creating}
                title={
                  <Typography variant="subtitle2">
                    Puede cambiar el avatar en gravatar.com
                  </Typography>
                }
              >
                <Box>
                  <Avatar
                    onClick={() => {
                      if (!creating)
                        window
                          .open("http://es.gravatar.com/", "_blank")
                          .focus();
                    }}
                    style={{ width: 200, height: 200 }}
                  >
                    <Gravatar
                      size={200}
                      rating="pg"
                      default={"mp"}
                      email={user.job_email ?? ""}
                    />
                  </Avatar>
                </Box>
              </Tooltip>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} lg={9}>
          {getFields(props)}
        </Grid>
      </Grid>
    </Box>
  );

  function getFields(props) {
    return (
      <Box mt={2}>
        <Paper className={classes.paperStyleFields} elevation={8}>
          <Box m={2}>
            <Grid container alignItems="center" alignContent="center">
              {/* Primera fila */}
              <Grid item xs={12} sm={4} mr={1}>
                <TextField
                  className={classes.margin}
                  id="input-with-icon-grid"
                  label="Nombre"
                  inputProps={{ readOnly: readOnly }}
                  value={personal.firstname}
                  helperText={
                    "firstname" in props?.errors
                      ? props?.errors["firstname"]
                      : ""
                  }
                  error={"firstname" in props?.errors}
                  onChange={(e) => {
                    handleEdited(true);
                    setPersonal({ ...personal, firstname: e.target.value });
                  }}
                  margin="dense"
                />
              </Grid>

              <Grid item xs={12} sm={4} mr={1}>
                <TextField
                  onChange={(e) => {
                    handleEdited(true);
                    setPersonal({ ...personal, middlename: e.target.value });
                  }}
                  className={classes.margin}
                  value={personal.middlename}
                  inputProps={{ readOnly: readOnly }}
                  label="Segundo nombre"
                  helperText={
                    "middlename" in props?.errors
                      ? props?.errors["middlename"]
                      : ""
                  }
                  error={"middlename" in props?.errors}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={4} mr={1}>
                <TextField
                  onChange={(e) => {
                    handleEdited(true);
                    setPersonal({ ...personal, lastname: e.target.value });
                  }}
                  className={classes.margin}
                  value={personal.lastname}
                  inputProps={{ readOnly: readOnly }}
                  label="Apellido"
                  helperText={
                    "lastname" in props?.errors ? props?.errors["lastname"] : ""
                  }
                  error={"lastname" in props?.errors}
                  margin="dense"
                />
              </Grid>

              {/* Segunda fila */}
              <Grid item xs={12} sm={4} mr={3}>
                <Box className={classes.selectSizeIdentityType}>
                  <Autocomplete
                    id="identityType"
                    autoComplete
                    loading={loading}
                    autoHighlight
                    disableClearable
                    getOptionSelected={(option, value) =>
                      value.value === option.value
                    }
                    value={identityType}
                    onChange={(e, value) => {
                      handleEdited(true);
                      setPersonal({
                        ...personal,
                        identity_type_id: value ? value.id : null,
                      });
                      setIdentityType(value);
                    }}
                    inputValue={identityType?.name}
                    getOptionLabel={(option) => {
                      if (option && option.name) return option.name;
                      else return "";
                    }}
                    options={identityTypes ?? []}
                    disabled={readOnly}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.margin}
                        label="Documento tipo"
                        helperText={
                          "identity_type_id" in props?.errors
                            ? props?.errors["identity_type_id"]
                            : ""
                        }
                        error={"identity_type_id" in props?.errors}
                        margin="dense"
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} mr={1}>
                <TextField
                  className={classes.margin}
                  value={personal.identity_number}
                  onChange={(e) => {
                    handleEdited(true);
                    setPersonal({
                      ...personal,
                      identity_number: e.target.value,
                    });
                  }}
                  inputProps={{ readOnly: readOnly }}
                  label="Número"
                  helperText={
                    "identity_number" in props?.errors
                      ? props?.errors["identity_number"]
                      : ""
                  }
                  error={"identity_number" in props?.errors}
                  margin="dense"
                />
              </Grid>

              <Grid item xs={12} sm={4} mr={1}>
                <Box
                  maxWidth={"199px"}
                  style={{
                    minHeight: "40px",
                    marginLeft: "6px",
                    marginTop: "6px",
                  }}
                >
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={esLocale}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="normal"
                      openTo="year"
                      disableFuture
                      disabled={readOnly}
                      label="Fecha de nacimiento"
                      maxDateMessage="La fecha no debe ser posterior a la actual"
                      value={moment(personal.birthdate) ?? null}
                      autoOk
                      helperText={
                        "birthdate" in props?.errors
                          ? props?.errors["birthdate"]
                          : ""
                      }
                      error={"birthdate" in props?.errors}
                      onChange={(value) => {
                        handleEdited(true);
                        setPersonal({
                          ...personal,
                          birthdate: moment(value).format("YYYY/MM/DD 00:00:00"),
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} mr={1}>
                <TextField
                  className={classes.margin}
                  label="Email"
                  value={personal.personal_email}
                  margin="dense"
                  inputProps={{ readOnly: readOnly }}
                  onChange={(e) => {
                    handleEdited(true);
                    setPersonal({
                      ...personal,
                      personal_email: e.target.value,
                    });
                  }}
                  helperText={
                    "personal_email" in props?.errors
                      ? props?.errors["personal_email"]
                      : "Email personal"
                  }
                  error={"personal_email" in props?.errors}
                />
              </Grid>

              {!creating ? (
                <Grid item xs={12} sm={4} mr={1}>
                  <TextField
                    className={classes.margin}
                    label="Notificaciones"
                    value={personal.notification_channel_id}
                    margin="dense"
                    inputProps={{ readOnly: readOnly }}
                    onChange={(e) => {
                      handleEdited(true);
                      setPersonal({
                        ...personal,
                        notification_channel_id: e.target.value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AlternateEmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    //TODO: get notify channel name and id
                    helperText={
                      "notification_channel_id" in props?.errors
                        ? props?.errors["notification_channel_id"]
                        : `Usuario de para recibir notificaciones`
                    }
                    error={"notification_channel_id" in props?.errors}
                  />
                </Grid>
              ) : (
                <></>
              )}

              {!creating ? (
                <Grid item xs={12} sm={4} mr={1}>
                  <Box marginTop={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={readOnly}
                          checked={personal.active === 1}
                          onChange={handleActiveChange}
                        />
                      }
                      label="Activo"
                      labelPlacement="start"
                    />
                  </Box>
                </Grid>
              ) : (
                <></>
              )}

              {!creating && me?.user.id === user?.id ? (
                <Grid item xs={12} sm={4} mr={1}>
                  <Box marginTop={2} marginLeft={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => {
                        history.push("/profile/change-password");
                      }}
                    >
                      Cambiar contraseña
                    </Button>
                  </Box>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
    );
  }
}
