import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Zoom,
  Button,
  Box,
} from "@material-ui/core";

const CardNextHoliday = (props) => {
  const { classes, zoomIn, velZoomIn, holiday } = props;

  return (
    <Zoom in={zoomIn} style={{ transitionDelay: zoomIn ? velZoomIn : "0ms" }}>
      <Grid item xs={12} sm={6} lg={3}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Próximo feriado
            </Typography>
            <Typography variant="h5" component="h2">
              {holiday.date_from_string}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {holiday.type.name}
            </Typography>
            <Typography variant="body2" component="p">
              {holiday.description}
            </Typography>
          </CardContent>
          <CardActions>
            <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.history.push("/holidays/coming")}
            >
              Ver todos
            </Button>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    </Zoom>
  );
};

export default CardNextHoliday;
