import React from 'react'
//card
import { TextField, FormControlLabel, Switch, Box, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export default function OutOfOfficeFields(props) {
  const { classes, outOfOffice, setOutOfOffice, setEdited, errors } = props;

  const handleEdited = (value) => {
    setEdited(value);
  }

  const handleNotifyChange = (e, value) => {
    if(value) {
      setOutOfOffice({...outOfOffice, vacation_notify: 0, vacation_email: ""});
    } else {
      setOutOfOffice({...outOfOffice, vacation_notify: 1});
    }
    handleEdited(true);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Alert variant="outlined" severity="info">
          Mientras esté de licencia / vacaciones:
        </Alert>
      </Grid>
      <Grid item xs={12} sm={4} mr={1}>
        <Box marginTop={3}>
          <FormControlLabel
            control={<Switch checked={!outOfOffice.vacation_notify} onChange={handleNotifyChange} />}
            label="No recibir notificaciones"
            labelPlacement="start" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          onChange={(e) => {
            setOutOfOffice({...outOfOffice, vacation_email: e.target.value});
            handleEdited(true);
          }}
          className={classes.margin}
          variant="outlined"
          value={outOfOffice.vacation_email ?? ''}
          label="Email de notificaciones"
          helperText={
            "vacation_email" in errors
              ? errors["vacation_email"]
              : "Redirigir mis notificaciones a esta dirección"
          }
          error={"vacation_email" in errors}
          margin="dense"
          disabled={!(outOfOffice.vacation_notify)}
          inputProps={
            { readOnly: !(outOfOffice.vacation_notify) }
          }
        />
      </Grid>
  </Grid>
  )
}