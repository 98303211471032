import React from 'react'
import { CssBaseline,  Avatar, Box, Typography, Container, Link } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import imageLogo from "../../resources/images/logo.png";
import useStyles from "../pages/login/login.css";

const AccessDenied = () => {

  /* import custom classes */
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <img src={imageLogo} alt='Netlabs' />
        <Avatar className={classes.avatarClose}><LockOutlinedIcon /></Avatar>
        <Typography component="h1" variant="h5">Acceso restringido</Typography>
        <Typography variant="body1" color="error" align="center"><br /><br />Permiso denegado<br />Si creés que nos equivocamos <Link variant="body2" color="inherit" target='_blank' href='mailto:desarrollo@netlabs.com.ar'>contactate con nosotros</Link></Typography>
      </Box>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link variant="body2" color="inherit" target='_blank' href="https://www.netlabs.com.ar">Netlabs</Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  )
}

export default AccessDenied;