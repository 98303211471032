import materialTableLocalization from "../../../../helpers/localization/materialTableLocalization.jsx";
import moment from 'moment-timezone';

import React from 'react';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import decimalToHumanHours from '../../../../helpers/dateUtils/dateFormatter';

// import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@mui/material/IconButton';
import Badge from "@material-ui/core/Badge";
import AttachmentIcon from "@material-ui/icons/Attachment";
import PageViewIcon from "@material-ui/icons/FindInPage";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

/* Material table title */
const title = "Aprobación de actividades";

/* Material table columns defs */
const getColumnDefinitions = (setActivity, setActivityDetailOpen, setActivityAttachmentsOpen) => [
  {
    title: "Usuario",
    field: "user_id",
    editable: "never",
    width: "15%",
    lookup: {},
    render: (rowData) =>
      [
        rowData.user.lastname,
        rowData.user.firstname,
        rowData.user.middlename,
      ].join(" "),
  },
  {
    title: "Inicio",
    field: "start",
    render: (rowData) => moment(rowData.start).format("DD/MM/YY HH:mm"),
    type: "date",
    editable: "never",
    width: "10%",
    filterPlaceholder: "Desde",
  },
  {
    title: "Fin",
    field: "end",
    render: (rowData) =>
      rowData.end === null
        ? "En curso"
        : moment(rowData.end).format("DD/MM/YY HH:mm"),
    type: "date",
    width: "10%",
    filterPlaceholder: "Hasta",
  },
  {
    title: 'Duración',
    editable: 'never',
    sortable: false,
    width: "10%",
    render: (rowData) => {
      return decimalToHumanHours(rowData.duration / 60);
    },
    align: 'center'
  },
  {
    title: "Categoría",
    field: "type.category.id",
    editable: "never",
    width: "10%",
    lookup: {},
    render: (rowData) => rowData.type.category.name,
  },
  {
    title: "Área",
    field: "type.area.id",
    editable: "never",
    width: "15%",
    lookup: {},
    render: (rowData) => rowData.type.area.name,
  },
  {
    title: "Tipo de actividad",
    field: "type.id",
    editable: "never",
    width: "25%",
    lookup: {},
    render: (rowData) => rowData.type.name,
  },
  {
    title: "Estado",
    field: "status_id",
    editable: "never",
    width: "10%",
    lookup: {},
    render: (rowData) => rowData.status_name,
  },
  {
    title: "",
    hidden: false,
    field: "lunch",
    render: (rowData) => {
      return rowData.lunch === 1 ? (
        <LocalDiningIcon color="secondary" fontSize="large" />
      ) : (
        ""
      );
    },
    type: "numeric",
    align: "center",
    editable: "never",
    export: false,
    filtering: false,
    filterPlaceholder: "Hasta",
  },
  {
    title: "Acciones",
    field: "actions",
    editable: "never",
    render: (rowData) => (
      <>
        <Tooltip title="Detalle de actividad">
          <Button onClick={() => {
            // Llama a la función que maneja la apertura del detalle de la actividad
            setActivity(rowData);
            setActivityDetailOpen(true);
          }}>
            <Badge
              badgeContent={
                rowData.comments.filter((comment) => comment.vote !== null)?.length
              }
              color="primary"
            >
              <PageViewIcon color="action" />
            </Badge>
          </Button>
        </Tooltip>
        <Tooltip title="Gestor de archivos adjuntos">
          <Button onClick={() => {
            // Llama a la función que maneja la apertura del gestor de archivos adjuntos
            setActivity(rowData);
            setActivityAttachmentsOpen(true);
          }}>
            <Badge
              badgeContent={
                rowData.comments.filter((comment) => comment.attachment != null)?.length
              }
              color="primary"
            >
              <AttachmentIcon color="action" />
            </Badge>
          </Button>
        </Tooltip>
      </>
    ),
    width: "10%",
  },
];

/* Material table options */
const options = {
  editable: false,
  search: true,
  pageSize: 50,
  emptyRowsWhenPaging: false,
  pageSizeOptions: [10, 20, 50, 100, 500],
  sorting: true,
  actionsColumnIndex: -1,
  toolbarButtonAlignment: "left",
  initialPage: 0,
  selection: true,
};

export default getColumnDefinitions;
export { title, materialTableLocalization as localization, options };
