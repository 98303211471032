import React, { useEffect } from 'react'

const Logout = (props) => {

    useEffect(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("token_expiration_date");
        localStorage.removeItem("areaId");
        props.history.push("/")
        props.onLoggedOut();
    },[props]);

    return (<></>)
}

export default Logout;