import React, { useContext, useState, useEffect } from 'react'
import { Grid, Box } from '@material-ui/core';
import MaterialTable, { MTableToolbar }  from 'material-table';
import UserContext from '../../../contexts/userContext/userContext.jsx';
/* importing columns a data service */
import columns, { title, localization, options } from './holidays.def.jsx'
import genericDataService from '../../../services/genericDataService';
import mtablePerm from '../../../helpers/permissions/permissions.jsx';
import Alert from '../../app/alert'
import HolidaysToolbar from './holidaysToolbar.jsx';

const permissions = new mtablePerm("holidays");

const holidayDataService = new genericDataService("/holidays");

const tableHolidays = React.createRef();

const Holidays = (props) => {

  const HOLIDAY_TYPE_FIELD_ID = 1;

  const me = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState({});

  const [data, setData] = useState(() => () => holidayDataService.emptyTable());
  const [date, changeDate] = useState(new Date());



  const [alert, setAlert] = useState({
    severity:"success",
    open: false,
    message:""
  });

  /* traigo los holidays types necesarios para el abm */
  useEffect(() => {
    holidayDataService
      .lookup("/holiday_types/all")
      .then((res) => res.data.map(holidayTypeItem => columns[HOLIDAY_TYPE_FIELD_ID].lookup[holidayTypeItem.id] = holidayTypeItem.name)
      );
  }, []);

  /* Refresca la tabla de holiday_types luego de los siguiente eventos: mount, refresh, add, update */
  useEffect(() => {
    if (me.user.actual_branch > 0) {
      setActions(permissions.resolve(me?.user?.menu_permissions).get());
      setLoading(false);
    }
  }, [me])

  useEffect(() => {
    let params = {
      year: date
    };
    setData(() => (query) => holidayDataService.rows(query, params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshTable = (filters) => {
    setData(() => (query) => {
        return holidayDataService.rows(query, filters).then((value) => value)
      } );

      tableHolidays.current.onQueryChange();
      setLoading(false);
}

  return (
    <>
    <Alert onClose={()=>setAlert({...alert, open: false})} alert={alert} setAlert={setAlert}/>
    <Box style={{ padding: 20 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            title={title}
            tableRef={tableHolidays}
            columns={columns}
            isLoading={loading}
            onLoading={loading}
            localization={localization}
            loadingType={"linear"}
            options={options}
            data={data}
            editable={{
              ...actions,
              onRowAdd: actions.onRowAdd ?
                newData => {
                  return holidayDataService.rowAdd(newData, me.user.company_id).then(res => {
                    setAlert({
                      severity: 'success',
                      message: 'Feriado creado correctamente: ' + newData.description,
                      open: true
                    });
                  }).catch((error) => {
                    setAlert({
                      severity: 'error',
                      message: error.message == "Request failed with status code 403" 
                        ? "Sólo los super administradores pueden modificar los feriados globales" 
                        : error.message,
                      open: true
                    });
                  });
              } : undefined,
              onRowUpdate: actions.onRowUpdate ? (newData, oldData) => holidayDataService.rowUpdate(newData, oldData) : undefined,
            }}
            components={{
              Toolbar: props => (
              <>
                <MTableToolbar {...props}/>
                <HolidaysToolbar
                  onSearch={refreshTable}
                  changeDate={changeDate}
                  date={date}
                />
              </>
              )
            }}
            actions={[
              {
                icon: 'list',
                tooltip: 'Tipos de feriados',
                isFreeAction: true,
                onClick: () => props.history.push("/admin/holiday_types")
              }
            ]}
          />
        </Grid>
      </Grid>
    </Box>
    </>
  )
}

export default Holidays;