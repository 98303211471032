import React, { useState, useEffect } from 'react'
//card
import { Accordion, AccordionSummary, AccordionDetails , Box, TextField, Grid, Typography} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import genericDataService from '../../../../../services/genericDataService.jsx'
import { Map, TileLayer, Marker } from "react-leaflet";
import { useDebounce } from '../../../../../hooks/useDebounce.jsx';

const AddressAccordion = (props) => {

  const { classes, setAccordionIndex, accordionIndex, user, index, setAlert, readOnly, creating } = props;

  const [edited, setEdited] = useState(false);
  const [address, setAddress] = useState({});
  const [cities, setCities] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [citySelected, setCitySelected] = useState({});
  const [firstTimeEdited, setFirstTimeEdited] = useState(false);
  const debounceValue = useDebounce(searchCity, 500);
  const params = {
    q: "",
    format: "json",
    addressdetails: "addressdetails",
  };
  const [state, setState] = useState({
    currentLocation: { lat: 52.52437, lng: 13.41053 },
    zoom: 16,
  });

  useEffect(() => {
    const obtenerCoordenadas = async (direccion) => {
      const direccionFormateada = encodeURIComponent(direccion);
      const url = `https://nominatim.openstreetmap.org/search?format=json&q=${direccionFormateada}`;

      const respuesta = await fetch(url);
      const datos = await respuesta.json();

      if (datos.length > 0) {
        const ubicacion = datos[0];
        const latitud = ubicacion.lat;
        const longitud = ubicacion.lon;

        setState({
          ...state,
          currentLocation: { lat: latitud, lng: longitud },
        });
      }
    };

    obtenerCoordenadas(address?.address + ' ' + address?.city);
  }, [address]);
  
  const obtenerDireccionDesdeCoordenadas = async (lat, lon) => {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=18&addressdetails=1`;

      const response = await fetch(url);
      const data = await response.json();
      setAddress({
        address: `${data.address?.road ?? ''} ${data.address?.house_number ?? ''}`.trim(),
        city: `${data.address?.town ? data.address?.town : data.address?.municipality ?? ''}, ${data.address?.state ?? ''}, ${data.address?.country ?? ''}`.trim(),
        postalcode: `${data.address?.postcode?.replace(/\D/g, '') ?? ''}`.trim(),
      });
      setCitySelected(data);

      if(user.address != data.display_name) {
        handleEdited(true);
      } else {
        handleEdited(false);
      }
  };

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;

    setState({
      ...state,
      currentLocation: { lat, lng },
    });

    obtenerDireccionDesdeCoordenadas(lat, lng);
  }

  const handleEdited = (value) => {
    if (!creating) setEdited(value);
  }

  useEffect(() => {
    setCitySelected({
      display_name : user.address?.address + ' ' + user.address?.city
    });
    setAddress(user.address);
  }, [user]);

  const handleSavePressed = () => {
    const dataService = new genericDataService('addresses')
    if(user.address?.id){
      dataService.update(user.address.id, address)
        .then( res => {
          setEdited(false);
          setAlert({
            severity: 'success',
            message: 'Domicilio actualizado correctamente',
            open: true
          });
        })
        .catch(e => {
          setAlert({
            severity: 'error',
            message: e.message,
            open: true
          });
        });
    } else {
      dataService
        .store({
          ...address,
          user_id: user.id,
        }).then( res => {
          setEdited(false);
          setFirstTimeEdited(true);
          setAlert({
            severity: 'success',
            message: 'Domicilio guardado correctamente',
            open: true});
          })
        .catch((e) => {});
    }
  }

  useEffect(() => {
    if (debounceValue.length > 2) {
        const params = {
            q: debounceValue,
            format: "json",
            addressdetails: 1,
            polygon_geojson: 0,
        };
        const queryString = new URLSearchParams(params).toString();
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };
        fetch(`https://nominatim.openstreetmap.org/search?${queryString}`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                setCities(JSON.parse(result));
            })
            .catch((err) => console.log("err: ", err));
    }
  }, [debounceValue]);

  const filterOptions = (options, { inputValue }) => {
    return options;
  };

  return (
    <Accordion
      expanded={accordionIndex === index}
      onChange={(e,v)=>{
        setAccordionIndex(v ? index : -1)
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        IconButtonProps={{color:'secondary'}}
      >
        <Grid container>
          <Grid item xs={11}>
            <Typography variant='h5'>Domicilio</Typography>
          </Grid>
          { edited ?
            <Grid item xs={1}>
            <IconButton onClick={handleSavePressed} aria-label="save-accordion">
              <SaveIcon />
            </IconButton>
          </Grid>
          : <></>}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>       
        <div style={{ width: '100%', height: '400px' }}>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Box className={classes.widerField}>
            <Autocomplete
                noOptionsText='Escriba para buscar'
                options={cities}
                disabled={readOnly}
                value={citySelected}
                filterOptions={filterOptions}
                onChange={(e, value, reason)=> {
                  if (reason === 'select-option') {
                      handleEdited(true);
                      setAddress({
                        address: value.address?.road + ' ' + value.address?.house_number,
                        city: value.address?.town + ', ' + value.address?.state + ', ' + value.address?.country,
                        postalcode: value.address?.postcode.replace(/\D/g, ''),
                      });
                      setCitySelected(value);
                  } else {
                      handleEdited(false);
                  }
                }}
                inputValue={searchCity}
                onInputChange={(e, newInputValue) => setSearchCity(newInputValue)}
                getOptionLabel={(option) => option?.display_name || ""}
                renderInput={(params) => <TextField {...params}
                                        multiline
                                        rowsMax={2}
                                        label="Ciudad"
                                        margin="dense"
                                        />}
            />
          </Box>
        </Grid>
          <Map center={state.currentLocation} zoom={state.zoom} style={{ width: '90%', height: '70%' }} onClick={handleMapClick}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={state.currentLocation}></Marker>
            </Map>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default AddressAccordion;