import React, { useState, useEffect } from "react";
//card
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SaveIcon from "@material-ui/icons/Save";
import CompanyFields from "../fields/companyFields.jsx";
import genericDataService from "../../../../../services/genericDataService.jsx";

const CompanyAccordion = (props) => {
  const {
    setAccordionIndex,
    accordionIndex,
    index,
    contractMethods,
    setAlert,
    user,
    errors,
    setErrors
  } = props;
  const [company, setCompany] = useState({});
  const [edited, setEdited] = useState(false);
  const [contractMethod, setContractMethod] = useState({});
  const [loadingBranches, setLoadingBranches] = useState(true);


  const handleSavePressed = async () => {
    const dataService = new genericDataService("users");
    const idUserHolidayTypes = props.userHolidayTypes.map(({ name, id }) => id);
    let params = { ...company, holiday_types: idUserHolidayTypes };
    let userRolesData = [];
    for (let [key, value] of Object.entries(props.userRoles)) {
      let newValue = value.map((element) => element.id);
      userRolesData.push({ branch_id: key, roles: newValue });
    }

    let res = await dataService.update(user.id, params).catch((error) => {
      setErrors(error.response?.data.errors);
      return error.response?.data.errors;
    });

    setAlert({
      severity: res?.status < 500 ? "success" : "error",
      message:
        res?.status < 500
          ? "Información laboral actualizada correctamente"
          : "Error al actualizar la información laboral",
      list: res?.status < 500 ? undefined : res,
      open: true,
    });

    res = await dataService
      .changeResource("users/" + user.id + "/roles")
      .store(userRolesData)
      .catch((error) =>  {
        setErrors(error.response?.data.errors);
        return null;
      });

    setTimeout(() => {
      setAlert({
        severity: res?.status < 500 ? "success" : "error",
        message:
          res?.status < 500
            ? "Roles actualizados correctamente"
            : "No se pudo actualizar los roles",
        open: true,
      });
    }, 3000);

    if (res?.status < 500) setEdited(false);
  };

  useEffect(() => {
    if (contractMethods) {
      const userContractMethod = contractMethods.find(
        (element) => element.id === user.contract_method_id
      );
      setContractMethod(userContractMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractMethods]);

  useEffect(() => {
    setCompany({
      ...company,
      takes_lunch: user.takes_lunch,
      flexible_schedule: user.flexible_schedule,
      vacation_amount: user.vacation_amount,
      admission_date: user.admission_date,
      entry_time: user.entry_time,
      job_email: user.job_email,
      workload: user.workload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion
      defaultExpanded
      expanded={accordionIndex === index}
      onChange={(e, v) => {
        setAccordionIndex(v ? index : -1);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        IconButtonProps={{ color: "secondary" }}
      >
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h5">Información laboral</Typography>
          </Grid>
          {edited ? (
            <Grid item xs={1}>
              <IconButton onClick={handleSavePressed}>
                <SaveIcon />
              </IconButton>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <CompanyFields
          {...props}
          errors={errors}
          company={company}
          contractMethod={contractMethod}
          setContractMethod={setContractMethod}
          setCompany={setCompany}
          setEdited={setEdited}
          edited={edited}
          loadingBranches={loadingBranches}
          setLoadingBranches={setLoadingBranches}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyAccordion;
