import React, { useState, useEffect } from "react";
//card
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import genericDataService from "../../../../../services/genericDataService";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Loading from "../../../../loading/loading";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment-timezone";

export default function CompanyFields(props) {
  const {
    classes,
    readOnly,
    loading,
    company,
    setCompany,
    setUserHolidayTypes,
    userHolidayTypes,
    holidayTypes,
    roles,
    userRoles,
    setUserRoles,
    contractMethods,
    creating,
    setEdited,
    contractMethod,
    setContractMethod,
  } = props;

  const handleEdited = (value) => {
    if (!creating) setEdited(value);
  };

  const handleHolidayTypesChange = (event, value) => {
    handleEdited(true);
    setUserHolidayTypes(value);
  };

  const handleRolesChange = (value, branch) => {
    handleEdited(true);
    let userRolesData = { ...userRoles };
    userRolesData[branch.id] = value;
    setUserRoles(userRolesData);
  };

  const [freelance, setFreelance] = useState(false);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    if (contractMethod?.id && contractMethod?.name === "Freelance") {
      setFreelance(true);
      setCompany({
        ...company,
        vacation_amount: 0,
        workload: 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractMethod]);

  useEffect(() => {
    if(props.user.company_id) {
      new genericDataService()
      .lookup("companies/" + props.user.company_id + "/branches")
      .then((res) => {
        setBranches(res.data);
        props.setLoadingBranches(false);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [props]);

  return (
    <Grid container>
      {/* Primera fila */}
      <Grid item xs={12} sm={4}>
        <Box className={classes.selectSize}>
          <Autocomplete
            id="contractMethod"
            autoComplete
            disableClearable
            loading={props.loadingBranches}
            autoHighlight
            value={contractMethod}
            onChange={(e, value) => {
              setContractMethod(value);
              if (value.name === "Freelance") {
                setCompany({
                  ...company,
                  contract_method_id: value.id,
                  vacation_amount: 0,
                  workload: 0
                });
                setFreelance(true);
              } else {
                setCompany({ ...company, contract_method_id: value.id });
                setFreelance(false);
              }
              handleEdited(true);
            }}
            inputValue={contractMethod?.name}
            getOptionLabel={(option) => {
              if (option && option.name) return option.name;
              else return "";
            }}
            options={contractMethods ?? []}
            disabled={readOnly}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.margin}
                label="Modo de contratación"
                helperText={
                  "contract_method_id" in props?.errors
                    ? props?.errors["contract_method_id"]
                    : ""
                }
                error={"contract_method_id" in props?.errors}
                margin="dense"
              />
            )}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          onChange={(e) => {
            setCompany({ ...company, job_email: e.target.value });
            handleEdited(true);
          }}
          className={classes.margin}
          value={company.job_email ?? ""}
          inputProps={{ readOnly: readOnly }}
          label="Email laboral"
          helperText={
            "job_email" in props.errors
              ? props?.errors["job_email"]
              : ""
          }
          error={"job_email" in props?.errors}
          margin="dense"
        />
      </Grid>

      {/* Segunda fila */}
      <Grid item xs={12} sm={4}>
        <Box
          maxWidth={"199px"}
          style={{
            minHeight: "40px",
            marginLeft: "6px",
            marginTop: "6px",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy/MM/dd"
              margin="normal"
              openTo="year"
              disabled={readOnly}
              disableFuture
              label="Fecha de ingreso"
              value={moment(company.admission_date) ?? null}
              maxDateMessage="La fecha no debe ser posterior a la actual"
              autoOk
              helperText={
                "admission_date" in props?.errors
                  ? props?.errors["admission_date"]
                  : ""
              }
              error={"admission_date" in props?.errors}
              onChange={(value) => {
                handleEdited(true);
                setCompany({
                  ...company,
                  admission_date: moment(value).format("YYYY/MM/DD 00:00:00"),
                });
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          className={classes.margin}
          value={company.workload || '0'}
          onChange={(e) => {
            const re = /^[0-9\b]+$/;
            if (e.target.value === "" || re.test(e.target.value)) {
              handleEdited(true);
              setCompany({ ...company, workload: e.target.value });
            }
          }}
          inputProps={{ readOnly: readOnly || freelance }}
          label="Carga laboral - Horas"
          margin="dense"
          helperText={
            "workload" in props?.errors
              ? props?.errors["workload"]
              : ""
          }
          error={"workload" in props?.errors}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          className={classes.margin}
          value={company.entry_time}
          onChange={(e) => {
            setCompany({ ...company, entry_time: e.target.value });
            handleEdited(true);
          }}
          inputProps={{ readOnly: readOnly }}
          label="Hora de entrada"
          margin="dense"
          helperText={
            "entry_time" in props?.errors
              ? props?.errors["entry_time"]
              : ""
          }
          error={"entry_time" in props?.errors}
        />
      </Grid>

      {!creating ? (
        <Grid item xs={12} sm={4}>
          <TextField
            className={classes.margin}
            value={company.vacation_amount || '0'}
            onChange={(e, value) => {
              handleEdited(true);
              setCompany({ ...company, vacation_amount: e.target.value });
            }}
            inputProps={{ readOnly: readOnly || freelance }}
            label="Días de vacaciones"
            helperText={
              "vacation_amount" in props?.errors
                ? props?.errors["vacation_amount"]
                : ""
            }
            error={"vacation_amount" in props?.errors}
            margin="dense"
          />
        </Grid>
      ) : (
        <></>
      )}

      {/* Tercera fila */}
      <Grid item xs={12} sm={4}>
        <FormControlLabel
          className={classes.marginCheckbox}
          control={
            <Checkbox
              disabled={readOnly}
              checked={company.flexible_schedule === 1}
              onChange={(e, value) => {
                handleEdited(true);
                setCompany({ ...company, flexible_schedule: value ? 1 : 0 });
              }}
              name="flexibleSchedule"
            />
          }
          label="Flexibilidad horaria"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControlLabel
          className={classes.marginCheckbox}
          control={
            <Checkbox
              disabled={readOnly}
              checked={company.takes_lunch === 1}
              onChange={(e, value) => {
                handleEdited(true);
                setCompany({ ...company, takes_lunch: value ? 1 : 0 });
              }}
              name="flexibleSchedule"
            />
          }
          label="Descuenta hora de almuerzo"
        />
      </Grid>
      <Grid item xs={12} sm={!creating ? 10 : 6}>
        <Autocomplete
          id="userHolidayTypes"
          autoComplete
          multiple
          options={holidayTypes}
          disableClearable
          loading={loading}
          autoHighlight
          onChange={handleHolidayTypesChange}
          value={userHolidayTypes}
          getOptionLabel={(option) => {
            if (option && option.name) return option.name;
            else return "";
          }}
          disabled={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.margin}
              variant="outlined"
              label="Tipos de feriado"
              margin="dense"
            />
          )}
        />
      </Grid>
      {!creating ? (
          <Grid item xs={12} sm={10}>
            <Box ml={2} height={4} width={5}  mt={5} display="flex" alignItems="center">
              <Loading open={props.loadingBranches}></Loading>
            </Box>
            <Box ml={2} mt={5} display="flex" alignItems="center">
              <ListAltIcon />
              <Box minWidth={4} />
              <Typography variant="h5">Roles</Typography>
            </Box>
            {branches.map((branch, index) => {
              return (
                <Autocomplete
                  id={"userRoles_branch" + branch.id}
                  autoComplete={true}
                  multiple
                  key={branch.id}
                  options={roles}
                  fullWidth
                  disableCloseOnSelect
                  loading={loading}
                  autoHighlight
                  onChange={(event, value) => handleRolesChange(value, branch)}
                  // value={[]}
                  value={userRoles[branch.id] ?? []}
                  getOptionLabel={(option) => {
                    if (option && option.full_name) return option.full_name;
                    else return "";
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.full_name}
                    </React.Fragment>
                  )}
                  disabled={readOnly}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.margin}
                      variant="outlined"
                      label={"Roles en " + branch.name}
                      margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
              );
            })}
          </Grid>
        ) : (
        <></>
      )}
    </Grid>
  );
}
