import HomeIcon from "@material-ui/icons/Home";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Timelapse from "@material-ui/icons/Timelapse";
import StoreIcon from "@material-ui/icons/Store";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import RedeemIcon from "@material-ui/icons/Redeem";
import FlightIcon from "@material-ui/icons/Flight";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MailIcon from '@material-ui/icons/Mail';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';

const categoryIcons = [
  {
    id: 1,
    name: "Trabajo",
    icon: HomeWorkIcon,
  },
  {
    id: 2,
    name: "Licencias",
    icon: LocalHospitalIcon,
  },
  {
    id: 3,
    name: "Vacaciones",
    icon: FlightIcon,
  },
  {
    id: 4,
    name: "Regímenes especiales",
    icon: RedeemIcon,
  },
];

const menu = [
  {
    name: "dashboard",
    text: "Panel de control",
    path: "/dashboard",
    exact: true,
    position: "top-menu",
    icon: HomeIcon,
  },
  {
    name: "admin",
    text: "Empresa",
    position: "top-menu",
    icon: SupervisorAccountIcon,
    childs: [
      {
        name: "areas",
        text: "Areas",
        path: "/admin/areas",
        exact: true,
        icon: SupervisedUserCircleIcon,
        permission: "read_areas",
      },
      {
        name: "roles",
        text: "Roles",
        path: "/admin/roles",
        exact: true,
        icon: VpnKeyIcon,
        permission: "read_roles",
      },
      {
        name: "branches",
        text: "Sucursales",
        path: "/admin/branches",
        exact: true,
        icon: StoreIcon,
        permission: "read_branches",
      },
      {
        name: "activity_types",
        text: "Tipos de actividad",
        path: "/admin/activity_types",
        exact: true,
        icon: FormatListBulletedIcon,
        permission: "read_activity_types",
      },
      {
        name: "activity_type_validators",
        text: "Validadores de actividad",
        path: "/admin/activity_type_validators",
        exact: true,
        icon: VerifiedUserIcon,
        permission: "read_activity_validators",
      },
      {
        name: "holidays",
        text: "Feriados",
        path: "/admin/holidays",
        exact: true,
        icon: DateRangeIcon,
        permission: "read_holidays",
      },
      {
        name: "users",
        text: "Colaboradores",
        path: "/admin/users",
        exact: true,
        icon: PeopleIcon,
        permission: "read_users",
      },
      {
        name: "templates",
        text: "Plantillas de correo",
        path: "/admin/templates",
        exact: true,
        icon: MailIcon,
        permission: "read_templates",
      },
    ],
  },
  {
    name: "trace_activities",
    text: "Seguimiento de actividades",
    path: "/admin/trace-activities",
    exact: true,
    icon: AssignmentInd,
    position: "top-menu",
  },
  {
    name: "trace_activities_tickets",
    text: "Reporte de horas",
    path: "/admin/trace-activities-tickets",
    exact: true,
    icon: Timelapse,
    position: "top-menu",
  },
  {
    name: "salaries",
    text: "Reporte liquidación de sueldos",
    path: "/admin/salaries",
    exact: true,
    icon: AssignmentTurnedInIcon,
    position: "top-menu",
    permission: "read_settlement_report"
  },
  {
    name: "import-csv",
    text: "Importar CSV",
    path: "/admin/import-csv",
    exact: true,
    icon: CloudUploadIcon,
    position: "top-menu",
    permission: "import_activities"
  },
  {
    name: "pending-activities",
    text: "Aprobación de actividades",
    path: "/admin/vote-activities",
    exact: true,
    icon: ThumbsUpDownIcon,
    position: "top-menu",
    permission: "local_approver" || "global_approver"
  },
  {
    name: "profile",
    text: "Perfil",
    path: "/profile",
    exact: true,
    position: "user-menu",
  },
  {
    name: "wallpaper",
    text: "Cambiar fondo",
    path: "/wallpapers",
    exact: true,
    position: "user-menu",
  },
  {
    name: "logout",
    text: "Cerrar sesión",
    path: "/logout",
    exact: true,
    position: "user-menu",
  },
];

export default menu;

export { categoryIcons };
