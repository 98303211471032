import { createMuiTheme } from '@material-ui/core/styles';

/* Leo desde las caracteristicas del navegador si el usuario tiene activo el modo dark
const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
import useMediaQuery from '@material-ui/core/useMediaQuery';
*/

const darkTheme = createMuiTheme({ palette: {type:'dark'}});
const lightTheme = createMuiTheme({ palette: {type:'light'}});

const getThemeSelected = () => {
    return localStorage.getItem("darkMode")==null ? false : (localStorage.getItem("darkMode")==="false" ? false: true);
}

export { darkTheme };
export { lightTheme };
export default getThemeSelected;