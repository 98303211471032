import materialTableLocalization from '../../../helpers/localization/materialTableLocalization.jsx';

/* Material table title */
const title = "Administración de validadores de actividad";

/* Material table columns defs */
const columns = [
  {title: 'Tipo de actividad', field: 'activity_type_id', validate: rowData => rowData.activity_type_id > 0, lookup:{}, sorting: false},
  {title: 'Area', field: 'area_id', validate: rowData => rowData.area_id > 0, lookup:{}, sorting: false},
  {title: 'Aprobación manual', field: 'manual', type: 'boolean', sorting: false },
  {title: 'Orden', field: 'order',  type: 'numeric', searchable: false, sorting: false },
]

/* Material table options */
const options = 
{
  editable: true,
  search: true,
  addRowPosition: "first",
  pageSize: 50,
  emptyRowsWhenPaging: false,
  pageSizeOptions: [10, 20, 50, 100, 500],
  exportButton: true,
  exportAllData: true,
  sorting: true,
  actionsColumnIndex: -1,
  toolbarButtonAlignment:'left',
  initialPage: 0,
  filtering: true
}

export default columns;
export { title, materialTableLocalization as localization, options };
