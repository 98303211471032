import materialTableLocalization from '../../../helpers/localization/materialTableLocalization.jsx';
import moment from 'moment';

/* Material table title */
const title = "Administración de feriados";

/* Material table columns defs */
const columns = [
  {title: 'Descripcion', field: 'description', validate: rowData => rowData.description !== ''},
  {title: 'Tipo', field: 'holiday_type_id', validate: rowData => rowData.holiday_type_id > 0, lookup:{}},
  {
    title: 'Desde',
    field: 'date_from',
    type: 'datetime',
    defaultSort: 'asc',
    initialEditValue: moment().startOf('day')
  },
  {
    title: 'Hasta',
    field: 'date_to',
    type: 'datetime',
    initialEditValue: moment().endOf('day')
  },
]

/* Material table options */
const options = 
{
  editable: true,
  search: true,
  addRowPosition: "first",
  pageSize: 10,
  emptyRowsWhenPaging: false,
  pageSizeOptions: [10, 20, 50, 100, 500],
  exportButton: true,
  exportAllData: true,
  sorting: true,
  actionsColumnIndex: -1,
  toolbarButtonAlignment:'left',
  initialPage: 0
}

export default columns;
export { title, materialTableLocalization as localization, options };
