import React, { useEffect, useState } from 'react'

import apiClient from "../../../helpers/apiClient/apiClient.jsx"

import { CssBaseline,  Avatar, Box, Typography, Container, Link } from '@material-ui/core';

/* Material icons */
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

/* import image logo*/
import imageLogo from "../../../resources/images/logo.png";

/* custom theme mods */
import useStyles from "../login/login.css";

/* history for routing */
import { useHistory } from "react-router-dom";

const NotFound = () => {

  /* import custom classes */
  const classes = useStyles();

  const history = useHistory();

  const [ping, setPing] = useState(null);

  useEffect(() => {
    apiClient.get("/ping")
      .then((res) => res.data)
      .then((res) =>  setPing(res.message === 'pong!' ? true : false ))
      .catch((err) => setPing(false))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(ping){
      history.replace('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ping]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <img src={imageLogo} alt='Netlabs' />
        <Avatar className={classes.avatarClose}><LockOutlinedIcon /></Avatar>
        <Typography component="h1" variant="h5">Access control</Typography>
        <Typography variant="body1" color="error" align="center"><br /><br /><br />Lo sentimos, el servidor de aplicación no responde.<br /> vuelva a intentar en unos minutos</Typography>
      </Box>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link variant="body2" color="inherit" target='_blank' href="https://www.netlabs.com.ar">Netlabs</Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  )
}

const NotFoundPage = () => {

  /* import custom classes */
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <img src={imageLogo} alt='Netlabs' />
        <Avatar className={classes.avatarClose}><LockOutlinedIcon /></Avatar>
        <Typography component="h1" variant="h5">Access control</Typography>
        <Typography variant="body1" color="error" align="center"><br /><br /><br />La pagina a la que querés acceder no existe.<br />Contanos como llegaste <Link variant="body2" color="inherit" target='_blank' href='mailto:desarrollo@netlabs.com.ar'>acá</Link></Typography>
      </Box>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link variant="body2" color="inherit" target='_blank' href="https://www.netlabs.com.ar">Netlabs</Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  )
}

export default NotFound;
export {NotFoundPage};