
import moment from 'moment-timezone';
import materialTableLocalization from '../../../../helpers/localization/materialTableLocalization.jsx';

/* Material table title */
const title = "Listado de actividades";

/* Material table columns defs. table activity */
const columns = [
  { 
    title: 'Inicio', 
    field: 'start', 
    render: rowData => (
      moment(rowData.start).format('DD/MM/YY HH:mm')
    ),
    type:'datetime',
    editable: 'never',
    width: "10%" 
  },
  { 
    title: 'Fin', 
    field: 'end',
    render: rowData => (
      rowData.end=== null ? "En curso" : moment(rowData.end).format('DD/MM/YY HH:mm')
    ),
    type:'datetime',
    width: "10%" 
  },
  {
    filtering: false ,
    title: 'Descripción', 
    field: 'summary',
    editable: 'never',
    width: "70%" 
  },
  { 
    title: 'Estado', 
    field: 'status_name',
    editable: 'never',
    width: "10%" 
  }
];



/* Material table options. table activities */
const options = 
{
  editable: false,
  search: true,
  pageSize: 10,
  pageSizeOptions: [10, 20, 50, 100, 500],
  exportButton: true,
  exportAllData: true,
  sorting: true,
  actionsColumnIndex: -1,
  toolbarButtonAlignment:'left',
  initialPage: 0,
  filtering: false
}

export default columns;
export { title, materialTableLocalization as localization, options };
