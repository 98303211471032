import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, 
  Box, Button, Dialog, DialogActions, DialogContent, TextField, IconButton, ListItemSecondaryAction } from "@material-ui/core";
import React, { useState } from "react";
import PhoneIcon from '@material-ui/icons/Phone';
import '../../profile.css';
import AddIcon from '@material-ui/icons/Add';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from '@material-ui/icons/Delete';

export default function PhoneFields (props) {
    const { phones, phoneTypes, primary, secondary, setPhones, isContact,
      label, classes, title, creating, setEdited, loading } = props;

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [phoneTypeSelected, setPhoneTypeSelected] = useState({});
    const [searchPhoneType, setSearchPhoneType] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [fullname, setFullname] = useState("");

    const handleNewPhone = () => {
      setOpen(true);
    };

    const handleSave = () => {
      let phone = {};
      phone[primary] = phoneNumber ?? '';
      phone[secondary] = phoneTypeSelected.id ?? '';
      phone[label] = isContact ? fullname : phoneTypeSelected.name ?? '';

      setDisabled(true);
      phones.push(phone);
      setOpen(false);
      resetState();
      handleEdited(true);
    };


    const resetState = () => {
      setSearchPhoneType('');
      setPhoneNumber('');
      setFullname('');
      setPhoneTypeSelected({});
    }

    const handleEdited = (value) => {
      if (!creating) setEdited(value);
    }

    const handleClose = () => {
      resetState();
      setOpen(false);
      setDisabled(true);
    }

    function handleDeleteItem(index) {
      let clone = [...phones]
      clone.splice(index, 1)
      setPhones(clone);
      handleEdited(true);
    }

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>

          { isContact ? 
          <TextField
              value={fullname}
              onChange={(e) => {
                  setFullname(e.target.value);
                  setDisabled(phoneTypeSelected?.id == null
                      || !phoneNumber.trim()
                      || (isContact && !e.target.value.trim())
                    );
                }
              }
              variant="outlined"
              label="Nombre completo"
              margin="dense"
            />
          : <></> }
          <Autocomplete
            noOptionsText='Escriba para buscar'
            options={phoneTypes}
            fullWidth
            loading={loading}
            value={phoneTypeSelected}
            onChange={(e, value)=> {
              setPhoneTypeSelected(value);
              setDisabled(!value || phoneNumber.trim() === "" || (isContact && fullname.trim() === ""));
            }}
            inputValue={searchPhoneType}
            onInputChange={(e, newInputValue) => {
                setSearchPhoneType(newInputValue);
              }}
            getOptionLabel={(option) => {
                if (option && option.name) return option.name
                else return "";
              }
            }
            
            renderInput={(params) => <TextField {...params}
                                      variant="outlined"
                                      className={classes.selectSizeDialog}
                                      fullWidth
                                      label="Tipo"
                                      margin="dense"
                                    />}
              />
            <TextField
              value={phoneNumber}
              onChange={(e) => {
                  if(e.key === 'Enter'){
                    handleSave();
                  } else {
                    setPhoneNumber(e.target.value);
                    setDisabled(phoneTypeSelected?.id == null
                        || !e.target.value.trim()
                        || (isContact && !fullname.trim())
                      );
                  }
                }
              }
              variant="outlined"
              label="Número"
              margin="dense"
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button disabled={disabled} onClick={handleSave} variant='contained' color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>    
        <Grid container>
          <Grid item xs={12}>
            <Box mx={20}>
              <Box height={85}>
                <Grid container>
                  <Grid item xs={10}>
                    { creating ? 
                      <Typography align='center' variant='button' gutterBottom>{title}</Typography>
                      : <ContactPhoneIcon fontSize='large'/>
                    }
                  </Grid>
                  { props.readOnly ? <></> : 
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNewPhone}
                      className={classes.button}
                      startIcon={<AddIcon />}
                    >
                      Nuevo
                    </Button>
                  </Grid>}
                </Grid>
              </Box>
              { phones.length === 0 ?
                <Typography variant='h6'>No hay teléfonos registrados</Typography>
              : <List dense={true}>
                  { phones.map((phone,index) =>
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                        <PhoneIcon />
                      </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={phone[primary] ? phone[primary].toString() : ""}
                        secondary={phone[label] ? phone[label].toString() : ""}
                      />
                       { props.readOnly ? <></> : 
                      <ListItemSecondaryAction>
                        <IconButton onClick={()=>{handleDeleteItem(phone, index)}} edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction> }
                    </ListItem>
                  )}
                </List>
              }
            </Box>
          </Grid>
        </Grid>
      </>
    )
}