import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Zoom,
  Button,
} from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";

const openWiki = () => {
  window.open("https://wiki.ac.netlabs.com.ar", "_blank").focus();
};

const CardWiki = (props) => {
  const { classes, zoomIn, velZoomIn } = props;
  return (
    <Zoom in={zoomIn} style={{ transitionDelay: zoomIn ? velZoomIn : "0ms" }}>
      <Grid item xs={12} sm={6} lg={3}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Manual de usuario
            </Typography>
            <Typography variant="h5" component="h2">
              Ayuda en línea
            </Typography>
                <MenuBookIcon style={{ fontSize: 50, paddingTop: 10, margin: 2 }} />
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" onClick={openWiki}>
              Ir al manual
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Zoom>
  );
};

export default CardWiki;
