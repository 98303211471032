import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import PersonalAccordion from "./accordions/personalAccordion";
import AddressAccordion from "./accordions/addressAccordion";
import PhonesAccordion from "./accordions/phonesAccordion";
import {
  makeStyles,
  Box,
  Grid,
  Container,
  AppBar,
  Tab,
  Tabs,
  Typography,
  Slide,
} from "@material-ui/core";
import UserContext from "../../../../contexts/userContext/userContext.jsx";
import SocialNetworksAccordion from "./accordions/socialNetworksAccordion";
import CompanyAccordion from "./accordions/companyAccordion";
import OutOfOfficeAccordion from "./accordions/outOfOfficeAccordion";
import Alert from "../../../app/alert";
import genericDataService from "../../../../services/genericDataService";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant="h5">{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  marginCheckbox: {
    marginTop: 10,
    marginLeft: 2,
  },
  selectSizeIdentityType: {
    width: "75%",
    "@media only screen and (max-width: 600px)": {
      width: "227px",
    },
  },
  selectSize: {
    width: "57%",
    "@media only screen and (max-width: 600px)": {
      width: "227px",
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  widerField: {
    width: "90%",
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  postalCode: {
    width: "65%",
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  paperStyleFields: {
    borderRadius: 25,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperStyleImage: {
    borderRadius: 25,
    width: 250,
    height: 250,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ProfileEdit = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [accordionIndex, setAccordionIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [readOnly, setReadOnly] = useState(true);
  const [alert, setAlert] = useState({ open: false });
  const [zoomIn, setZoomIn] = useState(false);

  //phones
  const [personalPhones, setPersonalPhones] = useState(null);
  const [contactPhones, setContactPhones] = useState(null);
  const [workPhones, setWorkPhones] = useState(null);
  const [personalSocialNetworks, setPersonalSocialNetworks] = useState(null);
  const [workSocialNetworks, setWorkSocialNetworks] = useState(null);
  const [phoneTypes, setPhoneTypes] = useState([]);

  const [identityTypes, setIdentityTypes] = useState(null);
  const [holidayTypes, setHolidayTypes] = useState(null);
  const [userHolidayTypes, setUserHolidayTypes] = useState([]);
  const [userRoles, setUserRoles] = useState({
    branchId: 1,
    roles: { id: 1, full_name: "Test" },
  });
  const [errors, setErrors] = useState([]);
  const [roles, setRoles] = useState([]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      setAccordionIndex(0);
    } else {
      setAccordionIndex(5);
    }
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  const me = useContext(UserContext);
  let user = me.user;
  if (props.user) user = props.user;
  const dataService = new genericDataService();
  const [contractMethods, setContractMethods] = useState([]);

  useEffect(() => {
    dataService
      .lookup("/contract_methods/all")
      .then((res) => {
        setContractMethods(res.data);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (holidayTypes === null) {
      dataService
        .lookup("/holiday_types/all")
        .then((res) => {
          setHolidayTypes(res.data);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (holidayTypes === null) {
      dataService
        .lookup("/roles/all")
        .then((res) => {
          setRoles(res.data);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.actual_branch > 0 || user.active != null) {
      dataService
        .lookup("users/" + user.id + "/contacts")
        .then((res) => {
          setContactPhones(res.data);
        })
        .catch(() => {});

      dataService
        .lookup("/identity_types/all")
        .then((res) => {
          setIdentityTypes(res.data);
        })
        .catch((err) => {});

      dataService
        .lookup("users/" + user.id + "/phones")
        .then((res) => {
          setPersonalPhones(res.data);
        })
        .catch(() => {});

      dataService
        .lookup("users/" + user.id + "/work-phones")
        .then((res) => {
          setWorkPhones(res.data);
        })
        .catch(() => {});

      dataService
        .lookup("users/" + user.id + "/social-networks")
        .then((res) => {
          setPersonalSocialNetworks(res.data);
        })
        .catch(() => {});

      dataService
        .lookup("users/" + user.id + "/work-social-networks")
        .then((res) => {
          setWorkSocialNetworks(res.data);
        })
        .catch(() => {});

      dataService
        .lookup("users/" + user.id + "/holiday-types")
        .then((res) => {
          setUserHolidayTypes(res.data);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const hasPermission = me?.user?.menu_permissions?.includes("update_users") ?? false;
    setReadOnly(hasPermission ? false : true);
    let roles = {};
    if(user.branches) {
      user.branches.forEach((value, index) => {
        if (!(`${value.id}` in roles)) {
          roles[value.id] = [];
        }
        let role = user.roles.find(
          (element) => element.id === value.pivot.role_id && value.pivot.active
        );

        if (role) {
          roles[value.id].push({
            id: value.pivot.role_id,
            full_name: role.full_name,
          });
        }
      });
    }

    setUserRoles(roles);
    setZoomIn(true);
  }, [me, user]);

  useEffect(() => {
    const phonesTypesDataService = new genericDataService("phone_types/all");
    if (phoneTypes === null || phoneTypes?.length === 0) {
      setLoading(true);
      phonesTypesDataService.index().then((res) => {
        setPhoneTypes(res.data);
        setLoading(false);
      });
    }
  }, [phoneTypes]);

  return (
    <Slide in={zoomIn} direction="left">
      <Box style={{ padding: 10 }}>
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          alert={alert}
          setAlert={setAlert}
        />
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ height: "100%" }}>
              <AppBar position="static" color="transparent">
                <Tabs
                  value={tabValue}
                  onChange={handleChangeTab}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                >
                  <Tab label="Personal" {...a11yProps(0)} />
                  <Tab label="Laboral" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={tabValue}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={tabValue} index={0} dir={theme.direction}>
                  <PersonalAccordion
                    setAccordionIndex={setAccordionIndex}
                    accordionIndex={accordionIndex}
                    classes={classes}
                    readOnly={readOnly}
                    identityTypes={identityTypes}
                    user={user}
                    me={me}
                    index={0}
                    errors={errors}
                    setErrors={setErrors}
                    loading={loading}
                    setAlert={setAlert}
                  />
                  <AddressAccordion
                    setAccordionIndex={setAccordionIndex}
                    accordionIndex={accordionIndex}
                    classes={classes}
                    readOnly={user.id !== me.user.id}
                    user={user}
                    index={1}
                    setAlert={setAlert}
                  />
                  <PhonesAccordion
                    setAccordionIndex={setAccordionIndex}
                    accordionIndex={accordionIndex}
                    classes={classes}
                    user={user}
                    phones={personalPhones ?? []}
                    setPhones={setPersonalPhones}
                    primary={"number"}
                    secondary={"phone_type_id"}
                    label={"phone_type"}
                    title={"Teléfonos personales"}
                    readOnly={readOnly}
                    path={"users/" + user.id + "/phones"}
                    phoneTypes={phoneTypes}
                    setAlert={setAlert}
                    index={2}
                  />
                  <PhonesAccordion
                    setAccordionIndex={setAccordionIndex}
                    accordionIndex={accordionIndex}
                    classes={classes}
                    user={user}
                    phones={contactPhones ?? []}
                    setPhones={setContactPhones}
                    primary={"phone_number"}
                    secondary={"phone_type_id"}
                    label={"fullname"}
                    title={"Personas de contacto"}
                    path={"users/" + user.id + "/contacts"}
                    isContact
                    readOnly={readOnly}
                    phoneTypes={phoneTypes}
                    setAlert={setAlert}
                    index={3}
                  />
                  <SocialNetworksAccordion
                    setAccordionIndex={setAccordionIndex}
                    accordionIndex={accordionIndex}
                    classes={classes}
                    path={"users/" + user.id + "/social-networks"}
                    title="Redes sociales"
                    socialNetworks={personalSocialNetworks ?? []}
                    setSocialNetworks={setPersonalSocialNetworks}
                    readOnly={readOnly}
                    user={user}
                    setAlert={setAlert}
                    index={4}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={1} dir={theme.direction}>
                  <CompanyAccordion
                    setAccordionIndex={setAccordionIndex}
                    accordionIndex={accordionIndex}
                    classes={classes}
                    user={user}
                    contractMethods={contractMethods}
                    me={me}
                    holidayTypes={holidayTypes ?? []}
                    userHolidayTypes={userHolidayTypes}
                    setUserHolidayTypes={setUserHolidayTypes}
                    roles={roles ?? []}
                    userRoles={userRoles}
                    setUserRoles={setUserRoles}
                    setAlert={setAlert}
                    errors={errors}
                    setErrors={setErrors}
                    loading={loading}
                    readOnly={readOnly}
                    index={5}
                  />
                  {props.user ? (
                    <></>
                  ) : (
                    <OutOfOfficeAccordion
                      setAccordionIndex={setAccordionIndex}
                      accordionIndex={accordionIndex}
                      classes={classes}
                      user={user}
                      contractMethods={contractMethods}
                      me={me}
                      holidayTypes={holidayTypes ?? []}
                      userHolidayTypes={userHolidayTypes}
                      setUserHolidayTypes={setUserHolidayTypes}
                      setAlert={setAlert}
                      errors={errors}
                      setErrors={setErrors}
                      loading={loading}
                      readOnly={readOnly}
                      index={6}
                    />
                  )}
                  <PhonesAccordion
                    setAccordionIndex={setAccordionIndex}
                    accordionIndex={accordionIndex}
                    classes={classes}
                    user={user}
                    phones={workPhones ?? []}
                    setPhones={setWorkPhones}
                    readOnly={readOnly}
                    setAlert={setAlert}
                    primary={"number"}
                    secondary={"phone_type_id"}
                    label={"phone_type"}
                    path={"users/" + user.id + "/work-phones"}
                    title={"Teléfonos laborales"}
                    phoneTypes={phoneTypes}
                    index={7}
                  />
                  <SocialNetworksAccordion
                    setAccordionIndex={setAccordionIndex}
                    accordionIndex={accordionIndex}
                    classes={classes}
                    user={user}
                    path={"users/" + user.id + "/work-social-networks"}
                    title="Redes sociales"
                    setAlert={setAlert}
                    socialNetworks={workSocialNetworks ?? []}
                    setSocialNetworks={setWorkSocialNetworks}
                    readOnly={readOnly}
                    index={8}
                  />
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Slide>
  );
};

export default ProfileEdit;
