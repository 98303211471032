
import React, { useState, useEffect } from 'react'
//card
import Typography from '@material-ui/core/Typography';

import { Grid, List, ListItemAvatar, Avatar, ListItem, ListItemText, Dialog, DialogContent,
   TextField, DialogActions, Button, Box, ListItemSecondaryAction } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TextsmsIcon from '@material-ui/icons/Textsms';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import genericDataService from '../../../../../services/genericDataService';

const SocialNetworksFields = (props) => {

  const { socialNetworks, setSocialNetworks, setEdited, classes, creating } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [socialNetworkTypes, setSocialNetworkTypes] = useState([]);
  const [socialNetworkSelected, setSocialNetworkSelected] = useState({id:0});
  const [search, setSearch] = useState("");
  const [url, setUrl] = useState("");


  const handleNewPhone = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetState();
  }

  const handleEdited = (value) => {
    if (!creating) setEdited(value);
  }

  useEffect(()=>{
    const socialNetworkService = new genericDataService('social_networks/all');
    if(socialNetworks === null || socialNetworks.length === 0) {
      socialNetworkService.index().then((res)=>{
       setSocialNetworkTypes(res.data);
       setLoading(false);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetState = () => {
    setSearch('');
    setSocialNetworkSelected({id:0})
    setSearch("");
    setUrl("");
  }

  const handleSaveSocialNetwork = () => {
    let socialNetwork = {
      social_network_id: socialNetworkSelected.id,
      name: socialNetworkSelected.name,
      url: url
    };
    socialNetworks.push(socialNetwork);
    setOpen(false);
    resetState();
    handleEdited(true);
  };

  function handleDeleteItem(socialNetwork, index) {
    let clone = [...socialNetworks];
    clone.splice(index, 1);
    setSocialNetworks(clone);
    handleEdited(true);
  }

  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  return (
    <>
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Autocomplete
          noOptionsText='Escriba para buscar'
          options={socialNetworkTypes ?? [{name:''}]}
          fullWidth
          loading={loading}
          value={socialNetworkSelected}
          onChange={(e, value)=> {
              setSocialNetworkSelected(value);
              setDisabled(!value || !url.trim());
          }}
          inputValue={search}
          onInputChange={(e, newInputValue) => {
              setSearch(newInputValue);
            }}
          getOptionLabel={(option) => {
              if (option && option.name) return option.name
              else return "";
            }
          }
          renderInput={(params) => <TextField {...params}
                                  variant="outlined"
                                  className={classes.selectSizeDialog}
                                  fullWidth
                                  label="Tipo"
                                  margin="dense"
                              />}
        />
        <TextField
          value={url}
          error={error}
          onChange={(e) => {
              setUrl(e.target.value);
              setError(!validURL(e.target.value));
              setDisabled(!validURL(e.target.value) || socialNetworkSelected?.id == null);
            }
          }
          variant="outlined"
          label="URL"
          margin="dense"
      />

      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Cancelar
      </Button>
        <Button disabled={disabled || error} onClick={handleSaveSocialNetwork} variant='contained' color="primary">
            Guardar
        </Button>
      </DialogActions>
    </Dialog>
    <Grid container>
        <Grid item xs={12}>
          <Box mx={20}>
              <Box height={85}>
                <Grid container>
                  <Grid item xs={10}>
                    { creating ? 
                      <Typography align='center' variant='button' gutterBottom>Redes sociales</Typography>
                      :  <TextsmsIcon fontSize='large'/>
                    }
                  </Grid>
                  { props.readOnly ? <></> : 
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNewPhone}
                      className={classes.button}
                      startIcon={<AddIcon />}
                    >
                      Nuevo
                    </Button>
                  </Grid> }
                </Grid>
              </Box>
              { socialNetworks === null || socialNetworks.length === 0 ?
              <Typography variant='h6'>No hay redes sociales registradas</Typography>
              : <List dense={true}>
              {
                  socialNetworks.map((socialNetwork, index) =>
                  <ListItem 
                    key={index}
                    button
                    onClick={()=>{
                      let url = '';
                      if (socialNetwork.pivot) {
                        url = socialNetwork.pivot.url.toString().trim();
                      } else {
                        url = socialNetwork.url.toString().trim();
                      }
                      url = url.includes('https://') || url.includes('http://') ? url : 'https://'+url;
                      window.open(url).focus();
                      }
                    }
                  >
                  <ListItemAvatar>
                      <Avatar>
                      <AccountCircleIcon />
                      </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                      primary={socialNetwork.name?.toString()}
                      secondary={socialNetwork.pivot?.url || socialNetwork.url?.toString()}
                  />
                  { props.readOnly ? <></> : 
                  <ListItemSecondaryAction>
                    <IconButton onClick={()=>{handleDeleteItem(socialNetwork, index)}} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton> 
                  </ListItemSecondaryAction> }
                  </ListItem>
              )}
              </List>
              }
          </Box>
        </Grid>
    </Grid>
    </>
  )
}

export default SocialNetworksFields;