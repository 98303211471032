import React, { useContext, useState, useEffect } from 'react'
import { Box, Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import UserContext from '../../../contexts/userContext/userContext.jsx';
import BuildIcon from '@material-ui/icons/Build';
/* importing columns a data service */
import columns, {title, localization, options} from './branches.def.jsx'
import genericDataService from '../../../services/genericDataService';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import mtablePerm from '../../../helpers/permissions/permissions.jsx';

const permissions = new mtablePerm("branches");

const branchesDataService = new genericDataService("/branches");

const tableBranches = React.createRef();

const Branches = (props) => {

	const me = useContext(UserContext);

	const [loading, setLoading] = useState(false);

	const [actions, setActions] = useState({});

	const [data, setData] = useState(() => () => branchesDataService.emptyTable());

	/* Refresca la tabla de branches luego de los siguiente eventos: mount, refresh, add, update */
	useEffect(() => {
		if (me.user.actual_branch>0) {
			setActions(permissions.resolve(me?.user?.menu_permissions).get());
			setData(() => (query) => branchesDataService.rows(query, me.user.company_id));
			tableBranches.current.onQueryChange();
			setLoading(false);
		}
  }, [me])

	return (
		<Box style={{ padding: 20 }}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<MaterialTable
						title={title}
						tableRef={tableBranches}
						columns={columns}
						isLoading={loading}
						onLoading={loading}
						localization={localization}
						loadingType={"linear"}
						options={options}
						data={data}
						editable={{
							...actions,
              isEditable: actions.isEditable ? rowData => { return (rowData.company_id == null ? false : true)} : false,
              onRowAdd: actions.onRowAdd ? newBranch => branchesDataService.rowAdd({...newBranch, company_id: me.user.company_id}) : undefined,
              onRowUpdate: actions.onRowUpdate ? (newData, oldData) => branchesDataService.rowUpdate(newData, oldData) : undefined
            }}
            actions={[
              {
                icon: BuildIcon,
                tooltip: "Administración de roles",
                isFreeAction: true,
                onClick: () => props.history.push("/admin/roles")
              },
            //   {
            //     icon: GroupAddIcon,
            //     tooltip: 'Asignar colaboradores',
            //     onClick: (event, rowData) => props.history.push("/admin/branch_users/" + rowData.id),
			// 					hidden: !actions.onRowAdd
            //   }
            ]}
					/>
				</Grid>
			</Grid>
		</Box>
  )
}

export default Branches;