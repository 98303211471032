import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "45%",
    '@media (max-width:700px)': {
      width: '95%',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  cardlist: {
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: 0,
    paddingTop: 0,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 10,
  },
  disabledFab: {
    position: "fixed",
    bottom: theme.spacing(3.5),
    right: theme.spacing(12),
    zIndex: 10,
  },
  cancelFab: {
    position: "fixed",
    bottom: theme.spacing(3.5),
    right: theme.spacing(30),
    zIndex: 10,    
  },  
  listFormControl: {
    width: "30%",
    margin: theme.spacing(1),
    '@media (max-width:700px)': {
      width: '90%',
    },
  },
  lastGrid: {
    marginBottom: 100,
  },
  responseMessage: {
    position: "fixed",
    bottom: theme.spacing(3.5),
    right: theme.spacing(48),
    zIndex: 10, 
  }
}));


export default useStyles;