import React, { useState, useEffect, useContext } from "react";
import { Grid, Fab, Icon, TextField, InputLabel, Box } from "@material-ui/core";
import genericDataService from "../../../../services/genericDataService";
import UserContext from "../../../../contexts/userContext/userContext.jsx";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  OutlinedInput,
  DialogTitle,
  Typography,
  ButtonGroup,
  InputAdornment,
  FormControl,
} from "@material-ui/core";

//Icons
import MessageIcon from "@material-ui/icons/Message";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";

const VoteActivitiesForm = ({
  openForm,
  handleOpenForm,
  massiveApprove,
  filtersData,
}) => {

  /* Importo el user context para obtener el branch selected */
  const [content, setContent] = useState("");

  const onApproveClick = () => {
    const r = window.confirm("¿Desea aprobar la actividad?");

    if (r) {
      handleOpenForm(false);
      massiveApprove(content);
    }
  };

  return (
    <Dialog open={openForm} onClose={() => handleOpenForm(false)}>
      <DialogTitle>Aprobar todas las actividades</DialogTitle>
      <DialogContent>
      <Box mt={6}>
              <Box my={4}>
                <Typography variant="h6">Votar</Typography>
              </Box>
              <Grid container>
                <Grid item xs={12} md={10}>
                  <Box mr={3} mb={2}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Comentario (opcional)
                      </InputLabel>
                      <OutlinedInput
                        value={content}
                        onChange={(event) => {
                          setContent(event.target.value);
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <MessageIcon />
                          </InputAdornment>
                        }
                        labelWidth={160}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box my={1}>
                    <ButtonGroup
                      disableElevation
                      variant="contained"
                      color="primary"
                    >
                      <Button onClick={onApproveClick}>
                        {" "}
                        <ThumbUpIcon />{" "}
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Grid>
              </Grid>
            </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpenForm(false)} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VoteActivitiesForm;
