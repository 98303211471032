import React from 'react';
import { InputLabel, FormControl, Select, MenuItem, TextField, FormHelperText } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker,  KeyboardTimePicker } from '@material-ui/pickers';
import useStyles from './inputs.css.jsx'
import moment from 'moment';
import esLocale from "date-fns/locale/es";

const TextInput = (props) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
       className={classes.formControl}
       label={props.label}
       value={props.data[props.name] ?? ""}
       onChange={props.handleChange}
       name={props.name}
       onKeyPress={props.onKeyPress}
       helperText={props.error && (props.name in props?.error) ? props?.error[props.name] : ""}
       error={props.error && props.name in props?.error}
       type={("type" in props) ? props.type : "text"}
    />
  )

}

const SelectInput = (props) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={(typeof(props.data[props.name]) == "undefined") ? "" : props.data[props.name] ?? ""}
        onChange={props.handleChange}
        name={props.name}
        inputProps={{ readOnly: !props.canEdit }}
        error={props.error && props.name in props?.error}
      >
        {
          props.options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))
        }
      </Select>
      {props.error && (props.name in props?.error) ? <FormHelperText error={true}>{props.error && props?.error[props.name]}</FormHelperText> : ""}
    </FormControl>
  )
}

const DateInput = (props) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <KeyboardDatePicker
        className={classes.formControl}
        format="dd/MM/yyyy"
        label={props.label}
        invalidDateMessage={props.error && props?.error[props.name]}
        value={props.data[props.name] ?? null}
        onChange={(date) => props.handleChange(props.name, date)}
        readOnly={!props.canEdit}
        helperText={props.error && (props.name in props?.error) ? props?.error[props.name] : ""}
        error={props.error && props.name in props?.error}
      />
    </MuiPickersUtilsProvider>
  )
}

const TimeInput = (props) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <KeyboardTimePicker
        className={classes.formControl}
        format="HH:mm"
        label={props.label}
        ampm={false}
        value={(props.name in props.data) ? moment(props.data[props.name], "HH:mm") : null}
        onChange={ (date) => props.handleChange(props.name, date)}
        readOnly={!props.canEdit}
        invalidDateMessage={props.error && props?.error[props.name]}
        helperText={props.error && (props.name in props?.error) ? props?.error[props.name] : ""}
        error={props.error && props.name in props?.error}
      />
    </MuiPickersUtilsProvider>
  )
}

const DateTimeInput = (props) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <KeyboardDateTimePicker
        className={classes.formControl}
        invalidDateMessage={props.error && props.error[props.name] ? props.error[props.name] : ""}
        format="dd/MM/yyyy HH:mm"
        label={props.label}
        ampm={false}
        value={(props.name in props.data) ? moment(props.data[props.name], "dd/MM/yyyy HH:mm") : null}
        onChange={ (date) => props.handleChange(props.name, date)}
        readOnly={!props.canEdit}
        error={props.error && props.name in props?.error}
      />
    </MuiPickersUtilsProvider>
  )
}

export {DateInput}
export {TextInput}
export {SelectInput}
export {TimeInput}
export {DateTimeInput}