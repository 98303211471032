import React, { useState } from "react";
import {
  Container,
  CssBaseline,
  Grid,
  Paper,
  Box,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import SaveIcon from "@material-ui/icons/Save";
import { useLocation } from "react-router-dom";
import Alert from "../../../components/app/alert";

import apiClient from "../../../helpers/apiClient/apiClient.jsx";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ActivityVote = (props) => {
  const [comment, setComment] = useState("");
  const [voted, setVoted] = useState(false);
  const [loading, setLoading] = useState(false);

  let query = useQuery();
  const url = query.get("url");

  const [alert, setAlert] = useState({
    severity: "success",
    open: false,
    message: "",
  });

  const handleSavePressed = async () => {
    let params = {
      comment: comment,
    };

    let signedUrl = atob(url);
    setLoading(true);
    const result = await apiClient.post(signedUrl, params).catch((e) => e.response.data.message);
    setLoading(false);
    if (result?.status < 400) {
      setVoted(true);
      setAlert({
        severity: "success",
        message: result?.data.message ?? 'Voto emitido correctamente',
        open: true,
      });
    }
    else {
      setAlert({
        severity: "error",
        message: result,
        open: true,
      });
    }
  };

  const commentForm = () => {
    return (
      <>
        <Box paddingY={"15px"}>
          <Typography>Incluir comentario al voto (opcional)</Typography>
        </Box>
        <Grid container alignItems="center">
          <Grid item>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">
                Comentario
              </InputLabel>
              <OutlinedInput
                value={comment}
                multiline
                onChange={(event) => {
                  setComment(event.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <MessageIcon />
                  </InputAdornment>
                }
                labelWidth={85}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Box
              style={{
                marginLeft: "25px",
                backgroundColor: "#4143dd",
                borderRadius: 8,
              }}
            >
              <IconButton onClick={handleSavePressed} disabled={loading}>
                { loading ? <CircularProgress size={24} style={{'color': 'white'}}/> : <SaveIcon /> }
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const voteEmited = () => {
    return (
      <>
        <Box paddingY={"15px"}>
          <Typography>Voto ya emitido</Typography>
        </Box>
      </>
    );
  };

  return (
    <Box marginTop={20} marginX="auto" maxWidth={"450px"} minWidth={"340px"}>
      <Alert
        onClose={() => setAlert({ ...alert, open: false })}
        alert={alert}
        setAlert={setAlert}
      />
      <Container component="main">
        <CssBaseline />
        <Paper elevation={3}>
          <Box padding={"20px"}>{!voted ? commentForm() : voteEmited()}</Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default ActivityVote;
