import React, { useState, useContext, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import columns, { localization, options } from "./licenceList.def.jsx";
import UserContext from "../../../../contexts/userContext/userContext.jsx";
import genericDataService from "../../../../services/genericDataService";
import { Grid } from "@material-ui/core";

import Badge from "@material-ui/core/Badge";
import AttachmentIcon from "@material-ui/icons/Attachment";
import AttachmentsDialog from "../../../attachmentsDialog/attachmentsDialog.jsx";
import LicenceRequest from "../licenceRequest/licenceRequest.jsx";

const tableLicences = React.createRef();

const LicenceDataService = new genericDataService("/categories/" + 2 + "/activities");

const LicenceList = () => {
  /* Importo el user context para obtener el branch selected */
  const me = useContext(UserContext);

  const [activity, setActivity] = useState(0);
  /* flag de loading, al cambiar este valor renderiza el componente*/
  const [loading, setLoading] = useState(false);

  /* Uso el hook de estado para renderizar la tabla al cambiar el mismo*/
  const [data, setData] = useState(() => () => {
    return LicenceDataService.emptyTable();
  });

  const [activityAttachmentsOpen, setActivityAttachmentsOpen] = useState(false);

  const refreshTable = () => {
    tableLicences.current.onQueryChange();
  };

  const handleLicenceRequestChange = () => {
    refreshTable();
  };

  useEffect(() => {
    if (me.user.actual_branch > 0) {
      setData(
        () => (query) => LicenceDataService.rows(query)
      );
      tableLicences.current.onQueryChange();
      setLoading(false);
    }
  }, [me]);

  /* traigo los activity types necesarios para obtener el titulo y verificar si hay una actividad iniciada*/
  useEffect(() => {
    if (me.user.actual_branch > 0) {
      // LicenceDataService.lookup("/activity_types/all");
    }
  }, [me]);

  return (
    <>
      <AttachmentsDialog
        activity={activity}
        activityAttachmentsOpen={activityAttachmentsOpen}
        setActivityAttachmentsOpen={setActivityAttachmentsOpen}
        refreshParent={refreshTable}
      />
      <Grid
        container
        spacing={2}
        style={{ position: "fixed", bottom: "10px", left: "20px", zIndex: 10 }}
      >
        <Grid item>
          <LicenceRequest onChange={handleLicenceRequestChange} />
        </Grid>
      </Grid>
      <MaterialTable
        tableRef={tableLicences}
        columns={columns}
        toolbar={true}
        title={'Listado de licencias'}
        data={data}
        isLoading={loading}
        loadingType={"linear"}
        localization={localization}
        options={{
          ...options,
          exportCsv: (columns) =>
            LicenceDataService.makeRequest(tableLicences, columns).exportCsv(),
          exportPdf: (columns) =>
            LicenceDataService.makeRequest(tableLicences, columns).exportPdf(),
        }}
        components={{
          Toolbar: (props) => <MTableToolbar {...props} />,
        }}
        actions={[
          (rowData) => ({
            icon: () => (
              <Badge badgeContent={rowData.comments.length} color="primary">
                <AttachmentIcon color="action" />
              </Badge>
            ),
            tooltip: "Gestor de archivos adjuntos",
            onClick: (event, rowData) => {
              setActivity(rowData);
              setActivityAttachmentsOpen(true);
            },
          }),
        ]}
      />
      <br />
      <br />
      <br />
    </>
  );
};
export default LicenceList;
