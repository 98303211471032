import React, { useState, useContext, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import columns, {
  localization,
  options,
  title,
} from "./activityTracer.def.jsx";
import UserContext from "../../../../contexts/userContext/userContext.jsx";
import genericDataService from "../../../../services/genericDataService";
import TraceActivitiesTicketsToolbar from "./activityTracerToolbar";
import {
  Button,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import PageViewIcon from "@material-ui/icons/FindInPage";
import AttachmentsDialog from "../../../attachmentsDialog/attachmentsDialog.jsx";
import ActivityDetailDialog from "../../../ActivityDetailDialog/activityDetailDialog.jsx";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "../../../app/alert.jsx";

const tableActivities = React.createRef();

const TraceActivitiesDataService = new genericDataService("/activities/tracer");

const ActivityListTrace = (props) => {
  /* Importo el user context para obtener el branch selected */
  const me = useContext(UserContext);
  const isAdminRRHH = me.user?.roles?.some(role => role.full_name === "Administrador de RRHH");
  /* flag de loading, al cambiar este valor renderiza el componente*/
  const [loading, setLoading] = useState(false);

  const [activity, setActivity] = useState(0);

  /* Uso el hook de estado para renderizar la tabla al cambiar el mismo*/
  const [data, setData] = useState(() => () => {
    return TraceActivitiesDataService.emptyTable();
  });

  const [alert, setAlert] = useState({
    severity: "success",
    open: false,
    message: "",
  });

  const [userHasPermissions, setUserHasPermissions] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [filtersData, setFiltersData] = useState({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(),
    user_id: [],
  });
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [activeUserFlag, setActiveUserFlag] = useState(true);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [areas, setAreas] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [allActivityTypes, setAllActivityTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [activityTypeSelected, setActivityTypeSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(null);
  const [activityDetailOpen, setActivityDetailOpen] = useState(false);
  const [activityAttachmentsOpen, setActivityAttachmentsOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingActivityId, setDeletingActivityId] = useState(null);

  const READ_ACTIVITY_TRACER_PERMISSION = "read_activity_tracer";
  const DELETE_ACTIVITIES_PERMISSION = "delete_activities";
  const ACTIVITY_STATUS_APPROVED_ID = 3;

  const refreshTable = (filters) => {
    setLoading(true);
    setData(() => (query) => {
      return TraceActivitiesDataService.rows(query, filters).then((value) => {
        if (value.data.filter((f) => f.lunch).length === 0) {
          columns.at(8).hidden = true;
        } else {
          columns.at(8).hidden = false;
        }
        return value;
      });
    });
    tableActivities.current.onQueryChange();
    setLoading(false);
  };

  useEffect(() => {
    if (me.user.actual_branch > 0) {
      if (props?.location?.state) {
        refreshTable({
          user_id: props.location.state.user_id
            ? [props.location.state.user_id]
            : [],
          start: props.location.state.dateFrom,
          end: props.location.state.dateTo,
        });
      } else {
        refreshTable();
      }
    }
  }, [me, props]);

  const updateUserActivityTypes = (availableActivityTypes) => {
    let userActivityTypes = [];

    availableActivityTypes.forEach((availableActivityType) => {
      availableActivityType["activity_types"].forEach((activityType) => {
        userActivityTypes.push(activityType);
      });
    });

    setActivityTypes(userActivityTypes);
  };

  const updateActivityTypes = () => {
    TraceActivitiesDataService.lookup("/activity_types/all").then((res) => {
      setActivityTypes(res.data);
      setAllActivityTypes(res.data);
    });
  };

  const updateUserAreas = (areas) => {
    let userAreas = [];
    Object.entries(areas).forEach(([key, value]) => {
      let area = {
        id: key,
        name: value,
      };
      userAreas.push(area);
    });
    setAreas(userAreas);
  };

  const areaChanged = (area) => {
    if (area) {
      let areaUsers = allUsers.filter(
        (user) => "" + area.id in user.available_areas
      );
      setUsers(areaUsers);
    } else {
      setUsers(allUsers);
    }
  };

  const userChanged = (user) => {
    if (user) {
      let userAreas = allAreas.filter(
        (area) => "" + area.id in user.available_areas
      );

      let userActivityTypes = allActivityTypes.filter(
        (activityType) => "" + activityType.area_id in user.available_areas
      );
      setAreas(userAreas);
      setActivityTypes(userActivityTypes);
    } else {
      setAreas(allAreas);
      setActivityTypes(allActivityTypes);
    }
  };

  const activityTypeChanged = (activityType) => {
    if (activityType) {
      let activityTypeUsers = allUsers.filter(
        (user) => "" + activityType.area_id in user.available_areas
      );
      let activityTypeAreas = allAreas.filter(
        (area) => "" + activityType.area_id == area.id
      );
      let activityTypeCategories = allCategories.filter(
        (category) => "" + activityType.category_id == category.id
      );

      setUsers(activityTypeUsers);
      setAreas(activityTypeAreas);
      setCategories(activityTypeCategories);
    } else {
      setUsers(allUsers);
      setAreas(allAreas);
      setCategories(allCategories);
    }
  };

  const categoryChanged = (category) => {
    if(category){
      let categoryActivityTypes = allActivityTypes.filter(
        (activityType) => "" + activityType.category_id == category.id
      );
      setActivityTypes(categoryActivityTypes);
    } else {
      setCategories(allCategories);
    }
  }

  const updateAreas = () => {
    TraceActivitiesDataService.lookup("/areas/all")
      .then((res) => {
        setAreas(res.data);
        setAllAreas(res.data);
      })
      .catch((err) => {});
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteActivity = () => {
    const activitiesDataService = new genericDataService("activities");

    activitiesDataService
      .destroy(deletingActivityId)
      .then((res) => {
        setAlert({
          severity: "success",
          message: res.data.message,
          open: true,
        });
        refreshTable();
      })
      .catch((error) => {
        setAlert({
          severity: "error",
          message: error.message,
          open: true,
        });
      })
      .finally(() => {
        setDeletingActivityId(null);
      });

    setOpenDialog(false);
  };

  /* chequea si tiene permisos para ver las horas trabajadas de todos */
  useEffect(() => {
    if (me.user.actual_branch > 0) {
      let permission = me.user.menu_permissions.includes(
        READ_ACTIVITY_TRACER_PERMISSION
      );
      setUserHasPermissions(permission);

      //set user, areas disponibles y activity types disponibles
      if (!permission) {
        setFiltersData({
          ...filtersData,
        });
        updateUserActivityTypes(me.user.available_activity_types);
        updateUserAreas(me.user.available_areas);
      } else {
        updateActivityTypes();
        updateAreas();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  /* traigo todos los users para el select del filtro */
  useEffect(() => {
    if (me.user.actual_branch > 0 && userHasPermissions) {
      setLoading(true);
      TraceActivitiesDataService.lookup("/users/all")
        .then((res) => {
          let usersArray = res.data.filter(
            (user) => user.active === activeUserFlag
          );
          setUsers(usersArray);
          setAllUsers(usersArray);
          if (props.location.state.user_id) {
            setFiltersData({
              ...filtersData,
              user_id: [props.location.state.user_id],
              start: props.location.state.dateFrom,
              end: props.location.state.dateTo,
            });
            const user = res.data.find(
              (u) => u.id === props.location.state.user_id
            );
            setUserSelected(user);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasPermissions, activeUserFlag]);

  /* traigo todas las categorias para el select del filtro */
  useEffect(() => {
    TraceActivitiesDataService.lookup("/categories/all")
      .then((res) => {
        setCategories(res.data);
        setAllCategories(res.data);
      })
      .catch((err) => {});
  }, []);

  /* traigo todas las activity_type para el select del filtro */
  useEffect(() => {
    if (areaSelected) {
      let idAreaSelected = areaSelected["id"];
      let filteredActivityTypes = activityTypes.filter(
        (activityType) => activityType["area_id"] === idAreaSelected
      );
      setActivityTypes(filteredActivityTypes);
    } else {
      if (me.user.actual_branch > 0) {
        let permission = me.user.menu_permissions.includes(
          READ_ACTIVITY_TRACER_PERMISSION
        );
        if (!permission) {
          updateUserActivityTypes(me.user.available_activity_types);
        } else {
          updateActivityTypes();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSelected, me]);

  /* traigo todos los status para el select del filtro */
  useEffect(() => {
    TraceActivitiesDataService.lookup("/statuses/all")
      .then((res) => {
        setStatuses(res.data);
      })
      .catch((err) => {});
  }, []);

  const onChangeRowsPerPage = (rowsPerPage) => {
    setPageSize(rowsPerPage);
  };

  const addComment = (comment) => {
    setActivity({ ...activity, comments: [...activity.comments, comment] });
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>{"¿Desea eliminar la actividad?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Al eliminar la actividad se perderán los datos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteActivity} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        onClose={() => setAlert({ ...alert, open: false })}
        alert={alert}
        setAlert={setAlert}
      />
      <AttachmentsDialog
        activity={activity}
        activityAttachmentsOpen={activityAttachmentsOpen}
        setActivityAttachmentsOpen={setActivityAttachmentsOpen}
        refreshParent={refreshTable}
      />
      <ActivityDetailDialog
        activity={activity}
        addComment={addComment}
        activityDetailOpen={activityDetailOpen}
        setActivityDetailOpen={setActivityDetailOpen}
        refreshParent={refreshTable}
      />
      <MaterialTable
        tableRef={tableActivities}
        columns={columns}
        toolbar={true}
        title={title}
        data={data}
        isLoading={loading}
        loadingType={"linear"}
        localization={localization}
        onChangeRowsPerPage={onChangeRowsPerPage}
        options={{
          ...options,
          pageSize: pageSize,
          emptyRowsWhenPaging: false,
          exportCsv: (columns) => {
            return TraceActivitiesDataService.makeRequest(
              tableActivities,
              columns
            ).exportCsv(filtersData);
          },
          exportPdf: (columns) =>
            TraceActivitiesDataService.makeRequest(
              tableActivities,
              columns
            ).exportPdf(filtersData),
        }}
        components={{
          Toolbar: (props) => (
            <>
              <MTableToolbar {...props} />
              <TraceActivitiesTicketsToolbar
                isAdminRRHH={isAdminRRHH}
                users={users}
                setUsers={setUsers}
                userChanged={userChanged}
                categories={categories}
                areas={areas}
                areaChanged={areaChanged}
                categoryChanged={categoryChanged}
                activityTypeChanged={activityTypeChanged}
                setAreas={setAreas}
                activityTypes={activityTypes}
                statuses={statuses}
                onSearch={refreshTable}
                filtersData={filtersData}
                userSelected={userSelected}
                setUserSelected={setUserSelected}
                categorySelected={categorySelected}
                setCategorySelected={setCategorySelected}
                areaSelected={areaSelected}
                setAreaSelected={setAreaSelected}
                activityTypeSelected={activityTypeSelected}
                setActivityTypeSelected={setActivityTypeSelected}
                statusSelected={statusSelected}
                setStatusSelected={setStatusSelected}
                setFiltersData={setFiltersData}
                dropdownUserAppear={userHasPermissions}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                activeUserFlag={activeUserFlag}
                setActiveUserFlag={setActiveUserFlag}
              />
            </>
          ),
        }}
        actions={[
          (rowData) => ({
            icon: () => (
              <Badge
                badgeContent={
                  rowData.comments.filter((comment) => comment.vote !== null)
                    ?.length
                }
                color="primary"
              >
                <PageViewIcon color="action" />{" "}
              </Badge>
            ),
            tooltip: "Detalle de actividad",
            onClick: (event, rowData) => {
              setActivity(rowData);
              setActivityDetailOpen(true);
            },
          }),
          (rowData) => ({
            icon: () => (
              <Badge
                badgeContent={
                  rowData.comments.filter(
                    (comment) => comment.attachment != null
                  )?.length
                }
                color="primary"
              >
                <AttachmentIcon color="action" />
              </Badge>
            ),
            tooltip: "Gestor de archivos adjuntos",
            onClick: (event, rowData) => {
              setActivity(rowData);
              setActivityAttachmentsOpen(true);
            },
          }),
          me.user.menu_permissions?.includes(DELETE_ACTIVITIES_PERMISSION)
            ? (rowData) => ({
                icon: () => rowData.status_id === ACTIVITY_STATUS_APPROVED_ID ? null: <DeleteIcon color="action" />,
                disabled: rowData.status_id === ACTIVITY_STATUS_APPROVED_ID,
                tooltip: "Eliminar actividad",
                onClick: (event, rowData) => {
                  setOpenDialog(true);
                  setDeletingActivityId(rowData.id);
                },
              })
            : null,
        ]}
      />
    </>
  );
};
export default ActivityListTrace;
