import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {

  const handleExit = () => {
    props.setAlert({
      ...props.alert,
      message: "",
      open: false,
    });
  };

  const messages = [];

  if(props.alert.list) {
    Object.values(props.alert.list).map(item => {
      messages.push(...item);
    })
  }

  return (
    <Snackbar
      open={props.alert.open}
      autoHideDuration={5000}
      onClose={handleExit}
      onExited={handleExit}
    >
      <MuiAlert
        severity={props.alert.severity}
        elevation={6}
        variant="filled"
        {...props}
      >
        { props.alert.list ?
        messages.map(item => {
          return <div>{item}</div>
        }) :
        props.alert.message }
      </MuiAlert>
    </Snackbar>
  );
}

export default Alert;
