import apiClient from '../helpers/apiClient/apiClient.jsx'

import moment from 'moment-timezone';
import { CsvBuilder } from 'filefy';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'

class genericDataService {

	constructor(_resource) {
		this.resource = _resource;
		this.query = {}
		this.columns = {}
		this.title = "";
	}
	/* api's methods */
	index(params) {
		params = typeof (params) == 'undefined' ? {} : params;
		/* TODO: eliminar la key tableData dentro del objeto orderby del request que arma material table */
		return apiClient.get(this.resource, { params: params }).catch(err => false);
	}

	show(id, params) {
		params = typeof (params) == 'undefined' ? {} : params;
		return apiClient.get(`${this.resource}/${id}`, params);
	}

	store(params) {
		return apiClient.post(this.resource, params);
	}

	update(id, params, method) {
		if (typeof (method) == "undefined") method = 'put';
		switch (method) {
			case "put": return apiClient.put(`${this.resource}/${id}`, params);
			case "post": return apiClient.post(`${this.resource}/${id}`, params);
			default: return apiClient.put(`${this.resource}/${id}`, params);
		}
	}

	upload(params) {
		return apiClient.post(this.resource, params, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		});
	}

	destroy(id) {
		return apiClient.delete(`${this.resource}/${id}`);
	}

	lookup(route, params) {
		params = typeof (params) == 'undefined' ? {} : params;
		return apiClient.get(route, { params: params });
	}

	async exportCsv(filters) {
		if (filters) {
			this.query.pageSize = 0;
			let newFilters = Object.keys(filters).map((filter) => {
				return {
					column: filter,
					operator: '=',
					value: filters[filter]
				}
			});
			this.query.filters = this.deleteParams(newFilters);
		}
		return apiClient.get(`${this.resource}/all`, { params: this.query })
			.then(res => res.data)
			.then(data => {
				const columns = this.columns.filter(columnDef => columnDef["export"] !== false);
				const exportedData = data.map(rowData => columns.map(columnDef => ("render" in columnDef) ? columnDef.render(rowData) : rowData[columnDef.field]));
				const newExportedData = exportedData.map((elemento) => {
					if (elemento[8] !== "") {
					  return [
						elemento[0],
						elemento[1],
						elemento[2],
						elemento[3],
						elemento[4],
						elemento[5],
						elemento[6],
						elemento[7],
						elemento[8],
					  ];
					}
					return elemento;
				  });
				new CsvBuilder('csv_' + moment().format('YYYY-MM-DDTHHmmss'))
					.setDelimeter(';')
					.setColumns(columns.map(columnDef => columnDef.title))
					.addRows(newExportedData)
					.exportFile();
			}).catch(err => false)
	}

	async exportPdf(filters) {
		if (filters) {
			this.query.pageSize = 0;
			let newFilters = Object.keys(filters).map((filter) => {
				return {
					column: filter,
					operator: '=',
					value: filters[filter]
				}
			});
			this.query.filters = this.deleteParams(newFilters);
		}
		return apiClient.get(`${this.resource}/all`, { params: this.query })
			.then(res => res.data)
			.then(data => {
				const columns = this.columns.filter(columnDef => columnDef["export"] !== false);
				const exportedData = data.map(rowData => columns.map(columnDef => ("render" in columnDef) ? columnDef.render(rowData) : rowData[columnDef.field]));
				const newExportedData = exportedData.map((elemento) => {
					if (elemento[8] !== "") {
					  return [
						elemento[0],
						elemento[1],
						elemento[2],
						elemento[3],
						elemento[4].props.label,
						elemento[5],
						elemento[6],
						elemento[7],
						elemento[8],
					  ];
					}
					return elemento;
				  });
				if (jsPDF !== null) {
					let content = {
						startY: 50,
						head: [columns.map((columnDef) => columnDef.title)],
						body: newExportedData,
					};
					const unit = "pt";
					const size = "A4";
					const orientation = "portrait";

					const doc = new jsPDF(orientation, unit, size);
					doc.setFontSize(15);
					doc.text(this.title, 40, 40);
					doc.autoTable(content);
					doc.save('pdf_' + moment().format('YYYY-MM-DDTHHmmss') + ".pdf");
				}
			}).catch(err => false);
	}

	/* callbacks for material table */
	rowAdd(newData) {
		return new Promise((resolve, reject) => {
			this
				.store(newData)
				.then((res) => resolve(res))
				.catch((error) => reject(error))
		})
	}

	rowUpdate(newData) {
		return new Promise((resolve, reject) => {
			this
				.update(newData.id, newData)
				.then((res) => resolve(res))
				.catch((error) => reject(error))
		})
	}

	rowDelete(OldData) {
		return new Promise((resolve, reject) => {
			this
				.destroy(OldData.id)
				.then((res) => resolve(res))
				.catch((error) => reject(error))
		})
	}

	changeResource(_resource) {
		this.resource = _resource;
		return this;
	}

	rows(query, customFilters) {
		/* TODO: see this issue of mui: https://github.com/mui-org/material-ui/issues/15616*/
		query.page++;
		return new Promise((resolve, reject) => {

			query.filters = this.deleteParams(query.filters);

			if (customFilters) {
				query.filters = [];
				for (let key in customFilters) {
					query.filters.push({
						column: key,
						operator: '=',
						value: customFilters[key]
					});
				}
			}

			query.orderBy = query.orderBy ? { "field": query.orderBy?.field } : null;
			this
				.index(query)
				/* TODO: see this issue of mui: https://github.com/mui-org/material-ui/issues/15616 */
				.then((res) => resolve({
					data: res.data.data,
					page: res.data.current_page - 1,
					totalCount: ((res.data.total / res.data.per_page) > (res.data.current_page - 1)) ? res.data.total : ((res.data.per_page * (res.data.current_page - 1)) + 1),
					totalWorked: res.data.total_worked,
					totalReported: res.data.total_reported,
					reportedVsWorked: res.data.reported_vs_worked,
					irregularDays: res.data.irregular_days,
				}))
				.catch((error) => reject("Recurso no disponible"))
		})
	}

	emptyTable() {
		return new Promise((resolve) => resolve({ data: [], page: 0, totalCount: 0 }))
	}

	makeRequest(tableRef, columns) {
		var query = {};
		this.title = tableRef.current.props.title.replace("Administración", "Listado");
		if ("orderBy" in tableRef.current.dataManager) {
			query = { ...query, orderBy: columns[tableRef.current.dataManager.orderBy] }
		}
		if ("orderDirection" in tableRef.current.dataManager) {
			query = { ...query, orderDirection: tableRef.current.dataManager.orderDirection }
		}
		if ("searchText" in tableRef.current.dataManager) {
			query = { ...query, search: tableRef.current.dataManager.searchText }
		}

		this.query = query;
		this.columns = columns;
		return this;
	}

	deleteParams(filters) {
		return filters.map((filter) => {
			return {
				"column": filter.column.field ?? filter.column,
				"operator": filter.operator,
				"value": filter.value
			}
		});
	}

	getApiClient() {
		return apiClient;
	}
	
}

export default genericDataService;